import * as React from 'react';
import { createForm, } from '@formily/core';
import { useSizeChange } from '@/components/cn-utils';
import { CnFilterProPlugin as Filter } from './filter';
import { getCache } from './cache';
import { withI18n } from 'panda-i18n';
import locale from '@locale';
export const CnFilterPro = withI18n(React.forwardRef((props, ref) => {
    const { size, setSize } = useSizeChange(props);
    return (React.createElement(Filter, { ref: ref, labelAlign: size === 'small' ? 'left' : 'top', isRightButton: size === 'small', ...props, size: size }));
}), {
    componentName: 'CnFilter',
    locale,
    forwardRef: true,
});
CnFilterPro.getCache = getCache;
CnFilterPro.createForm = (options = {}) => {
    return createForm({
        ...options,
        values: {
            ...(options.values || {}),
            ...(getCache(options.storageKey) || {}),
        },
    });
};
export default CnFilterPro;
export { CnFilterProItem } from './components/cn-filter-pro-item';
