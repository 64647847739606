/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as React from 'react';
import classnames from 'classnames';
import { EmptyContent } from '../dep';
export const stylegen = (props) => {
    const { hasBorder = true, style } = props;
    return Object.assign({}, style, {
        '--cell-border-vertical': hasBorder ? '1px solid var(--border-color)' : 0,
        '--header-cell-border-vertical': hasBorder
            ? 'var(--header-cell-border)'
            : 0,
    });
};
export const rowPropsgen = (props, selected) => {
    const { rowProps, primaryKey } = props;
    return (record, rowIndex) => {
        const customRowProps = rowProps && rowProps(record, rowIndex);
        return {
            ...customRowProps,
            className: classnames(customRowProps === null || customRowProps === void 0 ? void 0 : customRowProps.className, {
                'cn-table-row-selected': Array.isArray(selected) && selected.includes(record[primaryKey]),
            }),
        };
    };
};
export const classNamegen = (props) => {
    const { className, size = 'small' } = props;
    return classnames(className, {
        'cn-table-small': size === 'small',
        'cn-table-medium': size === 'medium',
        'cn-table-large': size === 'large',
    });
};
export const emptyContentgen = (props) => {
    const { emptyContent } = props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return typeof emptyContent === 'string' ? (React.createElement(EmptyContent, null, emptyContent)) : (emptyContent);
};
