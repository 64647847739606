import { withI18n } from 'panda-i18n';
import locale from '@locale';
import { CnCard as CnCardView } from './cn-card';
export const CnCard = withI18n(CnCardView, {
    locale,
    componentName: 'CnCard',
});
CnCard.displayName = 'CnCard';
export * from './sub-card';
export * from './sub-area-card';
