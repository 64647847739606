import * as React from 'react';
import { Tab } from '@/components/fusion';
export class CnTabItem extends React.Component {
    render() {
        const { children, ...otherProps } = this.props;
        return React.createElement(Tab.Item, { ...otherProps }, children);
    }
}
CnTabItem.defaultProps = {
    maxTitleLength: 20,
};
