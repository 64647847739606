import * as React from 'react';
import classNames from 'classnames';
import { Box } from '@/components/fusion';
import { CnTagSelectable } from '@/components/cn-tag';
import { useDerivedValue } from '@/components/cn-utils/hooks';
// 检查值是否能够完全等同
const checkSelected = (val, values) => {
    if (Array.isArray(values)) {
        return values.includes(val);
    }
    else {
        return val === values;
    }
};
export const CnSelector = (props) => {
    const { className, dataSource = [], mode = 'single', onChange, disabled, } = props;
    const [value, setValue, isControlled] = useDerivedValue(props, []);
    const handleClick = (v) => {
        if (mode === 'single') {
            const hasChecked = checkSelected(v, value);
            if (!isControlled) {
                setValue(hasChecked ? [] : [v]);
            }
            onChange && onChange(hasChecked ? undefined : v);
        }
        else {
            const newValue = [...value];
            if (newValue.includes(v)) {
                newValue.splice(newValue.indexOf(v), 1);
            }
            else {
                newValue.push(v);
            }
            if (!isControlled) {
                setValue(newValue);
            }
            onChange && onChange(newValue);
        }
    };
    const clsPrefix = 'cn-ui-selector';
    const items = dataSource.map((it) => {
        const v = typeof it === 'object' ? it.value : it;
        const label = typeof it === 'object' ? it.label : it;
        const itemDisabled = disabled || (typeof it === 'object' ? it.disabled : false);
        return (React.createElement(CnTagSelectable, { key: v, disabled: itemDisabled, onChange: () => {
                if (itemDisabled)
                    return;
                handleClick(v);
            }, checked: checkSelected(v, value), type: "primary" }, label));
    });
    return (React.createElement("div", { className: classNames(clsPrefix, `${clsPrefix}--medium`, className) },
        React.createElement(Box, { wrap: true, direction: "row", spacing: 8 }, items)));
};
