// import NextSelect from '@fusion/lib/select';
import { withI18n } from 'panda-i18n';
// import type { AutoComplete } from '@fusion/types/select';
import { componentName } from './const';
import { AutoComplete as PureAutoComplete } from './view/auto-complete';
import { Select as PureSelect } from './view';
import locale from '@locale';
// TODO ref组件未输出ref类型
export const CnAsyncSelect = withI18n(PureSelect, {
    componentName,
    locale,
    forwardRef: true,
});
// as unknown as ForwardRefExoticComponent<SelectProps> & {
//   AutoComplete: typeof AutoComplete;
// };
CnAsyncSelect.displayName = componentName;
CnAsyncSelect.AutoComplete = PureAutoComplete;
export * from './types';
