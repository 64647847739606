import * as React from 'react';
import { Tab } from '@fusion/lib';
import classnames from 'classnames';
import isString from 'lodash/isString';
import { getByteLen, subCharStr } from '@/components/cn-utils';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnTabItem } from './item';
function CustomTabItem({ itemKey, onClose, title, icon, closeable, }) {
    const onClick = (e) => {
        // 阻止触发 onChange
        e.stopPropagation();
        onClose && onClose(itemKey);
    };
    return (React.createElement("div", { className: "cn-next-tabs-tab-inner" },
        React.createElement("div", { className: "cn-ui-tab-inner-sub cn-ui-tab-inner-sub-left" }),
        React.createElement("div", { className: "cn-ui-tab-inner-sub cn-ui-tab-inner-sub-right" }),
        icon,
        title,
        closeable && (React.createElement("i", { className: "cn-next-icon cn-next-icon-close cn-next-medium cn-next-tabs-tab-close", onClick: onClick }))));
}
function getShape(currentShape, shape) {
    if (shape) {
        return shape;
    }
    if (!currentShape || currentShape === 'pure') {
        return 'wrapped';
    }
    if (currentShape === 'wrapped') {
        return 'text';
    }
    if (currentShape === 'text') {
        return 'capsule';
    }
    return undefined;
}
const TabShapeContext = React.createContext(undefined);
export class CnTab extends React.Component {
    constructor() {
        super(...arguments);
        this.getTabTitle = (props = {}) => {
            const { title: propTitle, maxTitleLength } = props;
            if (!isString(propTitle)) {
                return propTitle;
            }
            const byteLen = getByteLen(propTitle);
            if (byteLen <= maxTitleLength) {
                return propTitle;
            }
            return (React.createElement(CnTooltip, { v2: true, align: "t", trigger: React.createElement("div", null, subCharStr(propTitle, maxTitleLength)) }, propTitle));
        };
        this.getChildren = () => {
            const rst = [];
            React.Children.forEach(this.props.children, (child) => {
                if (child === null || child === void 0 ? void 0 : child.props) {
                    rst.push(React.cloneElement(child, {
                        title: this.getTabTitle(child.props),
                    }));
                }
            });
            return rst;
        };
    }
    render() {
        const { children, shape, className, onClose, ...otherProps } = this.props;
        const shapeContext = this.context;
        const viewShape = getShape(shapeContext, shape);
        return (React.createElement(TabShapeContext.Provider, { value: viewShape },
            React.createElement(Tab, { "data-name": "CnTab", tabRender: (key, props) => (React.createElement(CustomTabItem, { itemKey: key, ...props, onClose: onClose })), shape: viewShape, ...otherProps, className: classnames('cn-ui-tab', {
                    [className]: !!className,
                }) }, this.getChildren())));
    }
}
CnTab.contextType = TabShapeContext;
CnTab.Item = CnTabItem;
CnTab.displayName = 'CnTab';
