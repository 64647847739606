/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import ReactDOM from 'react-dom';
import * as React from 'react';
import { Balloon } from '@/components/fusion';
import { isFunction } from '../../utils/common';
import './index.scss';
const DropDown = 'cn-table-drapdown2';
const getContainer = () => {
    let container = document.getElementById(DropDown);
    if (!container) {
        container = document.createElement('div');
        container.id = DropDown;
        document.documentElement.append(container);
    }
    return container;
};
let lock = false;
const show = (event, content, opts) => {
    var _a, _b;
    if (lock || !content) {
        return;
    }
    lock = true;
    const container = getContainer();
    const { offset, props, accordTarget = true, triangleHeight = 10, zIndex = 100000, footer, hasMask = true, transform, target, closeOnClick = true, onMaskClick, isInVisible = true, } = opts || {};
    const { clientX, clientY } = event;
    const targetDom = target !== null && target !== void 0 ? target : event === null || event === void 0 ? void 0 : event.target;
    // @ts-ignore
    const { height, left, top } = isFunction(targetDom === null || targetDom === void 0 ? void 0 : targetDom.getBoundingClientRect)
        ? targetDom === null || targetDom === void 0 ? void 0 : targetDom.getBoundingClientRect()
        : {};
    const { innerHeight, innerWidth } = window;
    const isTopHalf = top < innerHeight / 2;
    const isLeftHalf = left < innerWidth / 2;
    const addVerOffset = (_a = offset === null || offset === void 0 ? void 0 : offset[0]) !== null && _a !== void 0 ? _a : 0;
    const addHozOffset = (_b = offset === null || offset === void 0 ? void 0 : offset[1]) !== null && _b !== void 0 ? _b : 0;
    let posTop = 0;
    posTop = isTopHalf
        ? top + height + triangleHeight + document.documentElement.scrollTop
        : top - triangleHeight + document.documentElement.scrollTop;
    const containerHeight = isTopHalf
        ? innerHeight - top - height - triangleHeight
        : top - triangleHeight;
    const pos = {
        top: isTopHalf ? posTop + addVerOffset : posTop - addVerOffset,
        // left: posLeft + addHozOffset,
    };
    if (accordTarget) {
        pos[isLeftHalf ? 'left' : 'right'] = isLeftHalf
            ? left + addHozOffset
            : innerWidth - left - addHozOffset;
    }
    else {
        pos.left = clientX + addHozOffset;
    }
    // @ts-ignore
    const Container = (opts === null || opts === void 0 ? void 0 : opts.Container) || Balloon.Inner;
    const maskClick = () => {
        closeOnClick && hide();
        onMaskClick instanceof Function && onMaskClick();
    };
    ReactDOM.render(React.createElement(React.Fragment, null,
        React.createElement(Container, { onClick: (event) => {
                event.stopPropagation();
            }, closable: false, align: isTopHalf ? 'b' : 't', ...(props || {}), style: {
                position: 'absolute',
                maxHeight: isInVisible ? containerHeight : 'auto',
                overflow: 'auto',
                ...pos,
                zIndex,
                transform: isFunction(transform)
                    ? transform({
                        isTopHalf,
                        accordTarget,
                    })
                    : `translate(${accordTarget ? 0 : -50}%, ${isTopHalf ? 0 : '-100'}%)`,
                ...((props === null || props === void 0 ? void 0 : props.style) || {}),
            } },
            content,
            footer),
        hasMask && (React.createElement(DropDownContainer, { onClick: maskClick, style: { zIndex: zIndex - 1 } }))), container);
};
function hide() {
    if (!lock) {
        return;
    }
    lock = false;
    const container = getContainer();
    ReactDOM.unmountComponentAtNode(container);
}
export default {
    show,
    hide,
};
function DropDownContainer(props) {
    return React.createElement("div", { ...props, className: 'dropdown-container' });
}
