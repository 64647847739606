import { useI18n } from 'panda-i18n';
import React, { useEffect, useState, useRef, useMemo, cloneElement, } from 'react';
import { Button } from '@/components/fusion';
import DragModal from './drag-modal';
import { normalizeChildren, getFieldInitName } from '../helper';
import storage from '../storage';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
// type Locale = typeof zhCN;
const getFilterItemMeta = (filterItemReactElement) => {
    const { enableConfig, name, label, children, _cnfilter_ismorefilter = false, span, } = filterItemReactElement.props;
    if (enableConfig === false)
        return null;
    if (typeof enableConfig === 'string') {
        return {
            label: enableConfig,
            value: enableConfig,
            _cnfilter_ismorefilter,
            span,
        };
    }
    if (typeof enableConfig === 'object') {
        return {
            ...enableConfig,
            _cnfilter_ismorefilter,
            span,
        };
    }
    if (name) {
        return {
            label: label,
            value: name,
            _cnfilter_ismorefilter,
            span,
        };
    }
    let meta = null;
    const normalizedChildren = normalizeChildren(children);
    normalizedChildren.some((child, idx) => {
        const childProps = (child === null || child === void 0 ? void 0 : child.props) || {};
        const initName = getFieldInitName(filterItemReactElement.props, childProps, idx);
        if (initName) {
            meta = {
                label,
                value: initName,
                _cnfilter_ismorefilter,
                span,
            };
            return true;
        }
        return false;
    });
    return meta;
};
export default ({ enableConfig, defaultConfigValue, configMeta, configValue: propConfigValue, onConfigValueChange, storageKey, children, onGetStorage, onSetStorage, 
// filterPopupContainerRef,
field, saveSelectSpan, gridProps, enableSaveSelected, onDragConfigItem, }) => {
    const $i18n = useI18n();
    const [configValue, setConfigValue] = useState(defaultConfigValue || null);
    const isInitStorageRef = useRef(true);
    const overlayRef = useRef(null);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [configInited, setConfigInited] = useState(false);
    useEffect(() => {
        if (propConfigValue !== undefined && propConfigValue !== configValue) {
            setConfigValue(propConfigValue);
        }
    }, [configValue, propConfigValue]);
    const storageRef = useRef(storage({
        storageKey,
        type: 'config',
        getData: onGetStorage,
        setData: onSetStorage,
    }));
    useEffect(() => {
        if (!enableConfig)
            return;
        storageRef.current
            .getData()
            .then((data) => {
            isInitStorageRef.current = false;
            if (data) {
                setConfigValue(data);
            }
        })
            .catch(() => { })
            .finally(() => {
            setConfigInited(true);
        });
    }, [enableConfig]);
    useEffect(() => {
        if (!enableConfig)
            return;
        if (isInitStorageRef.current)
            return;
        storageRef.current.setData(configValue).catch(() => { });
    }, [configValue, enableConfig]);
    const itemsMeta = useMemo(() => {
        if (!enableConfig)
            return [];
        if (configMeta && configMeta.length)
            return configMeta;
        return React.Children.map(children, (child) => getFilterItemMeta(child)).filter(Boolean);
    }, [enableConfig, children, configMeta]);
    const dataSource = useMemo(() => {
        if (!enableConfig)
            return [];
        if (!configValue) {
            return itemsMeta.map((meta) => ({
                ...meta,
                visible: !meta._cnfilter_ismorefilter,
            }));
        }
        const metaInConfig = [];
        const metaNotInConfig = [];
        itemsMeta.forEach((meta) => {
            const idx = configValue.findIndex((val) => val.name === (meta === null || meta === void 0 ? void 0 : meta.value));
            if (idx < 0) {
                metaNotInConfig.push({
                    ...meta,
                    visible: false,
                });
                return;
            }
            metaInConfig[idx] = {
                ...meta,
                visible: configValue[idx].visible,
            };
        });
        return [...metaInConfig.filter(Boolean), ...metaNotInConfig];
    }, [configValue, itemsMeta, enableConfig]);
    const renderConfigButton = (props) => {
        if (!enableConfig)
            return null;
        return (React.createElement(Button, { ...props, onClick: () => {
                sendLog({
                    id: 'cn-ui.cn-filter.clickSaveConfigButton',
                    name: 'CnFilter查询配置面板展示按钮点击',
                });
                setOverlayVisible((v) => !v);
            } },
            React.createElement("span", { ref: overlayRef },
                React.createElement(CnIcon, { type: "setting" }))));
    };
    const renderConfigButtonProps = (props) => {
        if (!enableConfig)
            return null;
        return {
            icon: 'setting',
            title: $i18n.get({ id: 'FilterSettings', dm: '查询设置' }),
            onClick: () => setOverlayVisible((v) => !v),
            ref: overlayRef,
            useIcon: true,
            ...props,
        };
    };
    const renderConfigModal = () => {
        if (!enableConfig)
            return null;
        return (React.createElement(DragModal, { ...{
                onDragConfigItem,
                enableSaveSelected,
                dataSource,
                updateDataSource,
                setOverlayVisible,
                configInited,
                configValue,
                field,
                overlayVisible,
                gridProps,
                saveSelectSpan,
                $i18n,
            } }));
    };
    // 内部状态改变更新配置 meta
    const updateConfigValue = (nextConfigValue) => {
        if (enableConfig) {
            onConfigValueChange && onConfigValueChange(nextConfigValue);
            if (propConfigValue === undefined) {
                setConfigValue(nextConfigValue);
            }
        }
    };
    const updateDataSource = (nextDataSource) => {
        updateConfigValue(nextDataSource.map((nextDataSourceItem) => {
            const { visible, value } = nextDataSourceItem;
            return {
                visible,
                name: value,
            };
        }));
    };
    const arrangeChildren = (initChildren) => {
        if (!enableConfig)
            return initChildren;
        let lastIdx = dataSource.length;
        const elementOrderList = React.Children.map(initChildren, (child) => {
            var _a;
            const meta = getFilterItemMeta(child);
            let idx = !meta
                ? -1
                : dataSource.findIndex((dataSourceItem) => dataSourceItem.value === (meta === null || meta === void 0 ? void 0 : meta.value));
            if (idx < 0) {
                idx = lastIdx++;
            }
            return {
                idx,
                element: cloneElement(child, {
                    style: ((_a = dataSource[idx]) === null || _a === void 0 ? void 0 : _a.visible) === false ? { display: 'none' } : {},
                }),
            };
        });
        elementOrderList.sort((a, b) => a.idx - b.idx);
        return elementOrderList.map((item) => item.element);
    };
    return {
        renderConfigButton,
        renderConfigButtonProps,
        renderConfigModal,
        arrangeChildren,
        children: arrangeChildren(children),
    };
};
