import * as React from 'react';
import isFunction from 'lodash/isFunction';
import { useField } from '@formily/react';
import CnTableCellFormat from '@/components/cn-table-cell-format';
import { safeCallFunction } from '@/components/cn-utils';
export default (tableCellProps) => {
    var _a, _b;
    const { format = 'text', tableCellItem, column } = tableCellProps || {};
    let cellFormat = CnTableCellFormat[format];
    if (isFunction(cellFormat)) {
        cellFormat = cellFormat(column);
    }
    if (!(cellFormat === null || cellFormat === void 0 ? void 0 : cellFormat.cell) || !tableCellItem)
        return null;
    if (typeof tableCellItem !== 'string' && tableCellItem.link) {
        tableCellItem.link = (_a = safeCallFunction(tableCellItem.link, useField())) !== null && _a !== void 0 ? _a : tableCellItem.link;
    }
    return (React.createElement("div", { style: {
            textAlign: (_b = column === null || column === void 0 ? void 0 : column.align) !== null && _b !== void 0 ? _b : cellFormat === null || cellFormat === void 0 ? void 0 : cellFormat.align
        } }, cellFormat.cell(tableCellItem)));
};
