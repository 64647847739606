import * as React from 'react';
import { Radio as NextRadio } from '@fusion/lib';
import $i18n from 'panda-i18n';
import { CnReadOnly } from '@/components/cn-read-only';
import locale from '@locale';
const renderPreview = (value, readOnlyProps) => {
    let showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnRadio' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnRadio' });
    }
    return (React.createElement(CnReadOnly, { value: showValue, type: "enum", ...readOnlyProps }));
};
function withI18n(c, p) {
    $i18n.init(p);
    return c;
}
export const CnRadio = withI18n(React.forwardRef((props, ref) => {
    const { readOnly, readOnlyProps, ...otherProps } = props;
    return (React.createElement(NextRadio, { "data-name": "CnRadio", ref: ref, isPreview: readOnly, renderPreview: (values) => renderPreview(values, readOnlyProps), ...otherProps }));
}), {
    locale,
    componentName: 'CnRadio',
    forwardRef: true,
});
CnRadio.displayName = 'CnRadio';
CnRadio.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
