import $i18n from 'panda-i18n';
import * as React from 'react';
import { Button } from '@/components/fusion';
import { CnIcon } from '@/components/cn-icon';
import { cssPrefix } from './constant';
/**
 * 渲染导出按钮
 */
export default function ExportButton(props) {
    const { onClick, children, loading, buttonProps, buttonText = $i18n.get({
        id: '31255238623236096.CNTM',
        dm: '导出',
        ns: 'CnExport',
    }), } = props;
    if (children) {
        return (React.createElement("span", { className: `${cssPrefix}button-wrapper`, onClick: onClick }, children));
    }
    return (React.createElement(Button, { ...buttonProps, loading: loading, iconSize: "medium", icons: {
            loading: loading ? undefined : (React.createElement(CnIcon, { size: "medium", style: { marginRight: 4 }, type: "export" })),
        }, onClick: onClick }, buttonText));
}
