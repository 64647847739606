import React from 'react';
import classnames from 'classnames';
import { useI18n } from 'panda-i18n';
import { RESULT_TYPE_MAP } from './constant';
import { getIcon } from './util';
import './index.scss';
export default function CnResultView(props) {
    // 拆除不需要向下传递的props参数
    const $i18n = useI18n();
    const { className, type, mode, size = 'normal', icon: _icon, title = RESULT_TYPE_MAP($i18n)[type].title, subTitle = RESULT_TYPE_MAP($i18n)[type].subTitle, extra, children = null, ...rest } = props;
    const icon = getIcon(_icon, type, size);
    return (React.createElement("div", { "data-name": "CnResult", className: classnames(className, 'cn-result', `cn-result-type-${type}`, `cn-result-mode-${mode}`, `cn-result-size-${size}`), ...rest },
        icon,
        title ? React.createElement("div", { className: "cn-result-title" }, title) : null,
        subTitle ? React.createElement("div", { className: "cn-result-subTitle" }, subTitle) : null,
        extra ? React.createElement("div", { className: "cn-result-extra" }, extra) : null,
        children));
}
CnResultView.defaultProps = {
    type: 'info',
    mode: 'page',
};
