import { useI18n } from 'panda-i18n';
import React, { useCallback, useEffect, useState, useRef, useMemo, } from 'react';
import { Select, Button, Menu, Input, Search, Overlay, Icon, } from '@/components/fusion';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnBalloon } from '@/components/cn-balloon';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import { isEmpty, genId } from '../helper';
import storage from '../storage';
import FilterItem from '../filter-item';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import { IconSave } from '../svg';
// declare interface Convert {
//   (values: Obj): SavedData;
// }
// declare interface Revert {
//   (data: SavedData): Obj;
// }
export default ({ enableSaveSelected, values, field, storageKey, beforeGetSaveSelected, beforeSetSaveSelected, onGetStorage, onSetStorage, filterPopupContainerRef, }) => {
    const $i18n = useI18n();
    const [options, setOptions] = useState([]);
    const [name, setName] = useState('');
    const [selected, setSelected] = useState();
    const currentSelectedValuesRef = useRef(null);
    const isInitStorageRef = useRef(true);
    const triggerRef = useRef(null);
    const overlayRef = useRef(null);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [habitSelectVisible, setHabitSelectVisible] = useState(false);
    const clearSelected = () => {
        const findSelected = options.find((i) => i.value === selected);
        if (!findSelected || !findSelected.data)
            return;
        if (!isEqual(findSelected.data, field.getValues())) {
            setSelected(undefined);
        }
    };
    const storageRef = useRef(storage({
        storageKey,
        type: 'selected',
        getData: onGetStorage,
        setData: onSetStorage,
    }));
    useEffect(() => {
        if (!enableSaveSelected)
            return;
        storageRef.current
            .getData()
            .then((data) => {
            isInitStorageRef.current = false;
            if (data) {
                setOptions(data);
            }
        })
            .catch(() => { });
    }, [enableSaveSelected]);
    useEffect(() => {
        if (!enableSaveSelected)
            return;
        if (isInitStorageRef.current)
            return;
        storageRef.current.setData(options).catch(() => { });
    }, [options, enableSaveSelected]);
    useEffect(() => {
        if (!enableSaveSelected)
            return;
        if (currentSelectedValuesRef.current &&
            Object.keys({
                ...currentSelectedValuesRef.current,
                ...values,
            }).some((key) => {
                var _a;
                return ((_a = currentSelectedValuesRef.current) === null || _a === void 0 ? void 0 : _a[key]) !== (values === null || values === void 0 ? void 0 : values[key]);
            })) {
            currentSelectedValuesRef.current = null;
            setSelected(undefined);
        }
    }, [values, enableSaveSelected]);
    const handleClose = useCallback(() => {
        var _a;
        ((_a = triggerRef.current) === null || _a === void 0 ? void 0 : _a.click) && triggerRef.current.click();
        setOverlayVisible(false);
    }, []);
    const asyncHandleSave = useCallback(async () => {
        let _values = values;
        if (beforeGetSaveSelected) {
            _values = (await beforeGetSaveSelected(_values)) || _values;
        }
        setOptions((_options) => {
            const value = genId(_options.map((opt) => opt.value));
            setSelected(value);
            _options = filter(_options, (i) => i.label !== name);
            // console.log(_options);
            const saveData = [
                {
                    label: name,
                    value,
                    data: _values,
                },
                ..._options,
            ];
            return saveData;
        });
        setName('');
        handleClose();
    }, [values, beforeGetSaveSelected, handleClose, name]);
    const renderSaveValidate = () => {
        const sameItem = options.find((i) => i.label === name);
        if (sameItem) {
            return (React.createElement("div", { className: "cn-ui-filter-save-panel-msg" },
                React.createElement(Icon, { className: "cn-next-icon-warning", style: { color: '#FFA64E', marginRight: 5 } }),
                $i18n.get({
                    id: 'TheNameIsDuplicatedAndWill_401244060',
                    dm: '名称重复，保存后将覆盖',
                    ns: 'CnFilter',
                })));
        }
        return '';
    };
    const handleSave = useCallback(() => {
        sendLog({
            id: 'cn-ui.cn-filter.saveSelectedOk',
            name: 'CnFilter查询条件保存按钮点击',
        });
        asyncHandleSave();
    }, [asyncHandleSave]);
    const handleRemove = useCallback((item, e) => {
        e.stopPropagation();
        setOptions((_options) => {
            return _options.filter((option) => option.value !== item.value);
        });
    }, []);
    const asyncHandleSelect = useCallback(async (value) => {
        setSelected(value || undefined);
        const target = value && (options || []).find((option) => option.value === value);
        if (!target || !target.data) {
            field.filterReset && field.filterReset();
            currentSelectedValuesRef.current = null;
            field.filterSearch && field.filterSearch();
            return;
        }
        let _values = target.data;
        if (beforeSetSaveSelected) {
            _values = (await beforeSetSaveSelected(_values)) || _values;
        }
        field.reset();
        currentSelectedValuesRef.current = _values;
        field.setValues(_values);
        field.filterChange && field.filterChange(_values, { field });
        field.filterSearch && field.filterSearch();
    }, [field, beforeSetSaveSelected, options]);
    const handleSelect = useCallback((value) => {
        asyncHandleSelect(parseInt(value, 10));
    }, [asyncHandleSelect]);
    const saveEnable = useMemo(() => {
        return (options.length ||
            Object.values(values || {}).some((value) => {
                return Array.isArray(value)
                    ? value.filter((val) => !isEmpty(val)).length > 0
                    : !isEmpty(value);
            }));
    }, [values, options]);
    const renderOption = useCallback((item) => {
        const removeTriggerRef = React.createRef();
        const handleRemoveClose = () => {
            var _a;
            ((_a = removeTriggerRef.current) === null || _a === void 0 ? void 0 : _a.click) && removeTriggerRef.current.click();
        };
        return (React.createElement("span", { className: "cn-ui-filter-save-option" },
            React.createElement("span", { className: "option-label" }, item.label),
            React.createElement("span", { onClick: (e) => e && e.stopPropagation() },
                React.createElement(CnBalloon, { v2: true, align: "tl", autoFocus: true, trigger: React.createElement("span", { ref: removeTriggerRef },
                        React.createElement(Button, { text: true, size: "large" },
                            React.createElement(CnIcon, { type: "close", className: "option-delete-icon" }))), closable: false, triggerType: "click" },
                    React.createElement("div", { className: "cn-ui-filter-save-text" }, $i18n.get({
                        id: 'ThisFilterCombinationWillBeLost_972042728',
                        dm: '确定要删除当前查询习惯吗？',
                    })),
                    React.createElement("div", { className: "cn-ui-filter-save-btns" },
                        React.createElement(Button, { type: "primary", size: "small", onClick: (e) => handleRemove(item, e) }, $i18n.get({ id: 'ok', dm: '确认' })),
                        React.createElement(Button, { size: "small", onClick: handleRemoveClose }, $i18n.get({ id: 'cancel', dm: '取消' })))))));
    }, [filterPopupContainerRef, handleRemove]);
    const renderSelector = useCallback((props) => {
        return (React.createElement(FilterItem, { label: $i18n.get({ id: 'myCriteria', dm: '查询习惯' }), ...props },
            React.createElement(Select, { ref: overlayRef, className: "select cn-ui-filter-habit", value: selected, dataSource: options, onChange: (value) => {
                    handleSelect(value);
                }, itemRender: (item) => renderOption(item), showSearch: true, hasClear: true, visible: habitSelectVisible, onVisibleChange: setHabitSelectVisible, onFocus: () => {
                    setOverlayVisible(false);
                }, addonAfter: React.createElement(CnTooltip, { align: "t", delay: 300, trigger: React.createElement(Button, { size: props.size, className: "save", onMouseDown: (e) => {
                            e.stopPropagation();
                            setOverlayVisible((v) => !v);
                            setHabitSelectVisible(false);
                            sendLog({
                                id: 'cn-ui.cn-filter.clickSaveSelected',
                                name: 'CnFilter查询条件弹层展示按钮点击',
                            });
                        }, onClick: (e) => {
                            e.stopPropagation();
                        } },
                        React.createElement(CnIcon, { type: "icon-favorites", size: props.size })) }, $i18n.get({
                    id: 'PreservationHabits',
                    dm: '保存习惯',
                    ns: 'CnFilter',
                })) })));
    }, [handleSelect, options, renderOption, selected, habitSelectVisible]);
    // const renderMenu = useCallback(() => {
    //   return options.length ? (
    //     <Menu onSelect={handleSelect} className='diy-menu' selectMode='single'>
    //       {options.map((item) => {
    //         return (
    //           <Menu.Item key={item.value}>
    //             <div className='habit-menu-item'>
    //               {item.label}
    //               <Button
    //                 className='diy-menu-button'
    //                 iconSize={'xs'}
    //                 onClick={(e) => handleRemove(item, e)}
    //                 text
    //               >
    //                 <Icon type='close' />
    //               </Button>
    //             </div>
    //           </Menu.Item>
    //         );
    //       })}
    //     </Menu>
    //   ) : null;
    // }, [handleRemove, handleSelect, options]);
    const renderMenu = () => {
        return options.length ? (React.createElement(Menu, { onSelect: handleSelect, className: "diy-menu", selectMode: "single" }, options.map((item) => {
            return (React.createElement(Menu.Item, { key: item.value },
                React.createElement("div", { className: "habit-menu-item" },
                    item.label,
                    React.createElement(Button, { className: "diy-menu-button", iconSize: 'xs', onClick: (e) => handleRemove(item, e), text: true },
                        React.createElement(CnIcon, { type: "close" })))));
        }))) : null;
    };
    const renderSaveBtns = useCallback((props) => {
        if (!enableSaveSelected)
            return null;
        const { size } = props;
        return saveEnable ? (React.createElement(CnBalloon, { v2: true, align: "tr", autoFocus: true, trigger: React.createElement("span", { ref: triggerRef, className: classNames('cn-ui-filter-icon-btn', `cn-ui-filter-icon-btn-${size}`, 'cn-ui-filter-icon-btn-save-btn'), onClick: (e) => !saveEnable && e && e.preventDefault() },
                React.createElement(Button, { size: size },
                    React.createElement(CnIcon, { type: "save" }))), closable: false, triggerType: "click", popupContainer: () => filterPopupContainerRef === null || filterPopupContainerRef === void 0 ? void 0 : filterPopupContainerRef.current },
            React.createElement("div", { className: "cn-ui-filter-save-line" },
                React.createElement(Input.Group, { addonAfter: React.createElement(Button, { className: "save", disabled: !name, onClick: handleSave },
                        React.createElement(CnIcon, { type: "save" })) },
                    React.createElement(Input, { value: name, onChange: setName }))),
            React.createElement("div", { className: "cn-ui-filter-saved-data" },
                " ",
                renderMenu()))) : (React.createElement(Button, { disabled: true, className: classNames('cn-ui-filter-icon-btn', `cn-ui-filter-icon-btn-${size}`) },
            React.createElement(CnIcon, { type: "save" })));
    }, [
        handleClose,
        handleSave,
        name,
        saveEnable,
        enableSaveSelected,
        filterPopupContainerRef,
    ]);
    const renderSaveButtonProps = () => {
        return {
            icon: 'save',
            // title: $i18n.get({ id: 'Save', dm: '保存' }),
            onClick: () => {
                setOverlayVisible((v) => !v);
            },
            // 临时先用title表示
            useIcon: false,
            title: (React.createElement("div", null,
                React.createElement(IconSave, null))),
            ref: overlayRef,
            // children: (
            //   <span ref={overlayRef}>
            //     <Icon type='set' />
            //   </span>
            // ),
        };
    };
    const renderSaveOverlay = useCallback(() => {
        if (!enableSaveSelected)
            return null;
        return (React.createElement(Overlay, { visible: overlayVisible, target: () => overlayRef.current, safeNode: () => overlayRef.current, onRequestClose: () => setOverlayVisible(false), 
            // container={() => filterPopupContainerRef?.current}
            align: "tc bc" },
            React.createElement("div", { className: "save-panel" },
                React.createElement("div", { className: "cn-ui-filter-search" },
                    React.createElement(Search, { style: { width: '100%' }, 
                        // size='small'
                        onVisibleChange: () => setMenuVisible(false), popupContent: renderMenu(), popupClassName: "cn-ui-filter-habit-list", visible: menuVisible, fillProps: "label", value: name, 
                        // todo
                        // searchText={<CnIcon type='save' size='large' />}
                        searchText: React.createElement("div", null,
                            React.createElement(IconSave, null)), hasIcon: false, onSearch: handleSave, onChange: setName, onFocus: () => setMenuVisible(true) })))));
    }, [
        enableSaveSelected,
        saveEnable,
        overlayVisible,
        name,
        handleSave,
        renderMenu,
        filterPopupContainerRef,
    ]);
    const renderInputSaveOverlay = () => {
        var _a;
        if (!enableSaveSelected)
            return null;
        return (React.createElement(Overlay, { visible: overlayVisible, target: () => overlayRef.current, safeNode: () => overlayRef.current, onRequestClose: () => setOverlayVisible(false), align: "tc bc" },
            React.createElement("div", { className: "cn-ui-filter-save-panel", style: { width: ((_a = overlayRef === null || overlayRef === void 0 ? void 0 : overlayRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 200 } },
                React.createElement(Input, { value: name, onChange: setName }),
                renderSaveValidate(),
                React.createElement("div", { className: "cn-ui-filter-save-panel-btns" },
                    React.createElement(Button, { onClick: () => {
                            setOverlayVisible(false);
                            sendLog({
                                id: 'cn-ui.cn-filter.saveSelectedCancel',
                                name: 'CnFilter查询条件取消按钮点击',
                            });
                        } }, $i18n.get({ id: 'cancel', dm: '取消', ns: 'CnFilter' })),
                    React.createElement(Button, { type: "primary", onClick: handleSave }, $i18n.get({ id: 'Save', dm: '保存', ns: 'CnFilter' }))))));
    };
    return {
        renderSelector,
        renderSaveBtns,
        renderSaveOverlay,
        renderSaveButtonProps,
        renderInputSaveOverlay,
        clearSelected,
    };
};
