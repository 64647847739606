import $i18n from 'panda-i18n'; /* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { rowIndex } from '../global';
export default function serialNumberPipeline(pipeline) {
    const serialNumberCol = {
        name: $i18n.get({ id: 'SerialNumber', dm: '序号', ns: 'CnBaseTable' }),
        width: 45,
        lock: true,
        sizeFixed: true,
        align: 'center',
        attach: false,
        symbol: rowIndex,
        render(_, record, rowIndex) {
            return rowIndex + 1;
        },
    };
    return pipeline.columns([serialNumberCol, ...pipeline.getColumns()]);
}
