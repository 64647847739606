import cx from 'classnames';
import * as React from 'react';
import './index.scss';
export const CnPageFooter = ({ className, style, leftSlot, middleSlot, middleSecondarySlot, rightSlot, ...otherProps }) => {
    const classes = cx('cn-ui-page-footer', className);
    return (React.createElement("div", { className: classes, ...otherProps },
        React.createElement("div", { className: "cn-ui-page-footer-header" }, leftSlot),
        React.createElement("div", { className: "cn-ui-page-footer-sub-header" }, middleSecondarySlot),
        React.createElement("div", { className: "cn-ui-page-footer-body" }, middleSlot),
        React.createElement("div", { className: "cn-ui-page-footer-footer" }, rightSlot)));
};
CnPageFooter.displayName = 'CnPageFooter';
