/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import classnames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
function SortIcon(props) {
    const { size = 32, style, className, order, ...rest } = props;
    return (React.createElement("div", { className: classnames('cn-table-sort-icon', className), ...rest },
        order === 'none' && React.createElement(CnIcon, { type: "triangle-sort", size: "small" }),
        order === 'asc' && React.createElement(CnIcon, { type: "triangle-up-fill", size: "small" }),
        order === 'desc' && React.createElement(CnIcon, { type: "triangle-down-fill", size: "small" }))
    // <svg
    //   className={classnames('cnc-table-sort-icon', {
    //     'cnc-table-icon-highlight': order === 'none',
    //     className,
    //   })}
    //   style={style}
    //   width={size}
    //   height={size}
    //   focusable="false"
    //   preserveAspectRatio="xMidYMid meet"
    //   viewBox="0 0 32 32"
    //   aria-hidden="true"
    //   {...rest}
    // >
    //   <path
    //     className={classnames('cnc-table-icon', {
    //       'cnc-table-icon-highlight': order === 'asc',
    //     })}
    //     transform="translate(0, 4)"
    //     d="M8 8L16 0 24 8z"
    //   />
    //   <path
    //     className={classnames('cnc-table-icon', {
    //       'cnc-table-icon-highlight': order === 'desc',
    //     })}
    //     transform="translate(0, -4)"
    //     d="M24 24L16 32 8 24z "
    //   />
    // </svg>
    );
}
export default function SortHeaderCell(props) {
    const { column, sortOrder, onToggle, children } = props;
    const justifyContent = column.align === 'right'
        ? 'flex-end'
        : column.align === 'center'
            ? 'center'
            : 'flex-start';
    return (React.createElement("div", { style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent,
        } },
        children,
        React.createElement(SortIcon, { style: { marginLeft: 2, flexShrink: 0 }, size: 16, order: sortOrder, onClick: onToggle })));
}
