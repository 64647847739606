import React, { useEffect, useRef } from 'react';
import RcUpload from 'rc-upload';
import { useOnSuccess } from './hooks/use-on-success';
import { useOnProgress } from './hooks/use-on-progress';
import { useOnError } from './hooks/use-on-error';
import { useOnStart } from './hooks/use-on-start';
export const UploadCore = (props) => {
    const { formatter, directory, method, headers, beforeUpload, customRequest, withCredentials, openFileDialogOnClick, onError: _onError, name = 'file', multiple, action, data, accept, disabled, onChange, setValue, } = props;
    const uploadRef = useRef();
    const onChangeRef = useRef(onChange);
    const onErrorRef = useRef(_onError);
    const formatterRef = useRef(formatter);
    useEffect(() => {
        onChangeRef.current = onChange;
        onErrorRef.current = _onError;
        formatterRef.current = formatter;
    }, [onChange, _onError, formatter]);
    const onStart = useOnStart({ setValue });
    const onProgress = useOnProgress({ setValue });
    const onSuccess = useOnSuccess({ setValue, onChangeRef, formatterRef });
    const onError = useOnError({ setValue, onChangeRef, onErrorRef });
    return (React.createElement(RcUpload, { ref: uploadRef, name: name, action: action, directory: directory, data: data, method: method, headers: headers, multiple: multiple, accept: accept, beforeUpload: beforeUpload, disabled: disabled, customRequest: customRequest, withCredentials: withCredentials, openFileDialogOnClick: openFileDialogOnClick, onStart: onStart, onSuccess: (res, file, xhr) => {
            if ((res === null || res === void 0 ? void 0 : res.success) === false) {
                const err = new Error(res.errorMsg);
                onError(err, res, file);
                return;
            }
            onSuccess(res, file, xhr);
        }, onError: onError, onProgress: onProgress }, props.children));
};
