import { useCallback } from 'react';
import { fileToObject, getFileIndex } from '../../../utils';
export const useOnSuccess = (props) => {
    const { setValue, onChangeRef, formatterRef } = props;
    return useCallback((response, _file, xhr) => {
        var _a;
        const file = fileToObject(_file, { status: 'done', xhr });
        file.response = ((_a = formatterRef.current) === null || _a === void 0 ? void 0 : _a.call(formatterRef, response, file)) || response;
        if (file.response) {
            file.url = file.response.url;
            file.downloadUrl = file.response.downloadUrl;
        }
        setValue((prev) => {
            var _a;
            const fileIndex = getFileIndex(file, prev);
            const value = prev.map((item, index) => {
                return index === fileIndex ? file : item;
            });
            (_a = onChangeRef.current) === null || _a === void 0 ? void 0 : _a.call(onChangeRef, value, file);
            return value;
        });
    }, [formatterRef, setValue, onChangeRef]);
};
