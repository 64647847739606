import * as React from 'react';
import { Balloon, Button } from '@/components/fusion';
import './employee-info.scss';
import $i18n from 'panda-i18n';
export class EmployeeInfo extends React.Component {
    constructor() {
        super(...arguments);
        this.renderCard = () => {
            const { dataSource } = this.props;
            const { name, nickName, workNo, department, dingding } = dataSource;
            return (React.createElement("div", { className: "cn-ui-employee-info", key: workNo },
                React.createElement("div", { className: "cn-ui-employee-info-header" },
                    React.createElement("div", { className: "cn-ui-employee-detail" },
                        React.createElement("div", { className: "cn-ui-employee-detail-header" },
                            React.createElement("img", { className: "employee-img", src: `https://work.alibaba-inc.com/photo/${workNo}.200x200.jpg?` })),
                        React.createElement("div", { className: "cn-ui-employee-detail-footer" },
                            React.createElement("div", { className: "employee-name" },
                                name,
                                nickName && `(${nickName})`),
                            React.createElement("div", { className: "employee-workid" }, workNo)))),
                React.createElement("div", { className: "cn-ui-employee-info-body" }, department),
                React.createElement("div", { className: "cn-ui-employee-info-footer" }, dingding ? (React.createElement(Button, { component: "a", type: "secondary", href: `dingtalk://dingtalkclient/action/sendmsg?dingtalk_id=${dingding}` }, $i18n.get({
                    id: 'DingTalkContact',
                    dm: '钉钉联系',
                    ns: 'CnEmployeeSelect',
                }))) : (React.createElement(Button, { component: "a", target: "_blank", type: "secondary", href: `https://work.alibaba-inc.com/nwpipe/u/${workNo}` }, $i18n.get({
                    id: '37329441080348672.CNTM',
                    dm: '阿里内外',
                    ns: 'CnEmployeeSelect',
                }))))));
        };
        this.renderTrigger = () => {
            const { dataSource } = this.props;
            const { name, nickName, workNo } = dataSource;
            return (React.createElement("div", { className: "cn-ui-employee-info-trigger" },
                React.createElement("img", { className: "cn-ui-employee-info-trigger-img", src: `https://work.alibaba-inc.com/photo/${workNo}.50x50.jpg` }),
                React.createElement("span", { className: "cn-ui-employee-info-trigger-text" },
                    name,
                    " ",
                    nickName && `(${nickName})`)));
        };
    }
    render() {
        const { dataSource, ...otherProps } = this.props;
        return (React.createElement(Balloon, { closable: false, popupClassName: "cn-ui-employee-info-popup", align: "bl", alignEdge: true, needAdjust: true, triggerType: ['hover', 'click'], autoFocus: false, trigger: this.renderTrigger(), ...otherProps }, this.renderCard()));
    }
}
EmployeeInfo.displayName = 'EmployeeInfo';
