import { isFunction } from './common';
export function isLeafNode(node) {
    return node.children == null || node.children.length === 0;
}
/** 遍历所有节点，并将节点收集到一个数组中.
 * order 参数可用于指定遍历规则：
 * * `pre` 前序遍历 （默认）
 * * `post` 后续遍历
 * * `leaf-only` 忽略内部节点，只收集叶子节点
 * */
export function collectNodes(nodes, order = 'pre') {
    const result = [];
    dfs(nodes);
    return result;
    function dfs(nodes) {
        if (nodes == null) {
            return;
        }
        for (const node of nodes) {
            if (isLeafNode(node)) {
                result.push(node);
            }
            else {
                if (order === 'pre') {
                    result.push(node);
                    dfs(node.children);
                }
                else if (order === 'post') {
                    dfs(node.children);
                    result.push(node);
                }
                else {
                    dfs(node.children);
                }
            }
        }
    }
}
/** 对树状结构的数据进行排序.
 * layeredSort 是一个递归的过程，针对树上的每一个父节点，该函数都会重新对其子节点数组（children) 进行排序.
 * */
export function layeredSort(array, compare) {
    return dfs(array);
    function dfs(rows) {
        if (!Array.isArray(array)) {
            return array;
        }
        return rows
            .map((row) => {
            if (isLeafNode(row)) {
                return row;
            }
            return { ...row, children: dfs(row.children) };
        })
            .sort(compare);
    }
}
export function getParent(dom, match, maxLevel) {
    if (dom && isFunction(match) && maxLevel > 0) {
        let level = 0;
        let nowDom = dom;
        while (level < maxLevel) {
            nowDom = nowDom === null || nowDom === void 0 ? void 0 : nowDom.parentElement;
            if (match(nowDom)) {
                return nowDom;
            }
            level++;
        }
    }
    return dom;
}
