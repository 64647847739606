import * as React from 'react';
import cx from 'classnames';
import $i18n, { withI18n } from 'panda-i18n';
import { CnTooltip } from '@/components/cn-tooltip';
import locale from '@locale';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { CascaderSelect as NextCascaderSelect, Loading, } from '@/components/fusion';
import { CnReadOnly } from '@/components/cn-read-only';
import { useCnRequest } from '@/components/cn-utils';
import { findDeep } from './utils';
import './index.scss';
function handleErrorInfo(error) {
    if (!error)
        return {};
    return {
        autoWidth: false,
        dataSource: [],
        notFoundContent: (React.createElement("div", { className: "cn-cascader-select-error" }, $i18n.get({
            id: 'RequestDataException',
            dm: '请求数据异常',
            ns: 'CnCascaderSelect',
        }))),
    };
}
function handleLoading(loading, data) {
    const ContentLoading = () => {
        return (React.createElement("div", { className: "content-loading" },
            React.createElement(Loading, { size: "medium" })));
    };
    const IsContentLoading = (data === null || data === void 0 ? void 0 : data.length) === 0 && loading;
    if (IsContentLoading) {
        return {
            notFoundContent: React.createElement(ContentLoading, null),
        };
    }
    if (loading) {
        return {
            state: 'loading',
        };
    }
    return {
        state: undefined,
    };
}
export const CnCascaderSelect = withI18n(React.forwardRef((props, ref) => {
    const { className, readOnly, readOnlyProps, popupContainer, requestConfig, onVisibleChange: onVisibleChangeProps, onChange: onChangeProps, filterLocal, enableRemoteLazyLoad, ...otherProps } = props;
    const [innerDataSource, setInnerDataSource] = React.useState([]);
    const existValues = React.useRef();
    const remoteLazyLoadItemRef = React.useRef();
    const selectRef = React.useRef(null);
    const insertSelectProps = {};
    const isRemoteDataSource = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = (res) => {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    const { run, runAsync, error, data, loading, mutate } = useCnRequest({
        ready: isRemoteDataSource,
        ...requestConfig,
    });
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            mutateDataSource: mutate,
            sendRequest: run,
            ...((_a = selectRef.current) !== null && _a !== void 0 ? _a : {}),
        };
    }, [selectRef]);
    const maxTagPlaceholder = (selectedValues) => {
        const trigger = (React.createElement("span", { className: "cn-next-select-tag-compact-inner" },
            $i18n.get({ id: 'Selected', dm: '已选择', ns: 'CnAsyncSelect' }),
            `${selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length}`,
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnAsyncSelect',
            })));
        const labels = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.map((obj) => {
            if (typeof obj === 'object') {
                return obj.label;
            }
            return obj;
        });
        return (React.createElement(CnTooltip, { trigger: trigger }, labels && labels.join(', ')));
    };
    React.useEffect(() => {
        // existValues !== props.value，则清空
        if (!('value' in props))
            return;
        let ev = existValues.current;
        ev = Array.isArray(ev) ? ev.map((i) => i.value) : ev === null || ev === void 0 ? void 0 : ev.value;
        if (!isEqual(ev, props.value)) {
            existValues.current = undefined;
        }
    }, [props.value]);
    React.useEffect(() => {
        // if (!isRemoteDataSource) return;
        if (loading)
            return;
        setInnerDataSource((previousDataSource) => {
            var _a;
            const rst = [];
            const ev = existValues.current;
            if (enableRemoteLazyLoad) {
                if (!((_a = remoteLazyLoadItemRef.current) === null || _a === void 0 ? void 0 : _a.value)) {
                    return data;
                }
                const valueItem = findDeep(previousDataSource, 'value', remoteLazyLoadItemRef.current.value);
                if (valueItem) {
                    valueItem.children = data;
                }
                return previousDataSource;
            }
            if (Array.isArray(ev)) {
                ev.length > 0 &&
                    ev.map((i) => {
                        if (!findDeep(data, 'value', i.value)) {
                            rst.push({
                                value: i.value,
                                label: i.label,
                            });
                        }
                    });
                return data.concat(rst);
            }
            if (ev && ev.value && !findDeep(data, 'value', ev.value)) {
                rst.push({
                    value: ev.value,
                    label: ev.label,
                });
                return data.concat(rst);
            }
            return data;
        });
    }, [data]);
    insertSelectProps.dataSource = innerDataSource || [];
    if (isRemoteDataSource) {
        insertSelectProps.resultAutoWidth = false;
    }
    const errorInfo = handleErrorInfo(error);
    const loadingInfo = handleLoading(loading, data);
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // 开启远程异步加载数据时，关闭搜索功能
    if (enableRemoteLazyLoad) {
        otherProps.showSearch = false;
        otherProps.loadData = (item) => {
            remoteLazyLoadItemRef.current = item;
            return runAsync({
                [requestConfig.remoteLazyLoadKey || 'value']: item.value,
            }).catch(err => {
                console.log(err);
            });
        };
    }
    // onSearch 自动包装
    // 仅在 showSearch 且无 filterLocal 为 false 情况启用
    const enableOnSearch = otherProps.showSearch && !otherProps.onSearch && !filterLocal;
    if (enableOnSearch) {
        insertSelectProps.onSearch = (inputValue) => {
            run({
                [requestConfig.searchKey || 'key']: inputValue,
            });
        };
        insertSelectProps.filter = () => true;
    }
    const onVisibleChange = (visible, type) => {
        if (typeof onVisibleChangeProps === 'function') {
            onVisibleChangeProps(visible, type, run);
        }
    };
    const onChange = (v, d, e) => {
        if (isRemoteDataSource) {
            existValues.current = d;
            if (!v || !v.length) {
                run({
                    [requestConfig.searchKey || 'key']: '',
                });
            }
        }
        if (typeof onChangeProps === 'function') {
            onChangeProps(v, d, e);
        }
    };
    const renderPreview = (values) => {
        return (React.createElement(CnReadOnly, { value: values, valueSeparator: ' / ', type: "enum", ...{
                ...pick(props, [
                    'addonBefore',
                    'innerBefore',
                    'addonTextBefore',
                    'addonTextAfter',
                    'innerAfter',
                    'addonAfter',
                ]),
                ...readOnlyProps,
            } }));
    };
    return (React.createElement(NextCascaderSelect, { "data-name": "CnCascaderSelect", className: cx({
            'cn-ui-cascader-select': true,
        }, className), ref: selectRef, isPreview: readOnly, renderPreview: renderPreview, maxTagPlaceholder: maxTagPlaceholder, popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : ((e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || document.body), ...insertSelectProps, ...omit(otherProps, ['$i18n']), onVisibleChange: onVisibleChange, onChange: onChange }));
}), {
    componentName: 'CnCascaderSelect',
    locale,
    forwardRef: true,
});
CnCascaderSelect.displayName = 'CnCascaderSelect';
CnCascaderSelect.defaultProps = {
    maxTagCount: 2,
    tagInline: true,
    requestConfig: {},
    readOnlyProps: {
        valueSeparator: ' / ',
        type: 'enum',
    },
    filterLocal: true,
};
