import * as React from 'react';
import cx from 'classnames';
import { throttle } from 'throttle-debounce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import $i18n from 'panda-i18n';
// import { Select } from '@/components/fusion';
import { CnAsyncSelect as Select } from '@/components/cn-async-select';
import { getCnEnv } from '@/components/cn-utils';
import request from './request';
import { EmployeeInfo } from './employee-info';
import { transformValue, transformValueToArray, renderEmployeeItem, } from './utils';
const env = getCnEnv();
function getUrl(currentEnv) {
    switch (currentEnv) {
        case 'daily':
            return 'https://cmgw.cainiao.test';
        case 'pre':
            return 'https://pre-ei.cainiao.com';
        case 'prod':
            return 'https://ei.cainiao.com';
        default:
            return 'https://ei.cainiao.com';
    }
}
const url = getUrl(env);
export class EmployeeSelect extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const changeState = {};
        if ('value' in nextProps) {
            const nextSelected = transformValueToArray(nextProps.value, nextProps.valuePaddingZero, nextProps.valueCommaJoin);
            if (!isEqual(nextSelected, prevState.selected)) {
                changeState.selected = nextSelected;
            }
            if (nextProps.useDetailValue) {
                const value = Array.isArray(nextProps.value)
                    ? nextProps.value
                    : [nextProps.value];
                const employeeMap = value.reduce((pre, cur) => ({ ...pre, [cur === null || cur === void 0 ? void 0 : cur.workNo]: cur }), {});
                changeState.employeeMap = {
                    ...((prevState === null || prevState === void 0 ? void 0 : prevState.employeeMap) || {}),
                    ...employeeMap,
                };
            }
        }
        if ('dataSource' in nextProps) {
            const processData = EmployeeSelect.processDataSource(nextProps.dataSource, prevState.employeeMap);
            changeState.employeeMap = processData.employeeMap;
            changeState.dataSource = processData.dataSource;
        }
        return changeState;
    }
    constructor(props) {
        super(props);
        /**
         * 根据 props 的值 自动搜索
         * 用于获取到用户工号后，填充 cache 的用途
         * 如果 cache 中已经有了全部的人员则不需要再次搜索
         * @param {String|Array} searchValue 搜索条件
         */
        // eslint-disable-next-line react/sort-comp
        this.autoSearch = (searchValue) => {
            if (searchValue.length > 0) {
                const searchStr = transformValue(searchValue);
                if (!this.checkEmployeeCache(searchStr.split(','))) {
                    this.searchEmployee(searchStr, 'staffId');
                }
            }
        };
        /**
         * 根据搜索条件去搜索人
         * @param {String} searchStr 搜索关键字 工号 111499 或 111499,222444
         */
        this.searchEmployee = (searchStr, fromType) => {
            var _a, _b, _c, _d, _e, _f;
            const { service, onSearch, serviceType, host = '', mokelay } = this.props;
            const { employeeMap } = this.state;
            if (onSearch) {
                // 当用户自定义 onSearch 的情况下，不执行接口请求
                onSearch(searchStr);
            }
            else if ('dataSource' in this.props) {
                // 当用户自定义 dataSource 的情况下，不执行接口请求
            }
            else if (service) {
                // 自定义请求能力
                this.setState({
                    loading: true,
                });
                service(searchStr, fromType)
                    .then((res) => {
                    const processData = EmployeeSelect.processDataSource(res, employeeMap);
                    this.setState({
                        ...processData,
                        loading: false,
                    });
                })
                    .catch(() => {
                    this.setState({
                        loading: false,
                    });
                });
            }
            else {
                // 执行内置请求
                if (serviceType === 'cainiao') {
                    if (fromType === 'keywords') {
                        this.setState({
                            loading: true,
                        });
                        const result = mokelay
                            ? request({
                                // url: '/api/tars2/user/getCnUserBySearchKey',
                                url: `${host}/cmdp/hysmab/fuzzyQueryOnJobEmployee`,
                                // headers: {
                                //   mokelay: true,
                                // },
                                method: 'POST',
                                data: {
                                    searchKey: searchStr,
                                    typeList: (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.typeList) === null || _b === void 0 ? void 0 : _b.join(','),
                                    isOnJob: (_c = this.props) === null || _c === void 0 ? void 0 : _c.isOnJob,
                                },
                                crossOrigin: true,
                                withCredentials: true,
                                isCommonRequest: true,
                            })
                            : request({
                                url: `${host}/cn-common-proxy/post`,
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    path: '/cmdp/hysmab/fuzzyQueryOnJobEmployee',
                                    params: {
                                        searchKey: searchStr,
                                        typeList: (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.typeList) === null || _e === void 0 ? void 0 : _e.join(','),
                                        isOnJob: (_f = this.props) === null || _f === void 0 ? void 0 : _f.isOnJob,
                                    },
                                },
                            });
                        result
                            .then((res) => {
                            const processData = EmployeeSelect.processDataSource(res.data.data, employeeMap);
                            this.setState({
                                ...processData,
                                loading: false,
                            });
                        })
                            .catch(() => {
                            this.setState({
                                loading: false,
                            });
                        });
                    }
                    if (fromType === 'staffId' && searchStr) {
                        // request({
                        //   url: `${url}/ei/bfsq4i/invokeCmdp`,
                        //   method: 'post',
                        //   data: {
                        //     apiName: 'cn_user_search_by_emp_id',
                        //     inParams: {
                        //       empId: searchStr,
                        //     },
                        //   },
                        //   crossOrigin: true,
                        //   withCredentials: true,
                        //   isCommonRequest: true,
                        // })
                        this.setState({
                            loading: true,
                        });
                        const result = mokelay
                            ? request({
                                url: `${host}/cmdp/hysmab/getOnJobEmployeeByEmpNo`,
                                // headers: {
                                //   mokelay: true,
                                // },
                                method: 'post',
                                data: { empId: searchStr },
                                crossOrigin: true,
                                withCredentials: true,
                                isCommonRequest: true,
                            })
                            : request({
                                url: `${host}/cn-common-proxy/post`,
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    path: '/cmdp/hysmab/getOnJobEmployeeByEmpNo',
                                    params: { empId: searchStr },
                                },
                            });
                        result
                            .then((res) => {
                            const processData = EmployeeSelect.processDataSource(res.data.data, employeeMap);
                            this.setState({
                                employeeMap: processData.employeeMap,
                                loading: false,
                            });
                        })
                            .catch(() => {
                            this.setState({
                                loading: false,
                            });
                        });
                    }
                }
                if (serviceType === 'ali') {
                    console.log(fromType, 'fromType');
                    // TODO: 接入阿里员工数据源
                    this.setState({
                        loading: true,
                    });
                    // if
                    if (fromType === 'keywords') {
                        const result = mokelay
                            ? request({
                                // url: '/api/tars2/user/getCnUserBySearchKey',
                                url: `${host}/cmdp/hysmab/getAliUserInfoBySearchKey`,
                                // headers: {
                                //   mokelay: true,
                                // },
                                method: 'POST',
                                data: { searchKey: searchStr },
                                crossOrigin: true,
                                withCredentials: true,
                                isCommonRequest: true,
                            })
                            : request({
                                url: `${host}/cn-common-proxy/post`,
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    path: '/cmdp/hysmab/getAliUserInfoBySearchKey',
                                    params: { searchKey: searchStr },
                                },
                            });
                        result
                            .then((res) => {
                            const processData = EmployeeSelect.processDataSource(res.data.data, employeeMap);
                            console.log({ ...processData }, 'elp-----');
                            this.setState({
                                ...processData,
                                employeeMap: { ...processData.employeeMap },
                                loading: false,
                            }, () => {
                                console.log(this.state);
                            });
                        })
                            .catch(() => {
                            this.setState({
                                loading: false,
                            });
                        });
                    }
                    if (fromType === 'staffId' && searchStr) {
                        const result = mokelay
                            ? request({
                                // url: '/api/tars2/user/getCnUserBySearchKey',
                                url: `${host}/cmdp/hysmab/getAliUserInfoByEmpNo`,
                                // headers: {
                                //   mokelay: true,
                                // },
                                method: 'POST',
                                data: { empId: searchStr },
                                crossOrigin: true,
                                withCredentials: true,
                                isCommonRequest: true,
                            })
                            : request({
                                url: `${host}/cn-common-proxy/post`,
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    path: '/cmdp/hysmab/getAliUserInfoByEmpNo',
                                    params: { empId: searchStr },
                                },
                            });
                        result
                            .then((res) => {
                            const processData = EmployeeSelect.processDataSource(res.data.data, employeeMap);
                            console.log({ ...processData }, 'elp-----');
                            this.setState({
                                ...processData,
                                employeeMap: { ...processData.employeeMap },
                                loading: false,
                            }, () => {
                                console.log(this.state);
                            });
                        })
                            .catch(() => {
                            this.setState({
                                loading: false,
                            });
                        });
                    }
                }
            }
        };
        this.transUsers = (value) => {
            return transformValueToArray(value, this.props.valuePaddingZero, this.props.valueCommaJoin);
        };
        this.handleValueChange = (newValue) => {
            const { employeeMap } = this.state;
            const { onChange, mode, useDetailValue, valueCommaJoin } = this.props;
            let newValueArray = this.transUsers(newValue);
            if (useDetailValue) {
                console.log(employeeMap, newValueArray);
                newValueArray = newValueArray.map((newItem) => {
                    if (employeeMap[newItem]) {
                        return employeeMap[newItem];
                    }
                    return { workNo: newItem };
                });
            }
            if (mode === 'single') {
                onChange && onChange(newValueArray[0] || null, newValue);
            }
            if (mode === 'multiple' && !valueCommaJoin) {
                onChange && onChange(newValueArray, newValue);
            }
            if (mode === 'multiple' && valueCommaJoin) {
                onChange && onChange(transformValue(newValueArray), newValue);
            }
            this.setState({
                selected: this.transUsers(newValue),
            });
        };
        this.selectedValueRender = (employeeStaffId) => {
            var _a;
            if (isEmpty(employeeStaffId))
                return;
            const { customValueRender } = this.props;
            const { employeeMap } = this.state;
            // 可能是个数组
            if (Array.isArray(employeeStaffId)) {
                employeeStaffId = (_a = employeeStaffId[0]) === null || _a === void 0 ? void 0 : _a.value;
            }
            else {
                employeeStaffId = employeeStaffId.value
                    ? employeeStaffId.value
                    : employeeStaffId;
            }
            const selectedEmployee = employeeMap[employeeStaffId];
            if (customValueRender) {
                return customValueRender(selectedEmployee || {});
            }
            return (React.createElement("span", { key: employeeStaffId }, (selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.nickName) ||
                (selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.name) ||
                employeeStaffId ||
                ''));
        };
        this.handleSearchChange = throttle(1200, (searchStr) => {
            this.searchEmployee(searchStr, 'keywords');
        });
        this.handleVisibleChange = (visible) => {
            this.setState({
                visible,
            });
        };
        this.renderReadStatus = () => {
            const { employeeMap, selected } = this.state;
            const { readOnlyRender } = this.props;
            const dataSource = [];
            selected.forEach((workid) => {
                if (employeeMap[workid]) {
                    dataSource.push(employeeMap[workid]);
                }
                else {
                    dataSource.push({
                        name: workid,
                        workNo: workid,
                    });
                }
            });
            if (readOnlyRender) {
                return readOnlyRender(dataSource);
            }
            return (React.createElement("div", { className: "cn-ui-employee-select-readonly" }, dataSource.map((empl, i) => (React.createElement(React.Fragment, { key: empl.workNo },
                React.createElement(EmployeeInfo, { dataSource: empl }),
                i !== dataSource.length - 1 && '、')))));
        };
        const st = {
            selected: this.transUsers(props.value || props.defaultValue),
            employeeMap: {},
            loading: false,
            dataSource: [],
            visible: false,
            urlPrefix: props.remoteService === 'local' ? '/cn-mokelay' : '',
        };
        if (props.dataSource) {
            const processData = EmployeeSelect.processDataSource(props.dataSource, {});
            st.dataSource = processData.dataSource;
            st.employeeMap = processData.employeeMap;
        }
        this.state = st;
    }
    componentDidMount() {
        // 那么先自动请求一波
        this.searchEmployee(transformValue(this.state.selected), 'staffId');
    }
    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevState.selected, this.state.selected)) {
            // 当 selected 更新后，更新 employeeMap 详细值
            this.autoSearch(this.state.selected);
        }
    }
    /**
     * 检查工号数组是否都已经在 cache 中存在，如果都存在返回 true，反之返回 false
     */
    checkEmployeeCache(staffIdArray) {
        const { employeeMap } = this.state;
        const existInCache = staffIdArray.find((item) => {
            if (!employeeMap[item]) {
                return true;
            }
            return false;
        });
        // 如果没找到则 notExistInCache === undefined
        return !existInCache;
    }
    render() {
        const { dataSource, selected, loading, visible } = this.state;
        const { mode, style = {}, className, readOnly, itemPropsRef, popupContainer, } = this.props;
        if (itemPropsRef) {
            itemPropsRef.current = {
                renderSelected: (key, value, itemLabel) => {
                    if (Array.isArray(value)) {
                        return (React.createElement("span", null,
                            itemLabel,
                            ":",
                            value.map((valueItem) => {
                                return React.createElement("span", null,
                                    " ",
                                    this.selectedValueRender(valueItem));
                            })));
                    }
                    return (React.createElement("span", null,
                        itemLabel,
                        ":",
                        this.selectedValueRender(value)));
                },
            };
        }
        const selectStyle = {
            // minWidth: '200px',
            ...style,
        };
        const classes = cx({
            'cn-ui-employee-select': true,
            [className]: !!className,
        });
        // let selectState;
        // if (loading) selectState = 'loading';
        const selectState = loading ? 'loading' : undefined;
        if (readOnly)
            return this.renderReadStatus();
        const selectProps = omit(this.props, [
            'style',
            'className',
            'mode',
            'readOnly',
            'readOnlyRender',
            'useDetailValue',
            'value',
            'onChange',
            'locale',
            'customValueRender',
            'service',
            'onSearch',
            'dataSource',
        ]);
        return (React.createElement(Select, { "data-name": "CnEmployeeSelect", style: selectStyle, className: classes, mode: mode, value: selected, valueRender: this.selectedValueRender, dataSource: dataSource, visible: visible, onVisibleChange: this.handleVisibleChange, itemRender: (item) => renderEmployeeItem(item), onChange: this.handleValueChange, onSearch: this.handleSearchChange, showSearch: true, hasClear: true, filterLocal: false, popupClassName: "cn-ui-employee-select-popup", placeholder: $i18n.get({
                id: 'PleaseEnterWorkNumberOrFlower_129025031',
                dm: '请输入工号或花名',
                ns: 'CnEmployeeSelect',
            }), notFoundContent: $i18n.get({
                id: 'RelevantPersonnelNotFound',
                dm: '未找到相关人员',
                ns: 'CnEmployeeSelect',
            }), state: selectState, popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : ((e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || document.body), ...selectProps }));
    }
}
EmployeeSelect.displayName = 'EmployeeSelect';
EmployeeSelect.defaultProps = {
    mode: 'single',
    mokelay: true,
    remoteService: 'local',
    useDetailValue: false,
    useInternalService: false,
    valuePaddingZero: true,
    isOnJob: true,
    typeList: ['REGULAR', 'OUTSOURCING'],
};
/**
 * 将 dataSource 处理成 employeeMap (缓存)
 */
EmployeeSelect.processDataSource = (dataSource, employeeMap) => {
    const formattedEmployees = dataSource.map((item) => ({
        ...item,
        value: item.workNo,
    }));
    formattedEmployees.forEach((employeeItem) => {
        if (employeeItem.workNo && !employeeMap[employeeItem.workNo]) {
            employeeMap[employeeItem.workNo] = employeeItem;
        }
        if (employeeItem.workNo && String(employeeItem.workNo).startsWith('0')) {
            const endNumber = employeeItem.workNo.slice(1);
            employeeMap[endNumber] = {
                ...employeeItem,
                workNo: endNumber,
                value: endNumber,
            };
        }
    });
    return {
        dataSource: formattedEmployees,
        employeeMap,
    };
};
