import deaultAllNeedCalChar from './asciiWidth';
// 原则上，基于本地环境测试各个字符大小的操作只做一次，其他情况，全部按照比例来算
function calcTextWidth(str, map, baseFonSize, radix = 1) {
    if (typeof str !== 'string') {
        str = String(str);
    }
    const strArr = str.split('');
    let count = 0;
    strArr.forEach((char) => {
        const chineseRE = /[^ -~]/;
        if (map[char]) {
            count += map[char];
        }
        else if (chineseRE.test(char)) {
            count += baseFonSize;
        }
        else {
            count += 0.5 * baseFonSize;
        }
    });
    return count * radix;
}
function fixedWidthMapToCharMap(allNeedCalChar) {
    let hasCaled = false;
    const charToWMap = {};
    return function (measureEl) {
        if (hasCaled) {
            return charToWMap;
        }
        hasCaled = true;
        allNeedCalChar.forEach((char) => {
            measureEl.textContent = char;
            charToWMap[char] = measureEl.offsetWidth;
        });
        return charToWMap;
    };
}
function getValByMaybeFunction(val, opts) {
    if (val instanceof Function) {
        return val.apply(null, opts);
    }
    return val;
}
function getCompareCal(charToWMap, cellFontSize) {
    return function (text, addtionWidth = 0) {
        return calcTextWidth(text, charToWMap, cellFontSize) + addtionWidth;
    };
}
export default function calcColumnWidths(columns, dataSource, opts) {
    const { headerFontSize, cellFontSize, sampleRate, additionalWidth, maxWidth, getCustomWidth, allNeedCalChar, } = Object.assign({
        headerFontSize: 12,
        cellFontSize: 12,
        sampleRate: 1,
        additionalWidth: 18,
        maxWidth: 460,
        getCustomWidth: (w) => w,
    }, opts);
    const widths = {};
    const maxLengthMap = {};
    const maxWidthMap = {};
    const keys = [];
    const cellTextCalRulesMap = {};
    const cellAdditionWidthCalFunctions = {}; // 配置保存
    const cellAdditionalWidths = {};
    const measureEl = document.createElement('span');
    measureEl.style.width = 'fit-content';
    measureEl.style.cssText = `
  position: fixed;
  top: -9999px;
  left: -9999px;
  z-index: -100;
  white-space: pre-wrap;
  font-weight: bold;
`;
    measureEl.style.fontSize = `${headerFontSize}px`;
    document.body.appendChild(measureEl);
    const charToWMap = fixedWidthMapToCharMap(allNeedCalChar || deaultAllNeedCalChar)(measureEl);
    // eslint-disable-next-line no-unused-expressions
    columns === null || columns === void 0 ? void 0 : columns.forEach((item) => {
        var _a;
        const { key, code, name, sortable, filter, contentWidthRule, dataIndex, title, maxWidth: itemMaxWidth, } = item;
        const { text: headerCalText, additionWidth: headerAdditionWidth } = (contentWidthRule === null || contentWidthRule === void 0 ? void 0 : contentWidthRule.header) || {};
        const cellRule = (_a = contentWidthRule === null || contentWidthRule === void 0 ? void 0 : contentWidthRule.cell) !== null && _a !== void 0 ? _a : contentWidthRule;
        const { text: calText, additionWidth: cellAdditionWidth } = cellRule || {};
        const usedKey = (key || code || dataIndex);
        // 添加header复杂计算方式contentWidthRule
        measureEl.textContent =
            headerCalText instanceof Function ? headerCalText(item) : name !== null && name !== void 0 ? name : title;
        widths[usedKey] =
            measureEl.offsetWidth +
                (parseInt(getValByMaybeFunction(headerAdditionWidth, [])) || 0);
        keys.push(usedKey);
        // 具备关闭能力，itemMaxWidth === false 则表示不存在最大宽度， 也可以自定义最大宽度 itemMaxWidth === number
        maxWidthMap[usedKey] =
            itemMaxWidth === false ? Infinity : itemMaxWidth !== null && itemMaxWidth !== void 0 ? itemMaxWidth : maxWidth;
        if (calText) {
            // 计算函数
            cellTextCalRulesMap[usedKey] = calText;
        }
        if (parseInt(cellAdditionWidth) > 0) {
            cellAdditionalWidths[usedKey] = parseInt(cellAdditionWidth);
        }
        else if (cellAdditionWidth instanceof Function) {
            cellAdditionWidthCalFunctions[usedKey] = cellAdditionWidth;
        }
    });
    const step = Math.ceil(1 / sampleRate);
    const calcCompareWidth = getCompareCal(charToWMap, cellFontSize);
    for (let i = 0; i < (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length); i += step) {
        const item = dataSource[i];
        keys.forEach((key) => {
            var _a;
            const cellRuleTextFunc = cellTextCalRulesMap[key];
            // additionCache 为function，则计算逻辑，则应该被包含在compare逻辑中
            const additionCalFunction = cellAdditionWidthCalFunctions[key];
            const nowcellAddtionWidth = cellAdditionalWidths[key];
            let val = item[key];
            if (cellRuleTextFunc instanceof Function ||
                additionCalFunction ||
                nowcellAddtionWidth) {
                const text = cellRuleTextFunc instanceof Function
                    ? cellRuleTextFunc(val, i, item)
                    : val !== null && val !== void 0 ? val : '';
                const cellAdditionalWidth = additionCalFunction instanceof Function
                    ? getValByMaybeFunction(additionCalFunction, [val, i, item])
                    : nowcellAddtionWidth;
                if ((!maxLengthMap[key] && !cellAdditionalWidths[key]) ||
                    // 和之前存的比较，取大的
                    calcCompareWidth(text, cellAdditionalWidth) >
                        calcCompareWidth(maxLengthMap[key], (_a = cellAdditionalWidths[key]) !== null && _a !== void 0 ? _a : 0)) {
                    // 这里的逻辑，是记录目前某列宽度最大行的一些信息，不断对比
                    maxLengthMap[key] = text;
                    cellAdditionalWidths[key] = cellAdditionalWidth;
                }
                return;
            }
            if (val == null)
                return;
            if (Array.isArray(val)) {
                val = val.reduce((prev, curr) => {
                    return calcTextWidth(curr, charToWMap, cellFontSize) >
                        calcTextWidth(prev, charToWMap, cellFontSize)
                        ? curr
                        : prev;
                }, 0);
            }
            if (!maxLengthMap[key] ||
                calcTextWidth(val, charToWMap, cellFontSize) >
                    calcTextWidth(maxLengthMap[key], charToWMap, cellFontSize)) {
                maxLengthMap[key] = val;
            }
        });
    }
    measureEl.style.fontSize = `${cellFontSize}px`;
    keys.forEach((key) => {
        var _a;
        const maxLengthStr = maxLengthMap[key];
        const cellAdditionalWidth = cellAdditionalWidths[key];
        const itemMaxWidth = maxWidthMap[key];
        measureEl.textContent = String(maxLengthStr !== null && maxLengthStr !== void 0 ? maxLengthStr : '');
        const width = Math.min(Math.max(widths[key], // 头部宽度
        measureEl.offsetWidth + (parseInt(cellAdditionalWidth) || 0)) + additionalWidth, itemMaxWidth);
        widths[key] = (_a = getCustomWidth(width, key)) !== null && _a !== void 0 ? _a : width;
    });
    document.body.removeChild(measureEl);
    return widths;
}
