import React, { useEffect, useState, useMemo } from 'react';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { motion } from 'framer-motion';
import cn from 'classnames';
import uniq from 'lodash/uniq';
import xor from 'lodash/xor';
import { configResponsive, useResponsive } from 'ahooks';
import { CnIcon } from '@/components/cn-icon';
import { isKeyParent, selectKeyAndExpandParent, findMenuItemWithChildren } from './utils';
import './index.scss';
configResponsive({
    small: 0,
    middle: 1280,
    large: 1920,
});
const SIDEBAR_WIDTH = {
    FOLD: 60,
    UNFOLD: 224,
};
export const SideBar = ({ leafItemIconRender, menu = [], selectedMenuKey, onSelectMenuItem, isFold, setFold, onCollapseClick, hideMenuItemIcon, defaultExpandAll, }) => {
    // 屏幕状态
    const responsive = useResponsive();
    // 展开状态
    const [expandKeys, setExpandKeys] = useState([]);
    // 是否 hover 悬浮
    const [isPreview, setPreview] = useState(false);
    // 是否是隐藏一级菜单 icon 的收起状态
    const isHideMenuItemIconFold = useMemo(() => hideMenuItemIcon && isFold && !isPreview, [hideMenuItemIcon, isFold, isPreview]);
    let cancelPreview = false; // 用来控制 preview 状态展示延迟
    useEffect(() => {
        const menuExpandKeys = selectKeyAndExpandParent(menu, selectedMenuKey);
        if (defaultExpandAll) {
            setExpandKeys(findMenuItemWithChildren(menu));
        }
        else if (!isFold && Array.isArray(menuExpandKeys) && menuExpandKeys.length > 0) {
            setExpandKeys(uniq(expandKeys.concat(menuExpandKeys)));
        }
    }, [menu, selectedMenuKey, defaultExpandAll]);
    React.useEffect(() => {
        const middleActive = responsive.middle;
        if (!middleActive) {
            handleClickFoldBtn(true);
        }
    }, [responsive.middle]);
    useEffect(() => {
        if (isFold) {
            document.body.classList.add('sidebar-fold');
        }
        else {
            document.body.classList.remove('sidebar-fold');
        }
    }, [isFold]);
    // 展开收起菜单
    const toggleMenuVisibility = (menuItem) => {
        var _a;
        if ((_a = menuItem.children) === null || _a === void 0 ? void 0 : _a.length) {
            setExpandKeys(xor(expandKeys, [menuItem.key]));
        }
    };
    // 当侧边栏为收起状态时候，移上去要预览
    const handleExpandAside = (ev) => {
        var _a, _b;
        cancelPreview = false;
        if ((_b = (_a = ev.nativeEvent) === null || _a === void 0 ? void 0 : _a.toElement) === null || _b === void 0 ? void 0 : _b.classList.contains('folder'))
            return;
        if (!isFold || isPreview)
            return;
        const timer = setTimeout(() => {
            clearTimeout(timer);
            if (cancelPreview) {
                cancelPreview = false;
                return;
            }
            setPreview(true);
            const menuExpandKeys = selectKeyAndExpandParent(menu, selectedMenuKey);
            if (defaultExpandAll) {
                setExpandKeys(findMenuItemWithChildren(menu));
            }
            else if (Array.isArray(menuExpandKeys) && menuExpandKeys.length > 0) {
                setExpandKeys(menuExpandKeys);
            }
        }, 100);
    };
    // 当侧边栏为预览态的时候，移出要取消预览
    const handleFoldAside = () => {
        cancelPreview = true;
        if (!isPreview)
            return;
        setPreview(false);
        setExpandKeys([]);
    };
    // 点击侧边栏折叠按钮
    const handleClickFoldBtn = (newFoldStatus) => {
        var _a, _b;
        const realNewFoldStatus = newFoldStatus !== null && newFoldStatus !== void 0 ? newFoldStatus : !isFold;
        if (realNewFoldStatus) {
            setExpandKeys([]);
        }
        else {
            const menuExpandKeys = selectKeyAndExpandParent(menu, selectedMenuKey);
            if (defaultExpandAll) {
                setExpandKeys(findMenuItemWithChildren(menu));
            }
            else if (Array.isArray(menuExpandKeys) && menuExpandKeys.length > 0) {
                setExpandKeys(menuExpandKeys);
            }
        }
        if (realNewFoldStatus) {
            (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
                name: 'CnShell左导航收起',
                eventType: 'CLK',
                hottag: 'cn-ui.cn-shell.sideBarCollapse',
            });
        }
        else {
            (_b = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _b === void 0 ? void 0 : _b.reportHottag({
                name: 'CnShell左导航展开',
                eventType: 'CLK',
                hottag: 'cn-ui.cn-shell.sideBarExpand',
            });
        }
        setFold(realNewFoldStatus);
    };
    // 点击侧边栏展开态
    const handleClickPin = () => {
        var _a;
        setPreview(false);
        setFold(false);
        (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
            name: 'CnShell左导航展开',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-shell.sideBarExpand',
        });
        const menuExpandKeys = selectKeyAndExpandParent(menu, selectedMenuKey);
        if (defaultExpandAll) {
            setExpandKeys(findMenuItemWithChildren(menu));
        }
        else if (Array.isArray(menuExpandKeys) && menuExpandKeys.length > 0) {
            setExpandKeys(menuExpandKeys);
        }
    };
    /**
     * 点击菜单
     * @param menu 单个菜单数据
     */
    const handleClickMenuItem = (menu) => {
        var _a;
        toggleMenuVisibility(menu);
        // 菜单受控
        if (!((_a = menu.children) === null || _a === void 0 ? void 0 : _a.length)) {
            onSelectMenuItem && onSelectMenuItem(menu);
        }
    };
    /**
     * 计算菜单下拉框的高度，用于动画
     * @param menu {MenuItem} 单个菜单
     * @returns number
     */
    const calculateMenuHeight = (baseHeight, menuItem) => {
        const recursive = (item, currentBaseHeight) => {
            if (item &&
                Array.isArray(item.children) &&
                expandKeys.includes(item.key) &&
                item.children.length > 0) {
                return item.children.reduce((prevHeight, subItem) => {
                    return prevHeight + 8 + recursive(subItem, 32);
                }, currentBaseHeight);
            }
            return 32;
        };
        return recursive(menuItem, baseHeight);
    };
    /**
     * 渲染菜单 icon
     * @param icon {string} 菜单内容
     * @returns ReactNode
     */
    const renderMenuIcon = (icon) => {
        return React.createElement(CnIcon, { type: icon || 'case-fill', className: "shell-menu-text-icon", size: "large" });
    };
    /**
     * 渲染单个菜单子项内容
     * @param menu {MenuItem} 单个菜单
     * @param level {number} 当前层级
     * @returns React.ReactNode
     */
    const renderMenuItem = (menuItem, level) => {
        var _a, _b;
        let baseHeight;
        let menuItemHeight = 0;
        if (level === 1) {
            baseHeight = 36;
        }
        else {
            baseHeight = 32;
        }
        if (expandKeys.includes(menuItem.key)) {
            menuItemHeight = calculateMenuHeight(baseHeight, menuItem);
        }
        else {
            menuItemHeight = baseHeight;
        }
        if (level >= 3) {
            console.warn('[cn-ui] 设计规范上侧边菜单不支持第四级菜单（总第五级菜单）');
        }
        let itemText = menuItem.text;
        if (isHideMenuItemIconFold) {
            itemText = /^[A-Za-z0-9]+$/.test(itemText.substr(0, 3)) ? itemText.substr(0, 3) : itemText.substr(0, 2);
            itemText = menuItem.abbrText ? menuItem.abbrText : itemText;
        }
        const rightIcon = isHideMenuItemIconFold ? null :
            (React.createElement(React.Fragment, null,
                !!((_a = menuItem.children) === null || _a === void 0 ? void 0 : _a.length) && level < 3 && (React.createElement(CnIcon, { type: "arrow-right", className: cn({ actived: expandKeys.includes(menuItem.key) }, 'sidebar-triangle'), size: "small" })),
                !((_b = menuItem.children) === null || _b === void 0 ? void 0 : _b.length) &&
                    leafItemIconRender &&
                    leafItemIconRender(menuItem)));
        return (React.createElement(motion.li, { animate: {
                height: menuItemHeight,
            }, transition: { ease: 'easeInOut' }, initial: false, key: menuItem.key },
            React.createElement("a", { onClick: (e) => {
                    e.preventDefault();
                    handleClickMenuItem(menuItem);
                }, href: menuItem.path, className: cn({
                    'cn-ui-shell-menu-item': true,
                    'hide-menu-item-icon-fold': isHideMenuItemIconFold,
                    // 是否为展开高亮
                    highlight: isKeyParent(menu, selectedMenuKey, menuItem.key),
                    // 是否为选中高亮
                    actived: selectedMenuKey === menuItem.key ||
                        // 当菜单收起时只显示一级菜单icon，如果这个时候有子菜单被选中的话，需要激活当前一级菜单
                        (isFold &&
                            !isPreview &&
                            level === 1 &&
                            isKeyParent(menu, selectedMenuKey, menuItem.key)),
                }) },
                React.createElement("div", { className: "menu-sidebar-line", style: {
                        top: baseHeight + 8,
                        height: menuItemHeight - baseHeight - 8,
                    } }),
                !hideMenuItemIcon && level === 1 && renderMenuIcon(menuItem.icon),
                React.createElement("span", { className: "menu-text", title: menuItem.text }, itemText),
                rightIcon),
            level < 3 && renderSubMenu(menuItem.children || [], level + 1)));
    };
    /**
     * 渲染菜单及子菜单
     * @param subMenu {Menu}
     * @param level number
     * @returns React.ReactNode
     */
    const renderSubMenu = (subMenu, level) => {
        return (subMenu === null || subMenu === void 0 ? void 0 : subMenu.length)
            ? subMenu.map((menuItem) => (React.createElement("ul", { key: menuItem.key, className: `level-${level}` }, renderMenuItem(menuItem, level))))
            : null;
    };
    return (React.createElement(motion.aside, { className: cn('cn-ui-shell-sidebar', {
            fold: isFold,
            preview: isPreview,
        }), initial: false, animate: {
            width: !isFold || isPreview ? SIDEBAR_WIDTH.UNFOLD : SIDEBAR_WIDTH.FOLD,
        }, onMouseEnter: handleExpandAside, onMouseLeave: handleFoldAside },
        React.createElement(CnScrollBar, { className: "menu-wrapper" },
            React.createElement("ul", { className: "level-1" }, menu.map((item) => renderMenuItem(item, 1)))),
        React.createElement("div", { className: "folder", onMouseEnter: (ev) => ev.stopPropagation(), onClick: () => {
                onCollapseClick && onCollapseClick(isFold, isPreview);
                if (isPreview) {
                    handleClickPin();
                }
                else {
                    handleClickFoldBtn();
                }
            } },
            React.createElement(CnIcon, { type: "collapse-right", size: "medium", className: "folder-icon" }))));
};
