import $i18n from 'panda-i18n';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { getRequestService } from '@cainiaofe/cn-ui-utils';
import { CnDraggerUpload, CnMiniUpload, CnUpload, } from '@/components/cn-upload';
import useLatest from 'ahooks/lib/useLatest';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import { CnMessage } from '@/components/cn-message';
import isObject from 'lodash/isObject';
import { defaultOssUploadService } from './constant';
import { fileValidator, getUploadData } from './utils';
export const CnOSSUploadView = (props) => {
    var _a;
    /**
     * 兼容uploadProps属性，未来会被废弃，直接使用props
     * @deprecated
     */
    const mergeProps = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { uploadProps = {}, ...restProps } = props;
        return { ...uploadProps, ...restProps };
    }, [props]);
    // 是否是受控模式(value由外部控制)
    const isControlled = 'value' in props;
    const { shape, onError: _onError, beforeUpload: _beforeUpload, ...restProps } = mergeProps;
    const { value: originValue, defaultValue, onChange: _onChange, useDetailValue, ossUploadConfig, } = props;
    const requestConfigRef = useLatest(props.requestConfig);
    const serviceRef = useLatest(mergeProps.service || defaultOssUploadService);
    const onChangeRef = useLatest(_onChange);
    const ossDataMapRef = useRef({});
    const [currentFileUid, setCurrentFileUid] = useState();
    const [value, setValue] = useState(originValue || defaultValue || []);
    const requestConfig = useMemo(() => {
        const service = serviceRef.current;
        if (requestConfigRef.current)
            return requestConfigRef.current;
        if (typeof service === 'function') {
            return { service };
        }
        return service;
    }, [requestConfigRef, serviceRef]);
    const requestService = useMemo(() => getRequestService(requestConfig), [requestConfig]);
    useUpdateEffect(() => {
        if (!isControlled)
            return;
        setValue((prev) => {
            if (!originValue || !Array.isArray(originValue))
                return [];
            const _value = originValue === null || originValue === void 0 ? void 0 : originValue.map((item) => {
                const prevItem = prev.find(({ key }) => key === item.key);
                return { ...prevItem, ...item };
            });
            return _value || [];
        });
    }, [originValue]);
    const onError = useCallback((error, res, file) => {
        if (_onError)
            _onError(error, res, file);
        CnMessage.error({
            title: '文件上传失败',
            content: (error === null || error === void 0 ? void 0 : error.message) || null,
        });
    }, [_onError]);
    const onChange = useCallback((_value, _file) => {
        if (onChangeRef.current) {
            if (useDetailValue) {
                onChangeRef.current(_value, _file);
            }
            else {
                const _simpleValue = _value.map((item) => {
                    const temp = {
                        key: item.key,
                        name: item.name,
                        size: item.size,
                        url: item.url,
                    };
                    if (!temp.key && item.uid && ossDataMapRef.current[item.uid]) {
                        temp.key = ossDataMapRef.current[item.uid].key;
                    }
                    return temp;
                });
                onChangeRef.current(_simpleValue);
            }
        }
        if (!isControlled) {
            setValue(_value);
        }
    }, [isControlled, useDetailValue, onChangeRef]);
    /** 除requestConfig里面的逻辑不下线，其它的逻辑后期会下线 */
    const fetchOssData = async (_file) => {
        try {
            return await requestService({ fileName: _file.name });
        }
        catch (err) {
            err.message = $i18n.get({
                id: 'OSSCredentialAcquisitionFailed',
                dm: 'OSS凭证获取失败',
                ns: 'CnOssUpload',
            });
            throw err;
        }
    };
    const beforeUpload = async (_file, fileList) => {
        try {
            let tempFile = _file;
            if (_beforeUpload) {
                const result = _beforeUpload(_file, fileList);
                if (result === false)
                    throw new Error();
                if (isObject(result) && result.then) {
                    const temp = await result;
                    if (temp)
                        tempFile = temp;
                }
            }
            fileValidator(tempFile);
            const data = await fetchOssData(tempFile);
            ossDataMapRef.current[_file.uid] = data;
            setCurrentFileUid(tempFile.uid);
            return tempFile;
        }
        catch (error) {
            onError(error, null, _file);
            throw error;
        }
    };
    /** 文件上传结束后会调用该函数 */
    const formatter = useCallback((_, _file) => {
        if (!(_file === null || _file === void 0 ? void 0 : _file.uid))
            return undefined;
        const temp = ossDataMapRef.current[_file.uid];
        // eslint-disable-next-line no-param-reassign
        _file.key = temp.key;
        delete ossDataMapRef.current[_file.uid];
        return temp;
    }, []);
    const newProps = {
        ...restProps,
        withCredentials: ossUploadConfig === null || ossUploadConfig === void 0 ? void 0 : ossUploadConfig.withCredentials,
        onError,
        data: getUploadData(currentFileUid ? ossDataMapRef.current[currentFileUid] : undefined),
        action: currentFileUid
            ? (_a = ossDataMapRef.current[currentFileUid]) === null || _a === void 0 ? void 0 : _a.host
            : undefined,
        beforeUpload,
        formatter,
        value,
        onChange,
    };
    switch (shape) {
        case 'mini':
            return React.createElement(CnMiniUpload, { ...newProps });
        case 'dragger':
            return React.createElement(CnDraggerUpload, { ...newProps });
        default:
            return React.createElement(CnUpload, { ...newProps });
    }
};
CnOSSUploadView.defaultProps = {
    limit: 999,
    readOnly: false,
    useDetailValue: true,
    showPreview: true,
    showRemove: true,
    showDownload: true,
    showUploadList: true,
};
