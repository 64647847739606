import * as React from 'react';
import $i18n from 'panda-i18n';
import cx from 'classnames';
import { Button } from '@/components/fusion';
import { downloadFile, unpackRequest } from '@/components/cn-utils';
import { CnMessage } from '@/components/cn-message';
export const CnDownload = (props) => {
    const { style, className, service, buttonText = $i18n.get({ id: 'Download', dm: '下载', ns: 'CnDownload' }), buttonProps, children, customRender, } = props;
    // 通过service拿到文件下载链接
    const fetchFileUrl = async () => {
        if (typeof service === 'function')
            return service();
        if (service)
            return unpackRequest(service);
        return undefined;
    };
    const onClick = async () => {
        const { onError, onSuccess, url, fileName } = props;
        try {
            const fileUrl = url || (await fetchFileUrl());
            if (!fileUrl) {
                throw new Error($i18n.get({
                    id: 'DownloadLinkDoesNotExist',
                    dm: '下载链接不存在',
                    ns: 'CnDownload',
                }));
            }
            downloadFile(fileUrl, fileName);
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(fileUrl);
        }
        catch (err) {
            if (onError) {
                onError(err);
            }
            else if (err.message) {
                CnMessage.error(err.message);
            }
        }
    };
    if (customRender)
        return customRender(onClick);
    if (children) {
        return (React.createElement("span", { style: style, className: cx('cn-ui-download-wrapper', className), onClick: onClick }, children));
    }
    return (React.createElement(Button, { "data-name": "CnDownload", ...buttonProps, onClick: onClick }, buttonText));
};
