/* eslint-disable import/no-cycle */
import cx from 'classnames';
import React from 'react';
import { safeGetRenderKey, safeGetValue } from '../../../view/utils';
import { Colgroup } from './colgroup';
import SpanManager from './helpers/SpanManager';
import { Classes } from './styles';
import { ErrorBoundary } from './errorBoundary';
export function HtmlTable({ tbodyHtmlTag, getRowProps, primaryKey, data, verticalRenderInfo: verInfo, horizontalRenderInfo: hozInfo, components: { Row, Cell, TableBody }, }) {
    const { flat, horizontalRenderRange: hoz } = hozInfo;
    const spanManager = new SpanManager();
    const fullFlatCount = flat.full.length;
    const leftFlatCount = flat.left.length;
    const rightFlatCount = flat.right.length;
    const tbody = TableBody != null && tbodyHtmlTag === 'tbody' ? (React.createElement(TableBody, { tbodyProps: { children: data.map(renderRow) } })) : (React.createElement(tbodyHtmlTag, null, data.map(renderRow)));
    return (React.createElement("table", null,
        React.createElement(Colgroup, { descriptors: hozInfo.visible }),
        tbody));
    function renderRow(row, i) {
        const rowIndex = verInfo.offset + i;
        spanManager.stripUpwards(rowIndex);
        const rowProps = getRowProps(row, rowIndex);
        const rowClass = cx(Classes.tableRow, {
            first: rowIndex === verInfo.first,
            last: rowIndex === verInfo.last,
            even: rowIndex % 2 === 0,
            odd: rowIndex % 2 === 1,
        }, rowProps === null || rowProps === void 0 ? void 0 : rowProps.className);
        const trProps = {
            ...rowProps,
            className: rowClass,
            'data-rowindex': rowIndex,
            children: hozInfo.visible.map((descriptor) => {
                if (descriptor.type === 'blank') {
                    return React.createElement("td", { key: descriptor.blankSide });
                }
                // 渲染单元格
                return renderBodyCell(row, rowIndex, descriptor.col, descriptor.colIndex);
            }),
        };
        const key = safeGetRenderKey(primaryKey, row, rowIndex);
        if (Row != null && tbodyHtmlTag === 'tbody') {
            return React.createElement(Row, { key, row, rowIndex, trProps });
        }
        else {
            return React.createElement("tr", { key: key, ...trProps });
        }
    }
    function renderBodyCell(row, rowIndex, column, colIndex) {
        var _a, _b;
        if (spanManager.testSkip(rowIndex, colIndex)) {
            return null;
        }
        const value = safeGetValue(column, row, rowIndex);
        const cellProps = (_b = (_a = column.getCellProps) === null || _a === void 0 ? void 0 : _a.call(column, value, row, rowIndex)) !== null && _b !== void 0 ? _b : {};
        let cellContent = value;
        if (column.render) {
            cellContent = column.render(value, row, rowIndex);
        }
        let colSpan = 1;
        let rowSpan = 1;
        if (column.getSpanRect) {
            const spanRect = column.getSpanRect(value, row, rowIndex);
            colSpan = spanRect == null ? 1 : spanRect.right - colIndex;
            rowSpan = spanRect == null ? 1 : spanRect.bottom - rowIndex;
        }
        else {
            if (cellProps.colSpan != null) {
                colSpan = cellProps.colSpan;
            }
            if (cellProps.rowSpan != null) {
                rowSpan = cellProps.rowSpan;
            }
        }
        // rowSpan/colSpan 不能过大，避免 rowSpan/colSpan 影响因虚拟滚动而未渲染的单元格
        rowSpan = Math.min(rowSpan, verInfo.limit - rowIndex);
        colSpan = Math.min(colSpan, leftFlatCount + hoz.rightIndex - colIndex);
        const hasSpan = colSpan > 1 || rowSpan > 1;
        if (hasSpan) {
            spanManager.add(rowIndex, colIndex, colSpan, rowSpan);
        }
        const positionStyle = {};
        if (colIndex < leftFlatCount) {
            positionStyle.position = 'sticky';
            positionStyle.left = hozInfo.stickyLeftMap.get(colIndex);
        }
        else if (colIndex >= fullFlatCount - rightFlatCount) {
            positionStyle.position = 'sticky';
            positionStyle.right = hozInfo.stickyRightMap.get(colIndex);
        }
        const tdProps = {
            ...cellProps,
            className: cx(Classes.tableCell, cellProps.className, {
                first: colIndex === 0,
                last: colIndex + colSpan === fullFlatCount,
                'lock-left': colIndex < leftFlatCount,
                'lock-right': colIndex >= fullFlatCount - rightFlatCount,
            }),
            ...(hasSpan ? { colSpan, rowSpan } : null),
            style: {
                textAlign: column.align,
                ...cellProps.style,
                ...positionStyle,
            },
            children: cellContent,
        };
        if (Cell != null && tbodyHtmlTag === 'tbody') {
            return (React.createElement(ErrorBoundary, { key: colIndex },
                React.createElement(Cell, { key: colIndex, tdProps: tdProps, row: row, rowIndex: rowIndex, column: column, colIndex: colIndex })));
        }
        else {
            return (React.createElement(ErrorBoundary, { key: colIndex },
                React.createElement("td", { key: colIndex, ...tdProps })));
        }
    }
}
