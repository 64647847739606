import { useI18n } from 'panda-i18n';
import React, { useCallback, useState } from 'react';
import { Tag } from '@/components/fusion';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import { CnIcon } from '@/components/cn-icon';
import { CnBalloon } from '@/components/cn-balloon';
import { CnCardSubCard } from './sub-card';
import { CnLoading } from '@/components/cn-loading';
import { useSizeChange } from '@/components/cn-utils';
import './cn-card.scss';
export function CnCard(props) {
    const { className, icon, title, titleTips, titleTag, children, desc, action, foldable, initFold, style, loading, onFoldChange, flexDirection, } = props;
    const $i18n = useI18n();
    const [fold, setFold] = useState(initFold);
    const { size, setSize } = useSizeChange(props);
    const toggleFold = useCallback(() => {
        if (isFunction(onFoldChange)) {
            onFoldChange(!fold);
        }
        setFold(!fold);
    }, [fold]);
    return (React.createElement("div", { "data-name": "CnCard", className: classNames([
            'cn-ui-card',
            className,
            `cn-ui-card-${size}`,
            fold ? 'cn-ui-card-fold' : 'cn-ui-card-unfold',
            !!title ? 'has-title' : 'no-title',
        ]), style: style },
        title && (React.createElement("div", { className: "cn-ui-card-header" },
            React.createElement("div", { className: "cn-ui-card-header-icon-wrapper" },
                React.createElement(CnIcon, { className: "cn-ui-card-header-icon", type: icon || 'service-center' })),
            React.createElement("div", { className: "cn-ui-card-header-title-container" },
                React.createElement("div", { className: "cn-ui-card-header-title" }, title),
                titleTips && (React.createElement("div", { className: "cn-ui-card-header-tips" },
                    React.createElement(CnBalloon, { trigger: React.createElement(CnIcon, { className: "cn-ui-card-tips-icon", type: "info-r-fill" }), triggerType: "hover", align: "t", title: titleTips }))),
                titleTag && (React.createElement(Tag, { className: "cn-ui-card-header-tag", ...titleTag }))),
            React.createElement("div", { className: "cn-ui-card-header-action" },
                action,
                foldable && (React.createElement("div", { className: "cn-ui-card-header-fold", onClick: toggleFold },
                    fold
                        ? $i18n.get({ id: 'Expand', dm: '展开' })
                        : $i18n.get({ id: 'PutItAway', dm: '收起' }),
                    React.createElement(CnIcon, { type: !fold ? 'triangle-up-fill' : 'triangle-down-fill' })))))),
        React.createElement("div", { className: classNames({
                'cn-ui-card-content': true,
                'cn-ui-card-content-flexDirection': flexDirection,
            }), style: { display: fold ? 'none' : 'block' } },
            desc && React.createElement("div", { className: "cn-ui-card-desc" }, desc),
            children && (React.createElement("div", { className: classNames({
                    'cn-ui-card-container': true,
                    'cn-ui-card-container-flexDirection': flexDirection,
                }) }, isNil(loading) ? (children) : (React.createElement(CnLoading, { visible: loading, style: { display: 'block' }, size: "medium" }, children)))))));
}
CnCard.defaultProps = {
    foldable: false,
    initFold: false,
};
// header, subtitle组件暴露出来供单独使用
CnCard.SubTitle = CnCardSubCard;
