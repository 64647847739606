import $i18n from 'panda-i18n';
import React, { useCallback, useEffect } from 'react';
import useJobPolling from '@/components/cn-async-jobs-pro/hooks/use-job-polling';
import { CnButton } from '@/components/cn-button';
import { CnDialog } from '@/components/cn-dialog';
import { CnResult } from '@/components/cn-result';
import { downloadFile } from '@/components/cn-utils';
import { Box, Progress } from '../fusion';
import { getSomeStatusJobDesc } from './util';
import classNames from 'classnames';
const cssPrefix = 'cn-ui-sync-dialog';
export default function LoopDialog(props) {
    const { showBgProcessBtn, onRender, autoDownload, visible, setVisible, setJobId, ...restProp } = props;
    const { jobResult, cancel } = useJobPolling(restProp);
    const close = useCallback(() => {
        setVisible(false);
        cancel();
    }, [setVisible, cancel]);
    useEffect(() => {
        if (!visible || !jobResult)
            return;
        onRender();
    }, [jobResult, onRender, visible]);
    useEffect(() => {
        if (!jobResult)
            return;
        const { downloadUrl, status, jobName } = jobResult;
        if (status === 'SUCCEEDED' && autoDownload && downloadUrl) {
            downloadFile(downloadUrl, jobName);
        }
    }, [autoDownload, jobResult]);
    useEffect(() => {
        if (visible)
            return;
        cancel();
    }, [visible, cancel]);
    if (!jobResult)
        return null;
    const { jobName, downloadUrl, errorDownloadUrl, status, reason } = jobResult;
    const showFooter = showBgProcessBtn && status === 'EXECUTING';
    return (React.createElement(CnDialog, { className: classNames(cssPrefix, {
            [`${cssPrefix}-show-footer`]: showFooter,
        }), size: "small", title: $i18n.get({
            id: 'Import',
            dm: '导入',
            ns: 'CnOssImport',
        }), visible: visible, onCancel: close, onOk: close, okProps: { visible: false }, cancelProps: { visible: false }, afterClose: () => {
            setJobId(undefined);
        }, onClose: close, cache: false, footer: showFooter ? (React.createElement(CnButton, { onClick: () => close() }, $i18n.get({
            id: 'BackgroundProcessing',
            dm: '后台处理',
            ns: 'CnOssImport',
        }))) : (false) },
        status === 'EXECUTING' ? (React.createElement(Box, { className: `${cssPrefix}-job-process`, justify: "center", direction: "column", align: "center", spacing: 12 },
            React.createElement(Progress, { className: `${cssPrefix}-running`, progressive: true, percent: jobResult.progress, shape: "circle" }),
            React.createElement("div", { className: `${cssPrefix}-running-tip` }, $i18n.get({
                id: 'FileImport',
                dm: '文件导入中...',
                ns: 'CnOssImport',
            })))) : null,
        status === 'FAILED' ? (React.createElement(CnResult, { size: "mini", type: "error", title: $i18n.get({
                id: 'DataImportFailed',
                dm: '数据导入失败',
                ns: 'CnOssImport',
            }), subTitle: reason, extra: errorDownloadUrl ? (React.createElement(CnButton, { size: "small", onClick: () => downloadFile(errorDownloadUrl) }, $i18n.get({
                id: 'ViewFailureRecords',
                dm: '查看失败记录',
                ns: 'CnOssImport',
            }))) : null })) : null,
        status === 'SOME' ? (React.createElement(CnResult, { size: "mini", type: "warning", title: $i18n.get({
                id: 'SomeDataImportedSuccessfully',
                dm: '部分数据导入成功',
                ns: 'CnOssImport',
            }), subTitle: getSomeStatusJobDesc(jobResult), extra: React.createElement(React.Fragment, null,
                errorDownloadUrl ? (React.createElement(CnButton, { size: "small", onClick: () => downloadFile(errorDownloadUrl) }, $i18n.get({
                    id: 'ViewFailureRecords',
                    dm: '查看失败记录',
                    ns: 'CnOssImport',
                }))) : null,
                downloadUrl ? (React.createElement(CnButton, { size: "small", onClick: () => downloadFile(downloadUrl, jobName) }, $i18n.get({
                    id: 'ViewSuccessRecords',
                    dm: '查看成功记录',
                    ns: 'CnOssImport',
                }))) : null) })) : null,
        status === 'SUCCEEDED' ? (React.createElement(CnResult, { size: "mini", type: "success", title: $i18n.get({
                id: 'CongratulationsDataImportSuccessful',
                dm: '导入成功',
                ns: 'CnOssImport',
            }), extra: downloadUrl ? (React.createElement(CnButton, { size: "small", onClick: () => downloadFile(downloadUrl, jobName) }, $i18n.get({ id: 'Download', dm: '下载', ns: 'CnOssImport' }))) : null })) : null));
}
