import $i18n from 'panda-i18n'; /* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { Radio } from '@/components/fusion';
import { safeGetRowKey, always, isFunction } from '../utils';
import { rowSelectionSymbol } from '../global';
function getFirstDefinedValue(...values) {
    for (let i = 0; i < values.length; i++) {
        const v = values[i];
        if (v !== undefined) {
            return v;
        }
    }
}
export default function singleSelect(opts = {}) {
    return function singleSelectStep(pipeline) {
        var _a, _b;
        if (Radio == null) {
            throw new Error($i18n.get({
                id: 'BeforeUsingSingleSelectComponentsRadioNeedTo_2051382980',
                dm: '使用 singleSelect 之前需要通过 pipeline context 设置 components.Radio',
                ns: 'CnBaseTable',
            }));
        }
        const stateKey = 'singleSelect';
        const clickArea = (_a = opts.clickArea) !== null && _a !== void 0 ? _a : 'radio';
        const isDisabled = isFunction(opts.isDisabled)
            ? opts.isDisabled
            : always(false);
        const primaryKey = pipeline.ensurePrimaryKey('singleSelect');
        const value = getFirstDefinedValue(opts.value, pipeline.getStateAtKey(stateKey), opts.defaultValue);
        const onChange = (rowKey, records) => {
            var _a;
            (_a = opts.onChange) === null || _a === void 0 ? void 0 : _a.call(opts, rowKey, records);
            pipeline.setStateAtKey(stateKey, rowKey);
        };
        const radioColumn = {
            name: '',
            width: 50,
            align: 'center',
            lock: true,
            attach: false,
            sizeFixed: true,
            symbol: rowSelectionSymbol,
            ...opts.columnProps,
            getCellProps(value, row, rowIndex) {
                if (clickArea === 'cell') {
                    const rowKey = safeGetRowKey(primaryKey, row, rowIndex);
                    const disabled = isDisabled(row, rowIndex);
                    return {
                        style: { cursor: disabled ? 'not-allowed' : 'pointer' },
                        onClick: disabled
                            ? undefined
                            : (e) => {
                                if (opts.stopClickEventPropagation) {
                                    e.stopPropagation();
                                }
                                onChange(rowKey, row);
                            },
                    };
                }
                return {};
            },
            render: (_, row, rowIndex) => {
                const rowKey = safeGetRowKey(primaryKey, row, rowIndex);
                return (React.createElement(Radio, { checked: value === rowKey, disabled: isDisabled(row, rowIndex), onChange: clickArea === 'radio'
                        ? (arg1, arg2) => {
                            var _a;
                            const nativeEvent = 
                            // @ts-ignore
                            (_a = arg2 === null || arg2 === void 0 ? void 0 : arg2.nativeEvent) !== null && _a !== void 0 ? _a : arg1 === null || arg1 === void 0 ? void 0 : arg1.nativeEvent;
                            if (nativeEvent && opts.stopClickEventPropagation) {
                                nativeEvent.stopPropagation();
                            }
                            onChange(rowKey, row);
                        }
                        : undefined }));
            },
        };
        const nextColumns = pipeline.getColumns().slice();
        const placement = (_b = opts.placement) !== null && _b !== void 0 ? _b : 'start';
        if (placement === 'start') {
            nextColumns.unshift(radioColumn);
        }
        else {
            nextColumns.push(radioColumn);
        }
        pipeline.columns(nextColumns);
        pipeline.appendRowPropsGetter((row, rowIndex) => {
            const rowKey = safeGetRowKey(primaryKey, row, rowIndex);
            const style = {};
            let className = '';
            let onClick;
            if (opts.highlightRowWhenSelected) {
                if (value === rowKey) {
                    className = 'highlight';
                }
            }
            if (clickArea === 'row' && !isDisabled(row, rowIndex)) {
                style.cursor = 'pointer';
                onClick = (e) => {
                    if (opts.stopClickEventPropagation) {
                        e.stopPropagation();
                    }
                    onChange(rowKey);
                };
            }
            return { className, style, onClick };
        });
        return pipeline;
    };
}
