export * from './card';
export function isFunction(val) {
    return val instanceof Function;
}
export function isPlainObject(o) {
    return Object.prototype.toString.call(o) === '[object Object]';
}
export function isString(o) {
    return Object.prototype.toString.call(o) === '[object String]';
}
export function isNumber(o) {
    return Object.prototype.toString.call(o) === '[object Number]';
}
export function getValueByPath(source, path, defaultValue) {
    return path.reduce((ret, nextKey) => { var _a; return (_a = ret === null || ret === void 0 ? void 0 : ret[nextKey]) !== null && _a !== void 0 ? _a : defaultValue; }, source);
}
export const isDef = (val) => {
    return val !== undefined && val !== null;
};
export const noop = (val) => val;
export const getTransform = function (source, defaultFunc = noop) {
    return isFunction(source) ? source : defaultFunc;
};
export const getArrayMap = function (source, defaultFunc = noop) {
    return Array.isArray(source)
        ? // @ts-ignore
            source.map((item, index, arr) => defaultFunc(item, index, arr))
        : [];
};
export const getArrayReduce = function (source, defaultValue, defaultFunc = noop) {
    return Array.isArray(source)
        ? // @ts-ignore
            source.reduce(
            // @ts-ignore
            (ret, nextItem, index, arr) => defaultFunc(ret, nextItem, index, arr), defaultValue)
        : [];
};
export function isNumberStr(str) {
    return isString(str) && !isNaN(parseFloat(str));
}
