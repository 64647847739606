import { footerSymbol } from '../global';
import { isDef, noop } from '../utils';
export function getFooterDataSource(pipeline, opts) {
    if (!opts) {
        return [];
    }
    const primaryKey = pipeline.ensurePrimaryKey('footerRule');
    const columns = pipeline.getColumns();
    const footerData = {
        key: footerSymbol,
        [primaryKey]: Date.now(),
    };
    const dataSource = pipeline.getDataSource();
    dataSource.forEach((item) => {
        columns.forEach((col) => {
            const key = col === null || col === void 0 ? void 0 : col.code;
            if (key && opts[key] instanceof Function) {
                footerData[key] = opts[key](footerData[key], item[key], item);
            }
            else if (!footerData[key]) {
                footerData[key] = '-';
            }
        });
    });
    return [footerData];
}
export default function footerPipeline(opts) {
    return function (pipeline) {
        const columns = pipeline.getColumns();
        const dataSource = pipeline.getDataSource();
        const primaryKey = pipeline.ensurePrimaryKey('footerRule');
        columns.forEach((col) => {
            if (col) {
                const sourceRender = col.render || noop;
                col.render = (val, record, index) => {
                    return (record === null || record === void 0 ? void 0 : record.key) === footerSymbol
                        ? col.code && isDef(val)
                            ? val
                            : '-'
                        : sourceRender(val, record, index);
                };
            }
        });
        const footerData = {
            key: footerSymbol,
            [primaryKey]: Date.now(),
        };
        if (opts) {
            dataSource.forEach((item) => {
                Object.keys(opts).forEach((key) => {
                    if (key && opts[key] instanceof Function) {
                        footerData[key] = opts[key](footerData[key], item[key], item);
                    }
                });
            });
        }
        dataSource.push(footerData);
        return pipeline.dataSource(dataSource).columns(columns);
    };
}
