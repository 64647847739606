import React from 'react';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import User from './user';
import { HeaderMenu } from './header-menu';
import { FullHeaderMenu } from './full-header-menu';
import DynamicSlot from './dynamic-slot';
import { LaunchpadMenu, } from './launchpad-menu';
import './index.scss';
export const Header = ({ logoStyle, onLogoClick, logoSrc, launchpadMenu, onLaunchpadMenuClick, onLaunchpadMenuDelete, launchpadPopupClassName, launchpadSlot, launchpadSelectedKey, onLaunchpadSelect, defaultLaunchpadSelectedKey, menu, menuType, menuOnSelect, hideExternalIcon, onExternalSelect, menuSelectedKey, searchSlot, widgetSlot, userSlot, user, triggerType, fullMenuItemIconRender, }) => {
    return (React.createElement("header", { className: "cn-ui-shell-header" },
        React.createElement("img", { className: "logo", src: logoSrc, style: logoStyle, onClick: onLogoClick, alt: "logo" }),
        React.createElement(LaunchpadMenu, { dataSource: launchpadMenu, onClick: onLaunchpadMenuClick, onDelete: onLaunchpadMenuDelete, popupClassName: launchpadPopupClassName, ...pickBy({
                selectedKeys: launchpadSelectedKey,
                onSelect: onLaunchpadSelect,
                defaultSelectedKeys: defaultLaunchpadSelectedKey,
            }, p => !isNil(p)) }),
        launchpadSlot && React.createElement("div", { className: "launchpad-slot" }, launchpadSlot),
        React.createElement("div", { className: "header-menu" },
            Array.isArray(menu) && menu.length > 0 && menuType === 'single' && (React.createElement(HeaderMenu, { menu: menu, onSelect: menuOnSelect, selectedKeys: menuSelectedKey, triggerType: triggerType })),
            Array.isArray(menu) && menu.length > 0 && menuType === 'full' && (React.createElement(FullHeaderMenu, { menu: menu, onSelect: menuOnSelect, selectedKeys: menuSelectedKey, hideExternalIcon: hideExternalIcon, onExternalSelect: onExternalSelect, fullMenuItemIconRender: fullMenuItemIconRender }))),
        React.createElement(DynamicSlot, null),
        searchSlot && React.createElement("div", { className: "slot" }, searchSlot),
        widgetSlot && React.createElement("div", { className: "slot" }, widgetSlot),
        React.createElement(User, { userSlot: userSlot, user: user })));
};
Header.defaultProps = {
    triggerType: 'hover',
    menuType: 'single',
};
