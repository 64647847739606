import * as React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { CnStatusTag } from '@/components/cn-tag';
import './index.scss';
const renderDescription = (description) => {
    const result = [];
    if (Array.isArray(description)) {
        description.forEach((descriptionItem, index) => {
            if (index === description.length - 1) {
                result.push(React.createElement("span", null, descriptionItem));
            }
            else {
                result.push(React.createElement("span", null, descriptionItem), React.createElement("span", null, "\u00A0|\u00A0"));
            }
        });
    }
    else {
        result.push(React.createElement("span", null, description));
    }
    return result;
};
export const CnDetailInfo = (props) => {
    const { icon, title, description, status, tagText, cnTagProps } = props;
    return (React.createElement("div", { className: "cn-ui-detail-info" },
        React.createElement("div", { className: "cn-ui-detail-info-header" },
            React.createElement(CnIcon, { type: icon, size: "xl" })),
        React.createElement("div", { className: "cn-ui-detail-info-footer" },
            React.createElement("div", { className: "cn-ui-detail-info-content" },
                React.createElement("div", { className: "cn-ui-detail-info-content-header" },
                    React.createElement("div", { className: "cn-ui-detail-info-title" }, title),
                    status && (React.createElement("div", { className: "cn-ui-detail-info-tag" },
                        React.createElement(CnStatusTag, { status: status, ...cnTagProps }, tagText)))),
                React.createElement("div", { className: "cn-ui-detail-info-content-footer" }, renderDescription(description))))));
};
