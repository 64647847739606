/* eslint-disable react/sort-comp */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable import/no-cycle */
import cx from 'classnames';
import React from 'react';
import { BehaviorSubject, combineLatest, noop, Subscription } from 'rxjs';
import { StyleSheetManager } from 'styled-components';
import * as op from 'rxjs/operators';
import { calculateRenderInfo } from './calculations';
import { EmptyHtmlTable } from './empty';
import TableHeader from './header';
import { getRichVisibleRectsStream } from './helpers/getRichVisibleRectsStream';
import { getFullRenderRange, makeRowHeightManager, } from './helpers/rowHeightManager';
import { TableDOMHelper } from './helpers/TableDOMUtils';
import { HtmlTable } from './html-table';
import Loading from './loading';
import { Classes, LOCK_SHADOW_PADDING, StyledArtTableWrapper, } from './styles';
import { getScrollbarSize, OVERSCAN_SIZE, shallowEqual, STYLED_REF_PROP, sum, syncScrollLeft, throttledWindowResize$, } from './utils';
export class BaseTable extends React.Component {
    constructor(props) {
        super(props);
        this.rowHeightManager = makeRowHeightManager(this.props.dataSource.length, this.props.estimatedRowHeight);
        this.artTableWrapperRef = React.createRef();
        this.rootSubscription = new Subscription();
        this.state = {
            hasScroll: true,
            needRenderLock: true,
            offsetY: 0,
            offsetX: 0,
            // 因为 ResizeObserver 在一开始总是会调用一次所提供的回调函数
            // 故这里为 maxRenderHeight/maxRenderWidth 设置一个默认值即可（因为这两个默认值很快就会被覆盖）
            // https://stackoverflow.com/questions/60026223/does-resizeobserver-invokes-initially-on-page-load
            maxRenderHeight: 600,
            maxRenderWidth: 800,
        };
    }
    getVerticalRenderRange(useVirtual) {
        const { dataSource } = this.props;
        const { offsetY, maxRenderHeight } = this.state;
        const rowCount = dataSource.length;
        if (useVirtual.vertical) {
            return this.rowHeightManager.getRenderRange(offsetY, maxRenderHeight, rowCount);
        }
        else {
            return getFullRenderRange(rowCount);
        }
    }
    componentDidMount() {
        this.updateDOMHelper();
        this.props$ = new BehaviorSubject(this.props);
        this.initSubscriptions();
        this.didMountOrUpdate();
    }
    componentDidUpdate(prevProps, prevState) {
        this.updateDOMHelper();
        this.props$.next(this.props);
        this.didMountOrUpdate(prevProps, prevState);
    }
    componentWillUnmount() {
        this.rootSubscription.unsubscribe();
    }
    updateOffsetX(nextOffsetX) {
        if (this.lastInfo.useVirtual.horizontal) {
            // 更新的频率控制
            if (Math.abs(nextOffsetX - this.state.offsetX) >= OVERSCAN_SIZE / 2) {
                this.setState({ offsetX: nextOffsetX });
            }
        }
    }
    syncHorizontalScrollFromTableBody() {
        this.syncHorizontalScroll(this.domHelper.tableBody.scrollLeft);
    }
    renderTableHeader(info) {
        const { stickyTop, hasHeader } = this.props;
        return (React.createElement("div", { className: cx(Classes.tableHeader, 'no-scrollbar'), style: {
                top: stickyTop === 0 ? undefined : stickyTop,
                display: hasHeader ? undefined : 'none',
            } },
            React.createElement(TableHeader, { info: info })));
    }
    renderTableBody(info) {
        const { dataSource, getRowProps, primaryKey, isLoading, emptyCellHeight, footerDataSource, components, } = this.props;
        const tableBodyClassName = cx(Classes.tableBody, Classes.horizontalScrollContainer, {
            'no-scrollbar': (footerDataSource === null || footerDataSource === void 0 ? void 0 : footerDataSource.length) > 0,
        });
        // 空数据
        if (dataSource.length === 0) {
            const { components, emptyContent } = this.props;
            let EmptyContent = components === null || components === void 0 ? void 0 : components.EmptyContent;
            if (EmptyContent == null && emptyContent != null) {
                EmptyContent = (() => emptyContent);
            }
            return (React.createElement("div", { className: tableBodyClassName },
                React.createElement(EmptyHtmlTable, { descriptors: info.visible, isLoading: isLoading, EmptyContent: EmptyContent, emptyCellHeight: emptyCellHeight })));
        }
        const { topIndex, bottomBlank, topBlank, bottomIndex } = info.verticalRenderRange;
        return (React.createElement("div", { "data-scoll-element-type": "horizontal-table", className: tableBodyClassName },
            topBlank > 0 && (React.createElement("div", { key: "top-blank", className: cx(Classes.virtualBlank, 'top'), style: { height: topBlank } })),
            React.createElement(HtmlTable, { components: components, tbodyHtmlTag: "tbody", getRowProps: getRowProps, primaryKey: primaryKey, data: dataSource.slice(topIndex, bottomIndex), horizontalRenderInfo: info, verticalRenderInfo: {
                    first: 0,
                    offset: topIndex,
                    limit: bottomIndex,
                    last: dataSource.length - 1,
                } }),
            bottomBlank > 0 && (React.createElement("div", { key: "bottom-blank", className: cx(Classes.virtualBlank, 'bottom'), style: { height: bottomBlank } }))));
    }
    renderTableFooter(info) {
        const { footerDataSource = [], getRowProps, primaryKey, stickyBottom, components, } = this.props;
        return (React.createElement("div", { 
            // datdata-scoll-element-type="horizontal-table"a
            className: cx(Classes.tableFooter, Classes.horizontalScrollContainer), style: { bottom: stickyBottom === 0 ? undefined : stickyBottom } },
            React.createElement(HtmlTable, { components: components, tbodyHtmlTag: "tfoot", data: footerDataSource, horizontalRenderInfo: info, getRowProps: getRowProps, primaryKey: primaryKey, verticalRenderInfo: {
                    offset: 0,
                    first: 0,
                    last: footerDataSource.length - 1,
                    limit: Infinity,
                } })));
    }
    renderLockShadows(info) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: Classes.lockShadowMask, style: {
                    left: 0,
                    width: info.leftLockTotalWidth + LOCK_SHADOW_PADDING,
                } },
                React.createElement("div", { className: cx(Classes.lockShadow, Classes.leftLockShadow) })),
            React.createElement("div", { className: Classes.lockShadowMask, style: {
                    right: 0,
                    width: info.rightLockTotalWidth + LOCK_SHADOW_PADDING,
                } },
                React.createElement("div", { className: cx(Classes.lockShadow, Classes.rightLockShadow) }))));
    }
    renderStickyScroll(info) {
        const { hasStickyScroll, stickyBottom } = this.props;
        const { hasScroll } = this.state;
        return (React.createElement("div", { "data-scoll-element-type": "horizontal-table", className: cx(Classes.stickyScroll, Classes.horizontalScrollContainer), style: {
                display: hasStickyScroll && hasScroll ? 'block' : 'none',
                bottom: stickyBottom,
            } },
            React.createElement("div", { className: Classes.stickyScrollItem })));
    }
    render() {
        const info = calculateRenderInfo(this);
        this.lastInfo = info;
        const { dataSource, className, style, hasHeader, useOuterBorder, isStickyHeader = true, isStickyFooter, isLoading, footerDataSource, components, } = this.props;
        const artTableWrapperClassName = cx(Classes.artTableWrapper, {
            'use-outer-border': useOuterBorder,
            empty: dataSource.length === 0,
            lock: info.hasLockColumn,
            'has-header': hasHeader,
            'sticky-header': isStickyHeader,
            'has-footer': footerDataSource.length > 0,
            'sticky-footer': isStickyFooter,
        }, className);
        const artTableWrapperProps = {
            className: artTableWrapperClassName,
            style,
            'data-scoll-element-type': 'vertical-table',
            [STYLED_REF_PROP]: this.artTableWrapperRef,
        };
        return (React.createElement(StyleSheetManager, { disableCSSOMInjection: true },
            React.createElement(StyledArtTableWrapper, { ...artTableWrapperProps },
                React.createElement(Loading, { visible: isLoading, LoadingIcon: components.LoadingIcon, LoadingContentWrapper: components.LoadingContentWrapper },
                    React.createElement("div", { className: Classes.artTable },
                        this.renderTableHeader(info),
                        this.renderTableBody(info),
                        this.renderTableFooter(info),
                        this.renderLockShadows(info)),
                    this.renderStickyScroll(info)))));
    }
    /** 同步横向滚动偏移量 */
    syncHorizontalScroll(x) {
        this.updateOffsetX(x);
        const { tableBody } = this.domHelper;
        const { flat } = this.lastInfo;
        const leftLockShadow = this.domHelper.getLeftLockShadow();
        if (leftLockShadow) {
            const shouldShowLeftLockShadow = flat.left.length > 0 && this.state.needRenderLock && x > 0;
            // 是否现实shadow
            if (shouldShowLeftLockShadow) {
                leftLockShadow.classList.add('show-shadow');
            }
            else {
                leftLockShadow.classList.remove('show-shadow');
            }
        }
        const rightLockShadow = this.domHelper.getRightLockShadow();
        if (rightLockShadow) {
            const shouldShowRightLockShadow = flat.right.length > 0 &&
                this.state.needRenderLock &&
                x < tableBody.scrollWidth - tableBody.clientWidth;
            if (shouldShowRightLockShadow) {
                rightLockShadow.classList.add('show-shadow');
            }
            else {
                rightLockShadow.classList.remove('show-shadow');
            }
        }
    }
    /** 自定义滚动条宽度为table宽度，使滚动条滑块宽度相同 */
    updateStickyScroll() {
        const { stickyScroll, artTable, stickyScrollItem } = this.domHelper;
        if (!artTable) {
            return;
        }
        const tableBodyHtmlTable = this.domHelper.getTableBodyHtmlTable();
        const innerTableWidth = tableBodyHtmlTable.offsetWidth;
        const artTableWidth = artTable.offsetWidth;
        const stickyScrollHeightProp = this.props.stickyScrollHeight;
        const stickyScrollHeight = stickyScrollHeightProp === 'auto'
            ? getScrollbarSize().height
            : stickyScrollHeightProp;
        stickyScroll.style.marginTop = `-${stickyScrollHeight + 1}px`;
        if (artTableWidth >= innerTableWidth) {
            if (this.state.hasScroll) {
                this.setState({ hasScroll: false });
            }
        }
        else if (!this.state.hasScroll && stickyScrollHeight > 5) {
            // 考虑下mac下面隐藏滚动条的情况
            this.setState({ hasScroll: true });
        }
        // 设置子节点宽度
        stickyScrollItem.style.width = `${innerTableWidth}px`;
    }
    didMountOrUpdate(prevProps, prevState) {
        this.syncHorizontalScrollFromTableBody();
        this.updateStickyScroll();
        this.adjustNeedRenderLock();
        this.updateRowHeightManager();
        this.updateScrollLeftWhenLayoutChanged(prevProps, prevState);
    }
    updateScrollLeftWhenLayoutChanged(prevProps, prevState) {
        if (prevState != null) {
            if (!prevState.hasScroll && this.state.hasScroll) {
                this.domHelper.stickyScroll.scrollLeft = 0;
            }
        }
        if (prevProps != null) {
            const prevHasFooter = prevProps.footerDataSource.length > 0;
            const currentHasFooter = this.props.footerDataSource.length > 0;
            if (!prevHasFooter && currentHasFooter) {
                this.domHelper.tableFooter.scrollLeft =
                    this.domHelper.tableBody.scrollLeft;
            }
        }
    }
    initSubscriptions() {
        const { tableHeader, tableBody, tableFooter, stickyScroll } = this.domHelper;
        this.rootSubscription.add(
        // widnow.resize 处理逻辑
        throttledWindowResize$.subscribe(() => {
            this.updateStickyScroll();
            this.adjustNeedRenderLock();
        }));
        // 滚动同步
        this.rootSubscription.add(
        // 同步多个元素之间的 scrollLeft
        syncScrollLeft([tableHeader, tableBody, tableFooter, stickyScroll], (scrollLeft) => {
            this.syncHorizontalScroll(scrollLeft);
        }));
        // getRichVisibleRectsStream 找到滚动体，并计算 clipRect . top 、left、right、bottom
        const richVisibleRects$ = getRichVisibleRectsStream(this.domHelper.artTable, this.props$.pipe(op.skip(1), op.mapTo('structure-may-change')), this.props.virtualDebugLabel).pipe(op.shareReplay());
        // 每当可见部分发生变化的时候，调整 loading icon 的位置（如果 loading icon 存在的话）
        this.rootSubscription.add(combineLatest([
            richVisibleRects$.pipe(op.map((p) => p.clipRect), op.distinctUntilChanged(shallowEqual)),
            this.props$.pipe(op.startWith(null), op.pairwise(), op.filter(([prevProps, props]) => prevProps == null || (!prevProps.isLoading && props.isLoading))),
        ]).subscribe(([clipRect]) => {
            const loadingIndicator = this.domHelper.getLoadingIndicator();
            if (!loadingIndicator) {
                return;
            }
            const height = clipRect.bottom - clipRect.top;
            // fixme 这里的定位在有些特殊情况下可能会出错 see #132
            loadingIndicator.style.top = `${height / 2}px`;
            loadingIndicator.style.marginTop = `${height / 2}px`;
        }));
        // 每当可见部分发生变化的时候，如果开启了虚拟滚动，则重新触发 render
        this.rootSubscription.add(richVisibleRects$
            .pipe(op.filter(() => {
            const { horizontal, vertical } = this.lastInfo.useVirtual;
            return horizontal || vertical;
        }), op.map(({ clipRect, offsetY }) => ({
            // 计算渲染视图大小
            maxRenderHeight: clipRect.bottom - clipRect.top,
            maxRenderWidth: clipRect.right - clipRect.left,
            offsetY,
        })), op.distinctUntilChanged((x, y) => {
            // 因为 overscan 的存在，滚动较小的距离时不需要触发组件重渲染，这里做了一层控制
            return (Math.abs(x.maxRenderWidth - y.maxRenderWidth) <
                OVERSCAN_SIZE / 2 &&
                Math.abs(x.maxRenderHeight - y.maxRenderHeight) <
                    OVERSCAN_SIZE / 2 &&
                Math.abs(x.offsetY - y.offsetY) < OVERSCAN_SIZE / 2);
        }))
            .subscribe((sizeAndOffset) => {
            this.setState(sizeAndOffset);
        }));
    }
    /** 更新 DOM 节点的引用，方便其他方法直接操作 DOM */
    updateDOMHelper() {
        this.domHelper = new TableDOMHelper(this.artTableWrapperRef.current);
    }
    updateRowHeightManager() {
        var _a;
        const virtualTop = this.domHelper.getVirtualTop();
        const virtualTopHeight = (_a = virtualTop === null || virtualTop === void 0 ? void 0 : virtualTop.clientHeight) !== null && _a !== void 0 ? _a : 0;
        let zeroHeightRowCount = 0;
        let maxRowIndex = -1;
        let maxRowBottom = -1;
        for (const tr of this.domHelper.getTableRows()) {
            const rowIndex = Number(tr.dataset.rowindex);
            if (isNaN(rowIndex)) {
                continue;
            }
            maxRowIndex = Math.max(maxRowIndex, rowIndex);
            const offset = tr.offsetTop + virtualTopHeight;
            const size = tr.offsetHeight;
            if (size === 0) {
                zeroHeightRowCount += 1;
            }
            maxRowBottom = Math.max(maxRowBottom, offset + size);
            this.rowHeightManager.updateRow(rowIndex, offset, size);
        }
        // 当 estimatedRowHeight 过大时，可能出现「渲染行数过少，无法覆盖可视范围」的情况
        // 出现这种情况时，我们判断「下一次渲染能够渲染更多行」是否满足，满足的话就直接调用 forceUpdate
        // zeroHeightRowCount === 0 用于确保当前没有 display=none 的情况
        if (maxRowIndex !== -1 && zeroHeightRowCount === 0) {
            if (maxRowBottom < this.state.offsetY + this.state.maxRenderHeight) {
                const vertical = this.getVerticalRenderRange(this.lastInfo.useVirtual);
                if (vertical.bottomIndex - 1 > maxRowIndex) {
                    this.forceUpdate();
                }
            }
        }
    }
    /** 计算表格所有列的渲染宽度之和，判断表格是否需要渲染锁列 */
    adjustNeedRenderLock() {
        const { needRenderLock } = this.state;
        const { flat, hasLockColumn } = this.lastInfo;
        if (hasLockColumn) {
            const sumOfColWidth = sum(flat.full.map((col) => col.width));
            const nextNeedRenderLock = sumOfColWidth > this.domHelper.artTable.clientWidth;
            if (needRenderLock !== nextNeedRenderLock) {
                this.setState({ needRenderLock: nextNeedRenderLock });
            }
        }
        else if (needRenderLock) {
            this.setState({ needRenderLock: false });
        }
    }
}
BaseTable.defaultProps = {
    hasHeader: true,
    isStickyHeader: true,
    stickyTop: 0,
    footerDataSource: [],
    isStickyFooter: true,
    stickyBottom: 0,
    hasStickyScroll: true,
    stickyScrollHeight: 'auto',
    useVirtual: 'auto',
    estimatedRowHeight: 48,
    isLoading: false,
    components: {},
    getRowProps: noop,
    dataSource: [],
};
