import $i18n from 'panda-i18n';
import { useState } from 'react';
import useUpdate from 'ahooks/lib/useUpdate';
import { mergeCellProps } from '../utils';
/**
 * 表格数据处理流水线。TablePipeline 提供了表格数据处理过程中的一些上下方与工具方法，包括……
 *
 * 1. ctx：上下文环境对象，step（流水线上的一步）可以对 ctx 中的字段进行读写。
 * ctx 中部分字段名称有特定的含义（例如 primaryKey 表示行的主键），使用自定义的上下文信息时注意避开这些名称。
 *
 * 2. rowPropsGetters：getRowProps 回调队列，step 可以通过 pipeline.appendRowPropsGetter 向队列中追加回调函数，
 *   在调用 pipeline.props() 队列中的所有函数会组合形成最终的 getRowProps
 *
 * 3. 当前流水线的状态，包括 dataSource, columns, rowPropsGetters 三个部分
 *
 * 4. snapshots，调用 pipeline.snapshot(name) 可以记录当前的状态，后续可以通过 name 来读取保存的状态
 * */
export class TablePipeline {
    constructor({ state, setState, doUpdate, ctx, }) {
        this._snapshots = {};
        this._rowPropsGetters = [];
        this.ctx = {
            components: {},
            indents: TablePipeline.defaultIndents,
        };
        this.state = state;
        this.doUpdate = doUpdate;
        this.setState = setState;
        Object.assign(this.ctx, ctx);
    }
    appendRowPropsGetter(getter) {
        this._rowPropsGetters.push(getter);
        return this;
    }
    getDataSource(name) {
        if (name == null) {
            return this._dataSource;
        }
        else {
            return this._snapshots[name].dataSource;
        }
    }
    getColumns(name) {
        if (name == null) {
            return this._columns;
        }
        else {
            return this._snapshots[name].columns;
        }
    }
    getStateAtKey(stateKey, defaultValue) {
        var _a;
        return (_a = this.state[stateKey]) !== null && _a !== void 0 ? _a : defaultValue;
    }
    /** 将 stateKey 对应的状态设置为 partialState  */
    setStateAtKey(stateKey, partialState, extraInfo) {
        this.setState((prev) => ({ ...prev, [stateKey]: partialState }), stateKey, partialState, extraInfo);
    }
    /** 确保 primaryKey 已被设置，并返回 primaryKey  */
    ensurePrimaryKey(hint) {
        if (this.ctx.primaryKey == null) {
            throw new Error(hint
                ? $i18n.get({
                    id: 'PrimaryKeyMustBeSetBeforeUsing_1275473647',
                    dm: '使用 {hint} 之前必须先设置 primaryKey',
                    ns: 'CnBaseTable',
                }, { hint: hint })
                : $i18n.get({
                    id: 'PrimaryKeyMustBeSetFirst',
                    dm: '必须先设置 primaryKey',
                    ns: 'CnBaseTable',
                }));
        }
        return this.ctx.primaryKey;
    }
    /** 设置流水线的输入数据 */
    input(input) {
        if (this._dataSource != null || this._columns != null) {
            throw new Error($i18n.get({
                id: 'InputCannotBeCalledTwice',
                dm: 'input 不能调用两次',
                ns: 'CnBaseTable',
            }));
        }
        this._dataSource = input.dataSource;
        this._columns = input.columns;
        this.snapshot('input');
        return this;
    }
    update() {
        this.doUpdate instanceof Function && this.doUpdate();
    }
    /** 设置 dataSource */
    dataSource(rows) {
        this._dataSource = rows;
        return this;
    }
    /** 设置 columns */
    columns(cols) {
        this._columns = cols;
        return this;
    }
    /** 设置主键 */
    primaryKey(key) {
        this.ctx.primaryKey = key;
        return this;
    }
    // locale(val: Locale) {
    //   this.ctx.locale = val;
    //   return this;
    // }
    // getLocale() {
    //   return this.ctx.locale ?? {};
    // }
    /** 保存快照 */
    snapshot(name) {
        this._snapshots[name] = {
            dataSource: this._dataSource,
            columns: this._columns,
            rowPropsGetters: this._rowPropsGetters.slice(),
        };
        return this;
    }
    /** 使用 pipeline 功能拓展 */
    use(step) {
        return step(this);
    }
    /** 转换 dataSource */
    mapDataSource(mapper) {
        return this.dataSource(mapper(this.getDataSource()));
    }
    /** 转换 columns */
    mapColumns(mapper) {
        return this.columns(mapper(this.getColumns()));
    }
    /** 获取 BaseTable 的 props，结果中包含 dataSource/columns/primaryKey/getRowProps 四个字段 */
    getProps() {
        const result = {
            dataSource: this._dataSource,
            columns: this._columns,
        };
        if (this.ctx.primaryKey) {
            result.primaryKey = this.ctx.primaryKey;
        }
        if (this._rowPropsGetters.length > 0) {
            result.getRowProps = (row, rowIndex) => {
                return this._rowPropsGetters.reduce((res, get) => {
                    return mergeCellProps(res, get(row, rowIndex));
                }, {});
            };
        }
        return result;
    }
}
TablePipeline.defaultIndents = {
    iconIndent: -8,
    iconWidth: 16,
    iconGap: 0,
    indentSize: 16,
};
export function useTablePipeline(ctx) {
    const [state, setState] = useState({});
    const doUpdate = useUpdate();
    return new TablePipeline({ state, setState, doUpdate, ctx });
}
