/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import classnames from 'classnames';
import { isFunction } from './index';
import { isPlainObject } from './common';
export default function reOrderColumns(columns) {
    const left = [];
    const normal = [];
    const right = [];
    if (!columns)
        return [];
    columns.forEach((col) => {
        const { lock } = col;
        if (lock === true || lock === 'left') {
            left.push(col);
        }
        else if (lock === 'right') {
            right.push(col);
        }
        else {
            normal.push(col);
        }
    });
    return [...left, ...normal, ...right];
}
export function normalizeColumns(columns) {
    const ret = [];
    if (!columns)
        return ret;
    columns.forEach((col) => {
        var _a;
        if (!isPlainObject(col)) {
            return;
        }
        if (Array.isArray(col.children) && col.children.length > 0) {
            col.children = normalizeColumns(col.children);
        }
        if ((col.key || col.dataIndex) && col.code == null) {
            col.code = (_a = col.key) !== null && _a !== void 0 ? _a : col.dataIndex;
        }
        if ('sortable' in col) {
            col.features = {
                ...col.features,
                sortable: col.sortable,
            };
        }
        /**
         * 兼容fusion api
         */
        if ((col === null || col === void 0 ? void 0 : col.cell) instanceof Function) {
            const sourceRender = col.cell;
            col.render = (value, row, rowIndex) => sourceRender(value, rowIndex, row);
        }
        if ('bold' in col || 'ellipsis' in col) {
            const sourceCellProps = col.cellProps;
            col.cellProps = (...args) => {
                const customProps = isFunction(sourceCellProps)
                    ? sourceCellProps(...args) || {}
                    : {};
                return {
                    ...customProps,
                    className: classnames(customProps === null || customProps === void 0 ? void 0 : customProps.className, {
                        'cn-table-cell--bold': col.bold,
                        'cn-table-cell--ellipsis': col.ellipsis,
                    }),
                };
            };
        }
    });
    return columns;
}
export function flatMap(array, callback) {
    const result = [];
    array.forEach((value, index) => {
        result.push(...callback(value, index, array));
    });
    return result;
}
