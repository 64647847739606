import * as React from 'react';
import { Input as NextInput } from '@/components/fusion';
import pick from 'lodash/pick';
import { CnReadOnly } from '@/components/cn-read-only';
import { useDerivedValue } from '@/components/cn-utils';
const renderPreview = (value, readOnlyProps, props) => {
    // if (value === undefined) return null;
    return (React.createElement(CnReadOnly, { value: value, type: "string", ...{
            ...pick(props, [
                'addonBefore',
                'innerBefore',
                'addonTextBefore',
                'addonTextAfter',
                'innerAfter',
                'addonAfter',
            ]),
            ...readOnlyProps,
        } }));
};
function nextCompMap(Comp, ComponentName) {
    const Component = React.forwardRef((props, ref) => {
        const { readOnly, readOnlyProps, ...otherProps } = props;
        const [value, setValue, isControlled] = useDerivedValue(props, undefined);
        const onChange = (newValue, e) => {
            if (!isControlled) {
                setValue(newValue);
            }
            props.onChange && props.onChange(newValue, e);
        };
        if (readOnly) {
            return renderPreview(value, readOnlyProps, props);
        }
        return (React.createElement(Comp, { "data-name": "CnInput", ref: ref, ...otherProps, value: value, onChange: onChange }));
    });
    Component.displayName = ComponentName;
    return Component;
}
export const CnInput = nextCompMap(NextInput, 'CnInput');
export const CnInputTextArea = nextCompMap(NextInput.TextArea, 'CnInputTextArea');
export const CnInputGroup = NextInput.Group;
CnInputGroup.displayName = 'CnInputGroup';
