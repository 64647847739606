import $i18n from 'panda-i18n';
// 初始化项目的时候填入的变量, 请谨慎修改, 会影响各个地方的使用
export const componentName = 'CnFilter';
export const componentItemName = 'CnFilterItem';
export const libraryName = 'cn-filter';
export const responsiveSizeV = [0, 0, 582, 780, 978, 1176, 1374, 1572];
export const responsiveSizeH = [0, 580, 876, 1172, 1468, 1764];
export const defaultDragZone = () => [
    {
        key: 'default',
        title: () => $i18n.get({ id: 'FilterAdded', dm: '已添加查询项', ns: 'CnFilter' }),
        zoneStyle: {
            backgroundColor: '#EEF1F7',
        },
    },
    {
        key: 'more',
        title: () => $i18n.get({
            id: 'FilterItemNotAdded',
            dm: '未添加查询项',
            ns: 'CnFilter',
        }),
        zoneStyle: {
            backgroundColor: '#F8F8F8',
        },
    },
];
