import * as React from 'react';
import classnames from 'classnames';
import { PandaConfigProvider } from 'panda-i18n';
import { CnCard } from '@/components/cn-card';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { CnBreadcrumb } from '@/components/cn-breadcrumb';
import { useSizeChange } from '@/components/cn-utils';
export class CnPageBase extends React.Component {
    constructor() {
        super(...arguments);
        this.isChildrenHasCard = () => {
            var _a;
            const { children, noCard } = this.props;
            if (noCard) {
                return true;
            }
            let types = [];
            if (Array.isArray(children) && children.length > 0) {
                types = children.map((item) => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName; });
            }
            else {
                types = [((_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName) || ''];
            }
            return types.includes('CnCard');
        };
    }
    /**
     * title 区域
     * 可用于渲染标题 or 面包屑 or 自定义元素
     */
    renderTitle() {
        const { title, cnBreadcrumbDataSource, cnBreadcrumbProps, action } = this.props;
        if (!title && !cnBreadcrumbDataSource) {
            return null;
        }
        if (cnBreadcrumbDataSource) {
            return (React.createElement("div", { className: "cn-ui-page-title" },
                React.createElement(CnBreadcrumb, { dataSource: cnBreadcrumbDataSource, ...cnBreadcrumbProps }),
                React.createElement("div", null, action)));
        }
        return (React.createElement("div", { className: "cn-ui-page-title" },
            React.createElement("div", null, title),
            React.createElement("div", null, action)));
    }
    render() {
        const { hasCnFormFooterSubmit, title, cnBreadcrumbDataSource, className, contentFill, children, embeddable, noPadding, flexDirection, size, ...otherDivProps } = this.props;
        const cls = classnames('cn-ui-page', `cn-ui-page-${size}`, className);
        return (React.createElement(PandaConfigProvider, null,
            React.createElement("div", { className: cls, "data-name": "CnPage", ...otherDivProps },
                this.renderTitle(),
                React.createElement(CnScrollBar, { "data-scoll-element-type": "page", className: classnames({
                        'cn-ui-page-content-wrapper': true,
                        'cn-ui-page-content-wrapper-no-padding': !!noPadding,
                        'has-title': !!title || !!cnBreadcrumbDataSource,
                        'has-cn-form-footer-submit': !!hasCnFormFooterSubmit,
                    }) },
                    React.createElement("div", { id: "cn-ui-page-scroll-container", className: classnames({
                            'cn-ui-page-scroll-container-flexDirection': flexDirection,
                        }) }, this.isChildrenHasCard() ? (children) : (React.createElement(CnCard, { flexDirection: flexDirection, className: classnames({
                            'cn-ui-page-embeddable': !!embeddable,
                            'cn-ui-page-content-fill': contentFill,
                        }) }, children)))))));
    }
}
CnPageBase.displayName = 'CnPage';
CnPageBase.defaultProps = {
    embeddable: false,
    noPadding: false,
    noCard: false,
    contentFill: true,
    hasCnFormFooterSubmit: false,
};
export const CnPage = React.forwardRef((props, ref) => {
    const { size, setSize } = useSizeChange(props);
    return (React.createElement(CnPageBase, { ...props, size: size, sizeChange: setSize, ref: ref }));
});
