import $i18n from 'panda-i18n';
import React, { useContext } from 'react';
import { ColumnItem } from '../column-item';
import { leftFixMaxNum } from '@/components/cn-table/const';
import { ColumnsStateCtx } from '../columns-setting/context';
export default function ColumnsFixLeft() {
    const { isDialog, fixLeftColumns } = useContext(ColumnsStateCtx);
    return (React.createElement(React.Fragment, null,
        isDialog || fixLeftColumns.length ? (React.createElement("div", { className: "cn-table-column-setting-wrapper-title cn-table-column-setting-wrapper-title-left" }, $i18n.get({
            id: 'FixedOnTheLeftDatasourcelethleftFixMaxNum',
            dm: '固定在左侧({dataSourceLength}/{leftFixMaxNum})',
            ns: 'CnTable',
        }, {
            dataSourceLength: fixLeftColumns.length,
            leftFixMaxNum,
        }))) : null,
        fixLeftColumns.length ? (React.createElement("div", { className: "cn-table-column-setting" }, fixLeftColumns.map((dataItem, index) => (React.createElement(ColumnItem, { key: dataItem.dataIndex, dataItem: dataItem, index: index, position: "left" }))))) : null));
}
