import * as React from 'react';
import classNames from 'classnames';
import './sub-card.scss';
export function CnCardSubCard({ className = '', subTitle = '', subDesc, action, children, }) {
    return (React.createElement("div", { className: classNames(['cn-ui-card-sub-card', className]) },
        subTitle && (React.createElement("div", { className: "cn-ui-card-sub-card-header" },
            React.createElement("div", { className: "cn-ui-card-sub-card-header-header" }, subTitle),
            React.createElement("div", { className: "cn-ui-card-sub-card-header-footer" }, action))),
        subDesc && (React.createElement("div", { className: "cn-ui-card-subdesc cn-ui-card-desc" }, subDesc)),
        children && (React.createElement("div", { className: "cn-ui-card-sub-card-content" }, children))));
}
CnCardSubCard.displayName = 'CnCardSubCard';
