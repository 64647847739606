import * as React from 'react';
import { connect } from '@formily/react';
import cx from 'classnames';
import { CnAnchor } from '@/components/cn-anchor';
import { CnGrid } from '@/components/grid';
import { CnForm } from '@/form/cn-form';
import './index.scss';
const { CnRow, CnCol } = CnGrid;
export const CnAnchorForm = connect((props) => {
    const { className, rightFixedSpan, anchorProps, ...otherProps } = props;
    const anchorFormRef = React.useRef();
    const [anchorFormMountedStyle, setAnchorFormMountedStyle] = React.useState({});
    React.useEffect(() => {
        if (!anchorFormRef.current)
            return;
        const { left = 0, width = 0, top = 0, } = anchorFormRef.current.getBoundingClientRect();
        setAnchorFormMountedStyle({
            top: top,
            left: left + width,
            right: 'initial',
        });
    }, []);
    return (React.createElement("div", { className: cx('cn-ui-anchor-form', className), ref: anchorFormRef },
        React.createElement(CnRow, null,
            React.createElement(CnCol, null,
                React.createElement(CnForm, { ...otherProps })),
            React.createElement(CnCol, { fixedSpan: rightFixedSpan })),
        React.createElement(CnAnchor, { style: anchorFormMountedStyle, ...anchorProps })));
});
CnAnchorForm.defaultProps = {
    rightFixedSpan: 10,
};
