import * as React from 'react';
import cx from 'classnames';
import { useSize } from 'ahooks';
import { onFormInputChange } from '@formily/core';
import { createForm } from '@formily/core';
import { FormProvider, ExpressionScope } from '@formily/react';
import { createScope } from './components/schema-field';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnIcon } from '@/components/cn-icon';
import { useCnRequest } from '@/components/cn-utils';
import { responsiveSizeH, responsiveSizeV } from './const';
import { FilterSelectedTags } from './components/filter-selected-tags';
import { ConfigModal } from './components/save-config';
import { SaveSelector, InputSaveOverlay } from './components/save-selected';
import { CnFilterGrid } from './components/cn-filter-grid';
import { FilterItemBtns } from './components/filter-item-btns';
import { JSXField } from './components/jsx-field';
import { CnFilterProItem } from './components/cn-filter-pro-item';
import { useSaveSelected } from './hooks/use-save-selected';
import { useSaveConfig } from './hooks/use-save-config';
import { useGap } from './hooks/use-gap';
import { isInvisible, calcLayout, calcLayoutWithButton } from './utils';
import { FilterRefsContext, FilterPropsContext } from './context';
import './index.scss';
import { SaveSelectedActions } from './store';
import { setCache, removeCache } from './cache';
import { HocBaseComponents, Plugin, pluginManager, safeCallFunction, } from '@/components/cn-utils';
export const CnFilterPro = React.forwardRef((props, ref) => {
    const { style, className, form, formProps, components, schema: propsSchema, scope, children, $i18n, extendButtons, extendButtonsPosition, expandButtonUseIcon, resetButtonUseIcon, columns, responsiveSize, columnGap, rowGap, colon, onColumnChange, enableExpand, maxVisibleRow, defaultExpand, expand: propsExpand, onExpandChange, showSelected, showFolder, showBottomLine, storageKey, onGetStorage, onSetStorage, enableConfig, defaultConfigValue, configValue, onConfigValueChange: propsOnConfigValueChange, enableSaveSelected, saveSelectSpan, beforeGetSaveSelected, beforeSetSaveSelected, buttonType, onChange, onSearch, onSubmit, onReset, fullWidth, labelAlign, labelTextAlign, size, labelCol, wrapperCol, moreFilters, useLabelForErrorMessage, requestConfig, cacheSearch, isRightButton, buttonSpan, } = props;
    const [, forceUpdate] = React.useState(1);
    const [fold, setFold] = React.useState(false);
    const filterRef = React.useRef();
    const resizeRef = React.useRef();
    const filterPopupContainerRef = React.useRef();
    const saveSelectedStateRef = React.useRef();
    const expandClickRef = React.useRef(false);
    React.useImperativeHandle(ref, () => resizeRef.current, [resizeRef]);
    const RealSchemaField = React.useMemo(() => createScope(scope), [scope]);
    const [formInstance, setFormInstance] = React.useState();
    // schema变化前的form values
    const formInstanceValuesRef = React.useRef();
    const [expand, setExpand] = React.useState(defaultExpand || false);
    const defaultValuesRef = React.useRef((formInstance && formInstance.getState().values) || {});
    const searchValuesRef = React.useRef((formInstance && formInstance.getState().values) || {});
    const innerValues = formInstance && formInstance.getState().values;
    const [schema, setSchema] = React.useState(propsSchema || {});
    const [schemaChanged, setSchemaChanged] = React.useState();
    React.useEffect(() => {
        if (propsSchema) {
            setSchema(propsSchema);
        }
    }, [propsSchema]);
    React.useEffect(() => {
        if (propsExpand !== undefined && propsExpand !== expand) {
            setExpand(propsExpand);
        }
    }, [expand, propsExpand]);
    const handleToggleMore = React.useCallback(() => {
        expandClickRef.current = true;
        onExpandChange && onExpandChange(!expand);
        if (propsExpand === undefined) {
            setExpand(!expand);
        }
    }, [propsExpand, expand, onExpandChange]);
    const { width } = useSize(resizeRef) || {};
    const innerColumns = React.useMemo(() => {
        if (columns)
            return columns;
        const _responsive = responsiveSize ||
            (labelAlign === 'left' ? responsiveSizeH : responsiveSizeV);
        let matched = _responsive.length; // 默认8个
        if (!width)
            return matched;
        _responsive.some((v, idx) => {
            if (width < v) {
                matched = idx;
                return true;
            }
            return false;
        });
        // 最小值3
        return matched > 3 ? matched : 3;
    }, [width, schema]);
    React.useEffect(() => {
        onColumnChange && onColumnChange(Number(innerColumns));
    }, [innerColumns, onColumnChange]);
    const handleSearch = () => {
        formInstance.validate().then(() => {
            searchValuesRef.current = formInstance.getState().values;
            setCache(searchValuesRef.current, storageKey, cacheSearch);
            onSearch && onSearch(formInstance.getState().values);
            // searchValuesRef更新了, 需要触发tags渲染
            forceUpdate((s) => (s + 1) % 32);
        }, (error) => {
            console.log(error);
        });
    };
    const handleSubmit = (e) => {
        e && e.preventDefault();
        formInstance.submit(onSubmit);
        handleSearch();
    };
    const handleReset = () => {
        formInstance && formInstance.reset('*');
        formInstance &&
            (defaultValuesRef === null || defaultValuesRef === void 0 ? void 0 : defaultValuesRef.current) &&
            formInstance.setValues(defaultValuesRef === null || defaultValuesRef === void 0 ? void 0 : defaultValuesRef.current);
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate((s) => (s + 1) % 32);
        if (onReset) {
            return onReset();
        }
        handleChange(formInstance.getState().values);
        handleSearch();
    };
    const handleChange = (values) => {
        onChange && onChange(values);
    };
    const handleRemove = (key) => {
        formInstance && formInstance.reset(key);
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate((s) => (s + 1) % 32);
        handleChange(formInstance.getState().values);
        handleSearch();
    };
    const getSearchValues = () => searchValuesRef.current;
    const filterRefContext = React.useRef({
        update: () => undefined,
        itemCollection: {},
        filterRef,
        overlayRef: React.useRef(null),
        currentSelectedValuesRef: React.useRef(null),
        formInstance: {},
    });
    if (formInstance) {
        formInstance.filterSubmit = handleSubmit;
        formInstance.filterReset = handleReset;
        formInstance.filterSearch = handleSearch;
        formInstance.filterChange = handleChange;
        formInstance.getSearchValues = getSearchValues;
    }
    const initialChildren = React.useMemo(() => {
        if (!schema || !schema.properties)
            return [];
        return Object.keys(schema.properties).map((v, i) => ({
            key: v,
            value: {
                ...schema.properties[v],
            },
        }));
    }, [schema]);
    const [saveConfigState, saveConfigDispatch] = useSaveConfig({
        enableConfig,
        defaultConfigValue,
        configValue,
        storageKey,
        children: initialChildren,
        onGetStorage,
        onSetStorage,
    });
    const [saveSelectedState, saveSelectedDispatch] = useSaveSelected({
        enableSaveSelected,
        values: searchValuesRef.current,
        storageKey,
        onGetStorage,
        onSetStorage,
        filterContext: filterRefContext.current,
    });
    const { arrangedChildren } = saveConfigState;
    const showAll = !enableExpand || expand;
    const { showChildren, hideChildren } = React.useMemo(() => isRightButton
        ? calcLayoutWithButton(showAll
            ? Object.keys(schema.properties).length
            : Number(maxVisibleRow) || 2, Number(innerColumns), Number(buttonSpan) || 1, arrangedChildren, enableSaveSelected, saveSelectSpan)
        : calcLayout(showAll
            ? Object.keys(schema.properties).length
            : Number(maxVisibleRow) || 2, Number(innerColumns), arrangedChildren, enableSaveSelected, saveSelectSpan), [
        showAll,
        innerColumns,
        maxVisibleRow,
        schema,
        arrangedChildren,
        enableSaveSelected,
        saveSelectSpan,
    ]);
    const filterPropsContext = React.useMemo(() => {
        return {
            ...props,
            cols: (props.columns || innerColumns),
            showAll,
            expand,
            hideChildren,
            handleToggleMore,
            saveSelectedState,
            saveSelectedDispatch,
            saveConfigState,
            saveConfigDispatch,
            rowGap: useGap(size, rowGap),
            columnGap: useGap(size, columnGap),
        };
    }, [
        props,
        showAll,
        expand,
        hideChildren,
        handleToggleMore,
        innerColumns,
        saveSelectedState,
        saveSelectedDispatch,
        saveConfigState,
        saveConfigDispatch,
        size,
    ]);
    const realSchema = React.useMemo(() => {
        const properties = {};
        [
            ...showChildren,
            ...hideChildren.map((child) => ({
                ...child,
                value: {
                    ...child.value,
                    isFold: true,
                },
            })),
        ].map(({ key, value }, idx) => {
            properties[key] = {
                ...value,
                'x-index': idx + 1,
            };
        });
        return {
            type: 'object',
            properties,
        };
    }, [showChildren, hideChildren]);
    const applyValues = (f, hiddenKeys, mode = 'merge') => {
        if (!formInstanceValuesRef.current)
            return;
        // 布局更新（屏幕尺寸调整/查询项调整）时，重新应用上次的 form values
        Object.keys(formInstanceValuesRef.current).map((k) => {
            if (hiddenKeys.includes(k)) {
                delete formInstanceValuesRef.current[k];
            }
        });
        f.setValues(formInstanceValuesRef.current, mode);
    };
    const handleConfigValueChange = (value) => {
        formInstance && applyValues(formInstance, value
            .map(({ visible, name }) => {
            if (!visible)
                return name;
        })
            .filter(Boolean), 'overwrite');
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate((s) => (s + 1) % 32);
        handleChange(formInstance.getState().values);
        safeCallFunction(propsOnConfigValueChange, value);
    };
    React.useEffect(() => {
        if (!cacheSearch) {
            // 关闭时 清空一次session值
            removeCache(storageKey);
        }
    }, [cacheSearch]);
    React.useEffect(() => {
        // 如果是点击 展开/收起 造成的 realSchema 更新，单独处理以避免刷新
        if (expandClickRef.current && formInstance) {
            expandClickRef.current = false;
            const filterGrid = formInstance.query('filterGrid').take();
            filterGrid &&
                filterGrid.setComponentProps({
                    realSchema,
                });
            return;
        }
        // 刷新form要在realSchema更新后的nexttick中，否则不能生效
        setSchemaChanged({});
    }, [form, realSchema]);
    React.useEffect(() => {
        // 记住上次 form values
        if (formInstance && formInstance.getState) {
            formInstanceValuesRef.current = formInstance.getState().values;
        }
        if (form) {
            setFormInstance(form);
            searchValuesRef.current = form.getState().values;
        }
        else {
            setFormInstance(createForm(formProps));
        }
    }, [schemaChanged]);
    React.useEffect(() => {
        if (!formInstance || !formInstanceValuesRef.current)
            return;
        filterRefContext.current.formInstance = formInstance;
        applyValues(formInstance, arrangedChildren
            .map(({ key, value }) => {
            if (isInvisible(value))
                return key;
        })
            .filter(Boolean));
    }, [formInstance]);
    React.useEffect(() => {
        // 查询项有变化时 触发onchange 并 清空查询习惯
        saveSelectedStateRef.current = saveSelectedState;
        formInstance === null || formInstance === void 0 ? void 0 : formInstance.addEffects('onFormInputChange', () => {
            onFormInputChange((f) => {
                handleChange(f.getState().values);
                saveSelectedDispatch(SaveSelectedActions.clearSelected(saveSelectedStateRef.current, {
                    values: f.getState().values,
                }));
            });
        });
    }, [formInstance, saveSelectedState]);
    const isInitRemote = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    const readyRequestConfig = React.useMemo(() => ({
        ...requestConfig,
        ready: isInitRemote,
    }), [requestConfig, isInitRemote]);
    const { data, loading } = useCnRequest(readyRequestConfig);
    React.useEffect(() => {
        if (!isInitRemote)
            return;
        if (loading)
            return;
        formInstance === null || formInstance === void 0 ? void 0 : formInstance.setValues(data || {});
        handleChange(formInstance === null || formInstance === void 0 ? void 0 : formInstance.getState().values);
    }, [data]);
    // 渲染 JSON Schema
    const renderFilterSchema = () => {
        if (!width || !realSchema || !realSchema.properties)
            return null;
        return (React.createElement(RealSchemaField, { scope: scope, components: components },
            React.createElement(RealSchemaField.Void, { name: "filterGrid", "x-component-props": {
                    realSchema,
                }, "x-component": CnFilterGrid },
                enableSaveSelected && (React.createElement(RealSchemaField.Void, { name: "saveSelector", "x-component": SaveSelector, "x-decorator-props": {
                        colSpan: saveSelectSpan,
                    }, "x-index": 0 })),
                Object.keys(realSchema.properties).map((n) => {
                    return (React.createElement(RealSchemaField.Markup, { name: n, ...realSchema.properties[n] }));
                }),
                isRightButton && (React.createElement(RealSchemaField.Void, { name: "rightButton", "x-component": FilterItemBtns, "x-decorator": CnFilterProItem, "x-decorator-props": {
                        colSpan: buttonSpan,
                        className: 'cn-ui-filter-pro-right-button',
                    } })))));
    };
    if (!formInstance) {
        return React.createElement("div", null, "Form \u521D\u59CB\u5316\u5B9E\u4F8B\u4E2D...");
    }
    return (React.createElement(FormProvider, { form: formInstance },
        React.createElement(ExpressionScope, { value: { $$form: formInstance } },
            React.createElement(FilterRefsContext.Provider, { value: filterRefContext.current },
                React.createElement(FilterPropsContext.Provider, { value: filterPropsContext },
                    React.createElement("div", { "data-name": "CnFilter", 
                        // id="filter-popup-container"
                        className: cx('cn-ui-filter-pro', className, `cn-ui-filter-pro-${size}`), style: style, ref: resizeRef },
                        React.createElement(ConfigModal, { enableConfig: enableConfig, enableSaveSelected: enableSaveSelected, onConfigValueChange: handleConfigValueChange, configValue: configValue, store: saveConfigState, dispatch: saveConfigDispatch, gridProps: {
                                columns: innerColumns,
                                gap: [8, 8],
                            }, saveSelectSpan: saveSelectSpan }),
                        React.createElement(InputSaveOverlay, { enableSaveSelected: enableSaveSelected, values: searchValuesRef.current, beforeGetSaveSelected: beforeGetSaveSelected, store: saveSelectedState, dispatch: saveSelectedDispatch }),
                        React.createElement("div", { style: { display: fold ? 'none' : 'inherit' } },
                            React.createElement(CnFormLayout, { ...{
                                    colon,
                                    labelAlign,
                                    labelTextAlign,
                                    labelCol,
                                    wrapperCol,
                                    size: size,
                                } },
                                renderFilterSchema(),
                                React.createElement(JSXField, { ...{
                                        propsSchema,
                                        schema,
                                        setSchema,
                                        children,
                                        formInstance,
                                    } })),
                            isRightButton ? null : React.createElement(FilterItemBtns, null)),
                        showSelected ? (React.createElement(FilterSelectedTags, { values: searchValuesRef.current, innerValues: innerValues, onRemove: handleRemove })) : null,
                        showFolder ? (React.createElement("div", { className: "cn-ui-filter-foldline" },
                            React.createElement("div", { className: "cn-ui-filter-foldline-btn", onClick: () => {
                                    setFold(!fold);
                                } },
                                React.createElement(CnIcon, { className: "cn-ui-filter-foldline-btn-icon", type: fold ? 'icon-arrow-down' : 'icon-arrow-up', size: "small" }),
                                fold
                                    ? $i18n.get({ id: 'Expand', dm: '展开', ns: 'CnFilter' })
                                    : $i18n.get({
                                        id: 'PutItAway',
                                        dm: '收起',
                                        ns: 'CnFilter',
                                    })))) : null,
                        !showFolder && showBottomLine ? (React.createElement("div", { className: "cn-ui-filter-foldline" })) : null,
                        React.createElement("div", { "data-role": "filter-popup-container", ref: filterPopupContainerRef })))))));
});
export const CnFilterProPlugin = React.forwardRef((props, ref) => {
    const plugin = React.useMemo(() => {
        const plugin = new Plugin();
        plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        plugin.setGlobalPlugin(pluginManager.getPlugin('CnFilterPro').map((item) => item.method));
        return plugin;
    }, []);
    const plguins = plugin.getPlugin();
    if (plguins.length === 0) {
        return React.createElement(CnFilterPro, { ...props, ref: ref });
    }
    return HocBaseComponents(CnFilterPro, { props, plguins, ref });
});
CnFilterPro.defaultProps = {
    extendButtonsPosition: 'end',
    expandButtonUseIcon: false,
    resetButtonUseIcon: false,
    isRightButton: false,
    buttonSpan: 1,
    enableExpand: true,
    maxVisibleRow: 2,
    showSelected: false,
    showFolder: true,
    showBottomLine: false,
    enableConfig: false,
    enableSaveSelected: false,
    saveSelectSpan: 1,
    size: 'medium',
    useLabelForErrorMessage: true,
};
