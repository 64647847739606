import React, { createContext, useCallback, useEffect, useMemo, useState, } from 'react';
import formatColumns from '../../utils/format-columns';
import getColumnsKey from '../../utils/get-columns-key';
export const ColumnsStateCtx = createContext({
    columns: [],
    originColumns: [],
    fixLeftColumns: [],
    fixRightColumns: [],
    noFixColumns: [],
    columnKeys: [],
    checkedColumnKeys: [],
});
export function ColumnsSettingCtx(props) {
    const { isDialog, columns: originColumns, setColumns: setOriginColumns, } = props;
    const [columns, setColumns] = useState(formatColumns(originColumns));
    const checkedColumnKeys = useMemo(() => {
        return columns.reduce((result, item) => {
            if (item.hidden)
                return result;
            return [...result, item.dataIndex];
        }, []);
    }, [columns]);
    useEffect(() => {
        setColumns(formatColumns(originColumns));
    }, [originColumns]);
    const columnKeys = useMemo(() => {
        return getColumnsKey(originColumns);
    }, [originColumns]);
    const fixLeftColumns = useMemo(() => {
        return columns.filter((item) => item.lock === 'left' || item.lock === true);
    }, [columns]);
    const fixRightColumns = useMemo(() => {
        return columns.filter((item) => item.lock === 'right');
    }, [columns]);
    const noFixColumns = useMemo(() => {
        return columns.filter((item) => !item.lock);
    }, [columns]);
    const updateColumns = useCallback(() => {
        setOriginColumns(columns, 'user');
    }, [columns, setOriginColumns]);
    /**
     * 取消列配置变更
     * 使用场景：当CnDialog关闭时，但dom未销毁
     */
    const cancelUpdateColumns = useCallback(() => {
        setColumns(formatColumns(originColumns));
    }, [setColumns, originColumns]);
    const output = {
        isDialog,
        columns,
        setColumns,
        fixLeftColumns,
        fixRightColumns,
        noFixColumns,
        columnKeys,
        checkedColumnKeys,
        checkedAll: checkedColumnKeys.length === columns.length,
        updateColumns,
        cancelUpdateColumns,
    };
    return (React.createElement(ColumnsStateCtx.Provider, { value: output }, props.children));
}
