import { useCallback } from 'react';
import { getFileIndex } from '../../../utils';
export const useRemoveFile = (props) => {
    const { setValue, onChangeRef, onRemoveRef } = props;
    return useCallback((_file) => {
        setValue((prev) => {
            var _a, _b;
            const fileIndex = getFileIndex(_file, prev);
            const file = prev[fileIndex];
            const value = prev.filter((_, index) => fileIndex !== index);
            (_a = onChangeRef.current) === null || _a === void 0 ? void 0 : _a.call(onChangeRef, value, file);
            (_b = onRemoveRef === null || onRemoveRef === void 0 ? void 0 : onRemoveRef.current) === null || _b === void 0 ? void 0 : _b.call(onRemoveRef, file);
            return value;
        });
    }, [setValue, onRemoveRef, onChangeRef]);
};
