import React from 'react';
import cx from 'classnames';
import { UploadListItem } from '../upload-list-item';
import './index.scss';
export const UploadList = (props) => {
    const { value, limit, readOnly, showRemove, ...restProps } = props;
    if (readOnly && !value.length)
        return null;
    if (!value.length)
        return null;
    if (limit === 1) {
        return (React.createElement("div", { className: cx('cn-ui-upload-single', 'cn-ui-upload-list-normal') },
            React.createElement(UploadListItem, { ...restProps, file: value[0], showRemove: !readOnly && showRemove })));
    }
    return (React.createElement("div", { className: cx('cn-ui-upload-list', 'cn-ui-upload-list-normal') }, value.map((item) => (React.createElement(UploadListItem, { key: item.uid || item.name || item.size, ...restProps, file: item, showRemove: !readOnly && showRemove })))));
};
UploadList.defaultProps = {
    showRemove: true,
};
