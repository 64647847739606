/* eslint-disable @typescript-eslint/ban-ts-comment */
import { packRequest } from '@/components/cn-utils';
import Config from './config';
import { isFunction } from './util';
export const defaultRemoteTransform = (res, resp) => {
    var _a;
    return (_a = res === null || res === void 0 ? void 0 : res.data) !== null && _a !== void 0 ? _a : res;
};
export default function fetch(opts) {
    // @ts-ignore
    return (isFunction(Config.request) ? Config.request : packRequest)(
    // @ts-ignore
    opts);
}
