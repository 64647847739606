import * as React from 'react';
import { Field, useField, observer } from '@formily/react';
import { CnFormItem } from '@/form/cn-form-item';
import { mapSize, mapStatus } from '@/form/__builtins__';
export const CnFormField = (props) => {
    const { children, ...otherProps } = props;
    if (children) {
        return (React.createElement(Field, { decorator: [CnFormItem], ...otherProps, component: [
                observer((childProps) => {
                    const childrenProps = (childProps === null || childProps === void 0 ? void 0 : childProps.props) || {};
                    const field = useField();
                    const { state } = mapStatus(childrenProps, field);
                    const { size } = mapSize(childrenProps);
                    return React.cloneElement(children, {
                        ...childProps,
                        field,
                        state,
                        size,
                    });
                }),
            ] }));
    }
    return React.createElement(Field, { decorator: [CnFormItem], ...otherProps });
};
