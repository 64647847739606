import { isFunction, mergeCellProps } from '../utils';
import { makeRecursiveMapper } from '../utils/tree';
export default function cellPropsPipeline(cellProps) {
    return function (pipeline) {
        const sourceColumns = pipeline.getColumns();
        let colIndex = 0;
        if (cellProps instanceof Function) {
            const traverseHandler = makeRecursiveMapper((col, { isLeaf }) => {
                if (!col) {
                    return col;
                }
                const nowColIndex = colIndex;
                if (isLeaf) {
                    const sourceGetCellProps = col.getCellProps;
                    col.getCellProps = (value, rowData, rowIndex) => {
                        const sourceCellProps = isFunction(sourceGetCellProps)
                            ? sourceGetCellProps(value, rowData, rowIndex)
                            : {};
                        const commonCellProps = cellProps(rowIndex, nowColIndex, col.code, rowData);
                        // 优先级 cellProps > getCellProps > pipeline内部getCellProps
                        return mergeCellProps(sourceCellProps, commonCellProps);
                    };
                    colIndex += 1;
                }
                return col;
            });
            traverseHandler(sourceColumns);
        }
        return pipeline.columns(sourceColumns);
    };
}
