import $i18n from 'panda-i18n';
import React, { useContext, useRef, useState } from 'react';
import { Box, Checkbox } from '@/components/fusion';
import useDrag from 'ahooks/lib/useDrag';
import useDrop from 'ahooks/lib/useDrop';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { CnIcon } from '@/components/cn-icon';
import { columnsLeftFixedArmsReport, columnsRightFixedArmsReport, columnsDragDropArmsReport, } from '../arms';
import './index.scss';
import { ColumnsStateCtx } from '../columns-setting/context';
import { CnMessage } from '@/components/cn-message';
import { leftFixMaxNum, rightFixMaxNum } from '@/components/cn-table/const';
import { getParent } from '../util';
import DragIcon from './drag-icon';
import classNames from 'classnames';
function FixLeftColumn(props) {
    return (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { type: "arrow-up-line", onClick: props.onClick, size: "medium" }) }, $i18n.get({
        id: 'FixedOnTheLeftSideOf_867442762',
        dm: '固定在表格左侧',
        ns: 'CnTable',
    })));
}
function CancelColumnFix(props) {
    return (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { type: "uturn-right", size: "medium", onClick: props.onClick }) }, $i18n.get({ id: 'Unfixed', dm: '取消固定', ns: 'CnTable' })));
}
function FixRightColumn(props) {
    return (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { type: "arrow-down-line", size: "medium", onClick: props.onClick }) }, $i18n.get({
        id: 'FixedToTheRightOfThe_1409668450',
        dm: '固定在表格右侧',
        ns: 'CnTable',
    })));
}
const errorTip = (num) => {
    CnMessage.error($i18n.get({
        id: 'MaximumFixedNum',
        dm: '最多固定{num}个',
        ns: 'CnTable',
    }, { num }));
};
export function ColumnItem(props) {
    const { itemRightSlot, showIndex, position, dataItem, index } = props;
    const { dataIndex } = dataItem;
    const domRef = useRef();
    const [isHovering, setIsHovering] = useState(false);
    const dropPosition = useRef('bottom');
    const { fixLeftColumns, fixRightColumns, checkedColumnKeys, setColumns } = useContext(ColumnsStateCtx);
    useDrag(dataIndex, domRef, {
        onDragStart: (event) => {
            event.dataTransfer.setData('text', dataIndex);
        },
        onDragEnd: () => {
            columnsDragDropArmsReport();
        },
    });
    useDrop(domRef, {
        onText: (dragKey) => {
            if (dragKey === dataIndex)
                return;
            setColumns((prev) => {
                const dragItem = prev.find((item) => item.dataIndex === dragKey);
                return prev
                    .filter((item) => item.dataIndex !== dragKey)
                    .reduce((result, item) => {
                    if (item.dataIndex === dataIndex) {
                        if (dropPosition.current === 'top') {
                            return [...result, dragItem, item];
                        }
                        return [...result, item, dragItem];
                    }
                    return [...result, item];
                }, []);
            });
        },
        onDragEnter: (event) => {
            const itemElement = getParent(event.currentTarget, (el) => el.className === 'cn-table-column-setting__item');
            if (itemElement === null || itemElement === void 0 ? void 0 : itemElement.getBoundingClientRect) {
                const { top, height } = itemElement.getBoundingClientRect();
                const halfLineTop = top + height / 2;
                setIsHovering(event.clientY > halfLineTop ? 'bottom' : 'top');
                dropPosition.current = event.clientY > halfLineTop ? 'bottom' : 'top';
            }
            else {
                setIsHovering('bottom');
            }
        },
        onDragLeave: () => setIsHovering(false),
        onDrop: () => setIsHovering(false),
    });
    return (React.createElement("div", { ref: domRef, className: classNames('cn-table-column-setting__item', {
            'cn-table-column-setting__item__top': isHovering === 'top',
            'cn-table-column-setting__item__bottom': isHovering === 'bottom',
        }) },
        React.createElement("div", { className: "cn-table-column-setting__item__left" },
            showIndex ? (React.createElement("span", { className: "cn-table-column-setting__item__left__index" },
                index + 1,
                ".")) : null,
            React.createElement(Checkbox, { className: "cn-table-column-setting_checkbox", checked: checkedColumnKeys.includes(dataItem.dataIndex), disabled: dataItem === null || dataItem === void 0 ? void 0 : dataItem.disabled, onChange: (checked) => {
                    setColumns((prev) => {
                        return prev.map((item) => {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return { ...item, hidden: !checked };
                            }
                            return item;
                        });
                    });
                } }),
            React.createElement("div", { className: "cn-table-column-setting_label" },
                React.createElement(CnEllipsis, null, dataItem.name || dataItem.title))),
        React.createElement(Box, { direction: "row", align: "center", spacing: 8, className: "cn-table-column-setting__item__right" },
            itemRightSlot,
            React.createElement("div", { className: "cn-table-column-setting_icon" }, position === 'left' ? (React.createElement(CancelColumnFix, { onClick: () => {
                    setColumns((prev) => {
                        return prev.map((item) => {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return { ...item, lock: undefined };
                            }
                            return item;
                        });
                    });
                    columnsLeftFixedArmsReport();
                } })) : (React.createElement(FixLeftColumn, { onClick: () => {
                    if (fixLeftColumns.length >= leftFixMaxNum) {
                        errorTip(leftFixMaxNum);
                        return;
                    }
                    setColumns((prev) => {
                        return prev.map((item) => {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return { ...item, lock: 'left' };
                            }
                            return item;
                        });
                    });
                    columnsLeftFixedArmsReport();
                } }))),
            React.createElement("div", { className: "cn-table-column-setting_icon" }, position === 'right' ? (React.createElement(CancelColumnFix, { onClick: () => {
                    setColumns((prev) => {
                        return prev.map((item) => {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return { ...item, lock: undefined };
                            }
                            return item;
                        });
                    });
                    columnsRightFixedArmsReport();
                } })) : (React.createElement(FixRightColumn, { onClick: () => {
                    if (fixRightColumns.length >= rightFixMaxNum) {
                        errorTip(rightFixMaxNum);
                        return;
                    }
                    setColumns((prev) => {
                        return prev.map((item) => {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return { ...item, lock: 'right' };
                            }
                            return item;
                        });
                    });
                    columnsRightFixedArmsReport();
                } }))),
            React.createElement("div", { className: "cn-table-column-setting_icon" },
                React.createElement(DragIcon, null)))));
}
