import * as React from 'react';
import { Button as NextButton } from '@fusion/lib';
import { useAsyncLoading } from '@/components/cn-utils';
import classNames from 'classnames';
export const CnButton = React.forwardRef((props, ref) => {
    const { className, ...restProps } = props;
    const wrapLoading = useAsyncLoading(props);
    return (React.createElement(NextButton, { className: classNames('cn-ui-button', className), "data-name": "CnButton", ...restProps, ...wrapLoading, ref: ref }));
});
/**
 * @deprecated
 * 建议使用 SplitButtonGroup
 */
CnButton.Group = NextButton.Group;
CnButton.displayName = 'CnButton';
CnButton.defaultProps = {
    type: 'normal',
    size: 'medium',
    icons: {},
    htmlType: 'button',
    component: 'button',
    loading: false,
    ghost: false,
    text: false,
    warning: false,
    disabled: false,
    onClick: function onClick() { },
};
export const CnSplitButtonGroup = React.forwardRef((props, ref) => {
    const { className } = props;
    return (React.createElement(NextButton.Group, { ref: ref, className: classNames('cn-ui-button-group', className), ...props }));
});
export const Button = CnButton;
export const SplitButtonGroup = NextButton.Group;
