import throttle from 'lodash/throttle';
function getArmsTrace() {
    return window.coneArmsTrace;
}
export function pageChangeArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '翻页',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.pageChange',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export const columnResizeChangeArmsReport = throttle(columnResizeChangeArmsReportBase, 3000);
function columnResizeChangeArmsReportBase() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '列拖拽宽度改变',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnResize',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function columnsLeftFixedArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '列左侧固定',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsLeftFixed',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function columnsRightFixedArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '列右侧固定',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsRightFixed',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function columnsAllCheckedArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '列全选',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsAllChecked',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function columnsDragDropArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '列拖拽位置改变',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsdragDrop',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function columnsSettingArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '列设置',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsSetting',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function tableFullScreenArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '表格全屏',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.tableFullScreen',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function tableDensityArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '表格密度选择',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.tableDensity',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function tableSortArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '表格排序',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.tableSort',
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function tablefilterArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '表格列筛选',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.tablefiltert',
        });
    }
    catch (error) {
        console.log(error);
    }
}
