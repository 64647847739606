import useLatest from 'ahooks/lib/useLatest';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { CnMessage } from '@/components/cn-message';
import { useCnRequest } from '@cainiaofe/cn-ui-utils';
export default function useJobPolling(props) {
    const { jobId, onSuccess, onError, pollingRequestConfig } = props;
    const onErrorRef = useLatest(onError);
    const onSuccessRef = useLatest(onSuccess);
    const pollingRequestConfigRef = useLatest(pollingRequestConfig);
    const intervalRef = useRef(0);
    const { data: _data, error, runAsync, cancel, } = useCnRequest({
        ...pollingRequestConfig,
        pollingInterval: intervalRef.current,
        onBefore: (params) => {
            var _a;
            if (intervalRef.current === 0)
                intervalRef.current = 1000;
            else if (intervalRef.current === 1000)
                intervalRef.current = 2000;
            else if (intervalRef.current === 2000)
                intervalRef.current = 3000;
            else if (intervalRef.current === 3000)
                intervalRef.current = 4000;
            else if (intervalRef.current === 4000)
                intervalRef.current = 5000;
            else if (intervalRef.current === 5000) {
                if (!props.pollingInterval || props.pollingInterval < 5000) {
                    intervalRef.current = 5000;
                }
                else {
                    intervalRef.current = props.pollingInterval;
                }
            }
            else
                intervalRef.current = 5000;
            (_a = pollingRequestConfig.onBefore) === null || _a === void 0 ? void 0 : _a.call(pollingRequestConfig, params);
        },
    });
    const data = useMemo(() => {
        try {
            if (typeof error === 'object') {
                const temp = error === null || error === void 0 ? void 0 : error.data;
                if (temp === null || temp === void 0 ? void 0 : temp.jobId)
                    return temp;
            }
        }
        catch { }
        return _data;
    }, [error, _data]);
    const startTask = useCallback(async () => {
        const _pollingService = pollingRequestConfigRef.current;
        if (!_pollingService || !jobId)
            return undefined;
        try {
            await runAsync({ jobId });
        }
        catch (err) {
            if ((err === null || err === void 0 ? void 0 : err.message) === 'canceled')
                return undefined;
            if (onErrorRef.current) {
                onErrorRef.current(err);
            }
            else if (err.message) {
                CnMessage.error(err.message);
            }
        }
        return undefined;
    }, [jobId, onErrorRef, pollingRequestConfigRef, runAsync]);
    useEffect(() => {
        var _a;
        if ((data === null || data === void 0 ? void 0 : data.status) !== 'EXECUTING')
            cancel();
        if ((data === null || data === void 0 ? void 0 : data.status) === 'SUCCEEDED')
            (_a = onSuccessRef.current) === null || _a === void 0 ? void 0 : _a.call(onSuccessRef, data);
    }, [data, cancel, onSuccessRef]);
    useEffect(() => {
        startTask();
        return () => {
            cancel();
        };
    }, [cancel, startTask]);
    const result = useMemo(() => ({ jobResult: data, cancel }), [data, cancel]);
    return result;
}
