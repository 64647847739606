import $i18n from 'panda-i18n'; /* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from 'react';
import isPlainObject from 'lodash/isPlainObject';
import { Input, Icon, Checkbox } from '@/components/fusion';
import './style/index.scss';
import { useControlValue } from './hooks';
import { debounce, isDef, isFunction, isString } from './util';
export default function ListFilter(props) {
    var _a, _b, _c, _d;
    const { list, labelKey, valueKey, className, style, searchStyle, searchTransform, column, } = props;
    const [search, setSearch] = React.useState('');
    const onChangeSearch = debounce((val) => {
        setSearch(val);
    }, 500, false);
    const allValue = React.useMemo(() => getAllValue(list, valueKey), [list]);
    const filterList = React.useMemo(() => getFilterList(list, isFunction(searchTransform) ? searchTransform(search) : search, labelKey), [list, search, labelKey]);
    // console.log(allValue, 'allValue');
    const [value, setValue] = useControlValue(props, {
        defaultValuePropName: 'defaultValue',
        valuePropName: 'value',
        changePropName: 'onChange',
        defaultValue: [],
    });
    // console.log(value, 'value');
    const filterValue = React.useMemo(() => {
        return ((filterList === null || filterList === void 0 ? void 0 : filterList.filter((item) => {
            return value.includes(item[valueKey]);
        })) || []);
    }, [filterList, value, valueKey]);
    const [openKeys, setOpenKeys] = useControlValue(props, {
        defaultValuePropName: 'defaultOpenKeys',
        valuePropName: 'openKeys',
        changePropName: 'onChangeOpenKeys',
        defaultValue: [],
    });
    const valueMap = React.useMemo(() => {
        return Array.isArray(value)
            ? value.reduce((ret, next) => ({
                ...ret,
                [next]: true,
            }), {})
            : {};
    }, [value]);
    const openKeysMap = React.useMemo(() => {
        return Array.isArray(openKeys)
            ? openKeys.reduce((ret, next) => ({
                ...ret,
                [next]: true,
            }), {})
            : {};
    }, [openKeys]);
    const onChange = (changeValue, checked) => {
        const ret = Array.isArray(value) ? [...value] : [];
        if (checked && !valueMap[changeValue]) {
            ret.push(changeValue);
            setValue(ret);
        }
        else if (!checked && valueMap[changeValue]) {
            const valueSet = new Set(ret);
            valueSet.delete(changeValue);
            setValue(Array.from(valueSet));
        }
    };
    const onChangeOpenKeys = (changeOpenKey, isOpen) => {
        const ret = Array.isArray(openKeys) ? [...openKeys] : [];
        if (isOpen && !openKeysMap[changeOpenKey]) {
            ret.push(changeOpenKey);
            setOpenKeys(ret);
        }
        else if (!isOpen && openKeysMap[changeOpenKey]) {
            const valueSet = new Set(ret);
            valueSet.delete(changeOpenKey);
            setOpenKeys(Array.from(valueSet));
        }
    };
    const onCheckAll = (checked) => {
        setValue(checked ? [...allValue] : []);
    };
    const onCheckAllFilterValue = (checked) => {
        const filterValueList = filterList === null || filterList === void 0 ? void 0 : filterList.map((item) => item[valueKey]);
        if (checked) {
            setValue([...value, ...filterValueList]);
        }
        else {
            setValue(allValue.filter((item) => filterList.includes(item)));
        }
    };
    function renderItem(item, level, index) {
        var _a, _b, _c;
        if (isFunction(column === null || column === void 0 ? void 0 : column.render)) {
            const key = (_c = (_b = (_a = column.code) !== null && _a !== void 0 ? _a : column.key) !== null && _b !== void 0 ? _b : labelKey) !== null && _c !== void 0 ? _c : '';
            try {
                item[key] = JSON.parse(item[key]);
            }
            catch (error) { }
            return column.render(item[key], item, level === 1 ? index : -1);
        }
        if (isUndefOrNullString(item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'])) {
            return $i18n.get({ id: 'Empty', dm: '空', ns: 'CnListFilter' });
        }
        return item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'];
        // {
        //   column?.render instanceof Function
        //     ? column.render(
        //       item?.[column.code ?? column.key ?? labelKey ?? ''],
        //       item,
        //       level === 1 ? index : -1,
        //     )
        //     : isUndefOrNullString(item[labelKey ?? 'label'])
        //       ? $i18n.get({ id: 'Empty', dm: '空', ns: 'CnListFilter' })
        //       : item[labelKey ?? 'label'];
        // }
    }
    // @ts-ignore
    function renderList(list, level = 1) {
        return Array.isArray(list)
            ? list.map((item = {
                children: [],
            }, index) => {
                var _a, _b;
                const ItemValue = isPlainObject(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'])
                    ? JSON.stringify(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'])
                    : item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'];
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: `cn-list-filter-item ${valueMap[item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']]
                            ? 'cn-list-filter-selected'
                            : ''}`, key: ItemValue },
                        ((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement(CaretRightIcon, { className: openKeysMap[ItemValue] && 'cn-list-filter-opened', onClick: () => {
                                onChangeOpenKeys(ItemValue, !openKeysMap[ItemValue]);
                            } })) : null,
                        React.createElement(Checkbox, { checked: (_b = valueMap[ItemValue]) !== null && _b !== void 0 ? _b : false, onChange: (checked) => {
                                onChange(ItemValue, checked);
                            }, className: "cn-list-filter-checkbox" }),
                        renderItem(item, level, index)),
                    openKeysMap[item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']] && (React.createElement("div", { style: { marginLeft: level * 16 }, className: "cn-list-filter-list" }, renderList(item === null || item === void 0 ? void 0 : item.children, level + 1)))));
            })
            : null;
    }
    return (React.createElement("div", { style: style, className: `cn-list-filter${className ? ` ${className !== null && className !== void 0 ? className : ''}` : ''}` },
        React.createElement(Input, { innerBefore: React.createElement(Icon, { type: "search", style: { margin: 4 } }), placeholder: "search", className: "cn-list-filter-search", onChange: onChangeSearch, style: searchStyle }),
        React.createElement("div", { className: "cn-list-filter-list cn-list-filter-wrapper" },
            !search ? (React.createElement("div", { className: `cn-list-filter-item ${allValue.length === (value === null || value === void 0 ? void 0 : value.length) ? 'cn-list-filter-selected' : ''}` },
                React.createElement(Checkbox, { checked: allValue.length === (value === null || value === void 0 ? void 0 : value.length), indeterminate: allValue.length > (value === null || value === void 0 ? void 0 : value.length) && (value === null || value === void 0 ? void 0 : value.length) > 0, className: "cn-list-filter-checkbox", onChange: onCheckAll }),
                $i18n.get({ id: 'SelectAll', dm: '全选(', ns: 'CnListFilter' }), (_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : 0,
                "/", (_b = allValue === null || allValue === void 0 ? void 0 : allValue.length) !== null && _b !== void 0 ? _b : 0,
                ")")) : (filterList === null || filterList === void 0 ? void 0 : filterList.length) ? (React.createElement("div", { className: `cn-list-filter-item ${allValue.length === (filterList === null || filterList === void 0 ? void 0 : filterList.length)
                    ? 'cn-list-filter-selected'
                    : ''}` },
                React.createElement(Checkbox, { checked: filterValue.length === (filterList === null || filterList === void 0 ? void 0 : filterList.length), indeterminate: filterValue.length < (filterList === null || filterList === void 0 ? void 0 : filterList.length) &&
                        filterValue.length > 0, className: "cn-list-filter-checkbox", onChange: onCheckAllFilterValue }),
                $i18n.get({
                    id: 'SelectAllSearchItems',
                    dm: '全选搜索项目(',
                    ns: 'CnListFilter',
                }), (_c = filterValue === null || filterValue === void 0 ? void 0 : filterValue.length) !== null && _c !== void 0 ? _c : 0,
                "/", (_d = filterList === null || filterList === void 0 ? void 0 : filterList.length) !== null && _d !== void 0 ? _d : 0,
                ")")) : null,
            renderList(filterList, 1))));
}
function dfs(list, handlerCallback) {
    Array.isArray(list) &&
        list.forEach((item) => {
            var _a;
            handlerCallback instanceof Function && handlerCallback(item);
            if (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                dfs(item === null || item === void 0 ? void 0 : item.children, handlerCallback);
            }
        });
}
// 是否为叶子结点
function isLeafNode(item) {
    var _a;
    return !(((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) > 0);
}
function dfsHouxu(list, handlerCallback, level = 1) {
    Array.isArray(list) &&
        list.forEach((item) => {
            var _a;
            if (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                dfsHouxu(item === null || item === void 0 ? void 0 : item.children, handlerCallback, level + 1);
            }
            handlerCallback instanceof Function && handlerCallback(item, level);
        });
}
function getAllValue(list, valueKey) {
    const allValue = [];
    dfs(list, (item) => {
        if ((valueKey !== null && valueKey !== void 0 ? valueKey : 'value') in item) {
            allValue.push(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']);
        }
    });
    return allValue;
}
function getNewNode(labelKey) {
    return {
        [labelKey !== null && labelKey !== void 0 ? labelKey : 'label']: '',
        value: '',
        children: [],
    };
}
function getFilterList(list, search, labelKey = 'label') {
    if (search === '') {
        return list;
    }
    let nowNodes = null;
    let nowNodeLevel = -1;
    let nowTree = null;
    let nowTreeLevel = -1;
    dfsHouxu(list, (item, level) => {
        var _a, _b, _c, _d, _e;
        if (nowNodeLevel === -1) {
            // 初始化
            nowNodeLevel = level;
            nowNodes = getNewNode(labelKey);
        }
        if (level < nowNodeLevel) {
            // 增加了一层，就做一层统计
            if (nowNodes && ((_a = nowNodes === null || nowNodes === void 0 ? void 0 : nowNodes.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                // 当前有节点, 则新建
                const newTempNode = {
                    ...item,
                    children: nowNodes === null || nowNodes === void 0 ? void 0 : nowNodes.children,
                };
                nowNodes = {
                    ...getNewNode(labelKey),
                    children: [newTempNode],
                };
            }
            if (level === nowTreeLevel && nowTree && nowNodes) {
                // 重新返回
                nowTree.children = nowTree.children.concat(nowNodes.children);
                nowNodes = nowTree;
            }
        }
        else if (level > nowNodeLevel) {
            // 开始深入下去
            // 记录当前start
            nowTreeLevel = nowNodeLevel;
            nowTree = nowNodes;
            // 记录当前end
            nowNodeLevel = level;
            nowNodes = getNewNode(labelKey);
            if (item &&
                isString(item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label']) &&
                item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'].includes(search)) {
                (_c = (_b = nowNodes === null || nowNodes === void 0 ? void 0 : nowNodes.children) === null || _b === void 0 ? void 0 : _b.push) === null || _c === void 0 ? void 0 : _c.call(_b, item);
            }
        }
        else if (level === nowNodeLevel) {
            // 平层，开始compare
            // @ts-ignore
            if (item &&
                isString(item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label']) &&
                item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'].includes(search)) {
                (_e = (_d = nowNodes === null || nowNodes === void 0 ? void 0 : nowNodes.children) === null || _d === void 0 ? void 0 : _d.push) === null || _e === void 0 ? void 0 : _e.call(_d, item);
            }
        }
        // 同步层次
        nowNodeLevel = level;
    });
    // @ts-ignore
    return nowNodes && nowNodes.children ? nowNodes.children : list;
}
function CaretRightIcon(props) {
    return (React.createElement("svg", { focusable: "false", preserveAspectRatio: "xMidYMid meet", fill: "currentColor", width: "16", height: "16", viewBox: "0 0 32 32", ...props },
        React.createElement("path", { d: "M12 8L22 16 12 24z" })));
}
function isUndefOrNullString(val) {
    return !isDef(val) || (isString(val) && val.replace(/\s/g, '') === '');
}
