import $i18n from 'panda-i18n';
import React, { useCallback, useMemo, useState } from 'react';
import useLatest from 'ahooks/lib/useLatest';
import classNames from 'classnames';
import { events, friendlyTips } from '@/components/cn-utils';
import { useCnRequest } from '@cainiaofe/cn-ui-utils';
import { CnMessage } from '@/components/cn-message';
import { cssPrefix } from './constant';
import ExportButton from './export-button';
import LoopDialog from './loop-dialog';
import { renameQuickDialog } from './rename-quick-dialog';
import { LOOPING_EXPORT_JOB } from '../cn-async-jobs-pro/constant';
import { CnDialog } from '../cn-dialog';
export function CnExport(props) {
    const { style, className, buttonProps, buttonText, children = null, needRename = false, renameDialogProps, createService, createRequestConfig: _createRequestConfig, hideLoop: hideLoopDialog, pollingService, pollingRequestConfig: _pollingRequestConfig, pollingInterval, showBgProcessBtn, autoDownload, onCreateSuccess, onSuccess, onError, } = props;
    const [jobId, setJobId] = useState();
    const [createJobLoading, setCreateJobLoading] = useState(false);
    const [loopDialogVisible, setLoopDialogVisible] = useState(false);
    const renameDialogPropsRef = useLatest(renameDialogProps);
    const onCreateSuccessRef = useLatest(onCreateSuccess);
    const onErrorRef = useLatest(onError);
    const createRequestConfig = useMemo(() => {
        const main = () => {
            if (_createRequestConfig) {
                return _createRequestConfig;
            }
            if (typeof createService === 'function') {
                return {
                    service: createService,
                };
            }
            return createService;
        };
        return { ...main(), manual: true };
    }, [createService, _createRequestConfig]);
    const pollingRequestConfig = useMemo(() => {
        const main = () => {
            if (_pollingRequestConfig) {
                return _pollingRequestConfig;
            }
            if (typeof pollingService === 'function') {
                return {
                    service: pollingService,
                };
            }
            return pollingService;
        };
        return { ...main(), manual: true };
    }, [pollingService, _pollingRequestConfig]);
    const _onCreateSuccess = useCallback(async (data) => {
        const _jobId = ['number', 'string'].includes(typeof data)
            ? data
            : data.jobId;
        if (['number', 'string'].includes(typeof data)) {
            friendlyTips({
                component: 'CnExport',
                message: $i18n.get({
                    id: 'WhenCreatingTheDataStructureReturned_1770598113',
                    dm: '创建导出的任务返回的数据结构必须时对象，为了避免后期出现问题，请尽快调整',
                    ns: 'CnExport',
                }),
            });
        }
        if (!_jobId) {
            throw new Error($i18n.get({
                id: 'TheReturnedStructureOfTheCreated_1950281892',
                dm: '创建任务服务返回结构不正确，必须包含jobId',
                ns: 'CnExport',
            }));
        }
        events.emit(LOOPING_EXPORT_JOB);
        if (!hideLoopDialog) {
            setJobId(_jobId);
            setLoopDialogVisible(true);
        }
        if (onCreateSuccessRef.current) {
            onCreateSuccessRef.current(data);
        }
        else if (hideLoopDialog) {
            CnMessage.notice($i18n.get({
                id: 'ExportInProgress',
                dm: '导出进行中',
                ns: 'CnExport',
            }));
        }
    }, [hideLoopDialog, onCreateSuccessRef]);
    const { runAsync } = useCnRequest(createRequestConfig);
    const onClickExportBtn = useCallback(async () => {
        try {
            const jobName = needRename
                ? await renameQuickDialog(renameDialogPropsRef.current)
                : undefined;
            setCreateJobLoading(true);
            let data = await runAsync({ jobName });
            if (data.needAudit) {
                try {
                    await new Promise((resolve, reject2) => {
                        CnDialog.confirm({
                            title: $i18n.get({
                                id: 'ExportDataApproval',
                                dm: '导出数据审批',
                                ns: 'CnExport',
                            }),
                            content: $i18n.get({
                                id: 'ExportOfThisDataRequiresApproval_1240034591',
                                dm: '导出该数据需经过审批，是否提交导出数据审批流程？',
                                ns: 'CnExport',
                            }),
                            onOk: resolve,
                            okProps: {
                                children: $i18n.get({
                                    id: '31255239613091840.CNTM',
                                    dm: '提交',
                                    ns: 'CnExport',
                                }),
                            },
                            onCancel: reject2,
                        });
                    });
                    data = await runAsync({ jobName, __needAudit: true });
                    const { auditUrl } = data;
                    if (auditUrl)
                        window.open(auditUrl);
                }
                catch { }
            }
            await _onCreateSuccess(data);
        }
        catch (err) {
            if (onErrorRef.current) {
                onErrorRef.current(err);
            }
            else {
                friendlyTips({
                    component: 'CnExport',
                    message: $i18n.get({
                        id: 'FailedToCreateExportTaskTask_123840374',
                        dm: '创建导出任务任务失败',
                        ns: 'CnExport',
                    }),
                });
            }
        }
        setCreateJobLoading(false);
    }, [
        needRename,
        renameDialogPropsRef,
        runAsync,
        _onCreateSuccess,
        onErrorRef,
    ]);
    return (React.createElement("div", { "data-name": "CnExport", className: classNames(`${cssPrefix}wrapper`, className), style: style },
        React.createElement(ExportButton, { loading: createJobLoading, onClick: onClickExportBtn, buttonProps: buttonProps, buttonText: buttonText }, children),
        hideLoopDialog ? null : (React.createElement(LoopDialog, { jobId: jobId, setJobId: setJobId, autoDownload: autoDownload, visible: loopDialogVisible, setVisible: setLoopDialogVisible, pollingRequestConfig: pollingRequestConfig, pollingInterval: pollingInterval, showBgProcessBtn: showBgProcessBtn, onSuccess: onSuccess, onError: onError }))));
}
