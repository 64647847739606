import React, { useContext } from 'react';
import $i18n from 'panda-i18n';
import { Button } from '@/components/fusion';
import { DropDown2 as DropDown } from '@/components/cn-base-table/view/dep';
import { ColumnsStateCtx } from './context';
import ColumnsFixLeft from '../columns-fix-left';
import ColumnsFixRight from '../columns-fix-right';
import { ColumnItem } from '../column-item';
import { CnScrollBar } from '@/components/cn-scroll-bar';
export default function ColumnsSettingPopup() {
    const { fixLeftColumns, fixRightColumns, noFixColumns, updateColumns } = useContext(ColumnsStateCtx);
    return (React.createElement(React.Fragment, null,
        React.createElement(CnScrollBar, { className: "cn-table-column-setting-wrapper cn-table-column-setting-popup-wrapper" },
            React.createElement(ColumnsFixLeft, null),
            fixLeftColumns.length || fixRightColumns.length ? (React.createElement("div", { className: "cn-table-column-setting-wrapper-title" }, $i18n.get({ id: 'NotFixed', dm: '不固定', ns: 'CnTable' }))) : null,
            noFixColumns.length ? (React.createElement("div", { className: "cn-table-column-setting" }, noFixColumns.map((dataItem, index) => (React.createElement(ColumnItem, { position: "center", dataItem: dataItem, key: dataItem.dataIndex, index: index }))))) : null,
            React.createElement(ColumnsFixRight, null)),
        React.createElement("div", { className: "cn-table-column-set__footer" },
            React.createElement(Button, { onClick: () => DropDown.hide() }, $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnTable' })),
            React.createElement(Button, { onClick: () => {
                    DropDown.hide();
                    updateColumns();
                }, type: "primary" }, $i18n.get({ id: 'Confirmation', dm: '确认', ns: 'CnTable' })))));
}
