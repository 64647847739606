/* eslint-disable import/no-cycle */
/* eslint-disable import/no-mutable-exports */
import $i18n from 'panda-i18n';
import merge from 'lodash/merge';
import { defaultRemoteTransform } from './fetch';
// import defaultFormat from 'cn-table-cell-format';
import defaultFormat from '@/components/cn-table-cell-format';
let cacheFormat = {};
let globalConfig = {
    request: null,
    responseTransform: defaultRemoteTransform,
    defaultParamsTransform: null,
    pageSizeList: [10, 20, 50],
    sizeList: () => [
        {
            label: $i18n.get({ id: 'CompactTable', dm: '紧凑表格', ns: 'CnTable' }),
            value: 'small',
        },
        {
            label: $i18n.get({ id: 'NormalForm', dm: '正常表格', ns: 'CnTable' }),
            value: 'medium',
        },
        {
            label: $i18n.get({ id: 'LooseForm', dm: '宽松表格', ns: 'CnTable' }),
            value: 'large',
        },
    ],
    format: {},
    defaultPageSize: 10,
};
export function getCellFormat(format) {
    return {
        ...defaultFormat,
        ...cacheFormat,
        ...format,
    };
}
export function registerFormat(format) {
    cacheFormat = merge(cacheFormat, format);
}
export function config(props) {
    cacheFormat = Object.assign({}, cacheFormat, props === null || props === void 0 ? void 0 : props.format);
    globalConfig = Object.assign(globalConfig, props);
}
export default globalConfig;
