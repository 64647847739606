import $i18n from 'panda-i18n';
import React from 'react';
import { Button } from '@/components/cn-button';
import { useParentForm } from '@formily/react';
export const Reset = ({ forceClear, validate, onResetValidateFailed, onResetValidateSuccess, ...props }) => {
    const form = useParentForm();
    return (React.createElement(Button, { ...props, onClick: (e) => {
            if (props.onClick) {
                if (props.onClick(e) === false)
                    return;
            }
            form
                .reset('*', {
                forceClear,
                validate,
            })
                .then(onResetValidateSuccess)
                .catch(onResetValidateFailed);
        } }, props.children || $i18n.get({ id: 'Reset', dm: '重置', ns: 'CnForm' })));
};
