import * as React from 'react';
import { Balloon } from '@/components/fusion';
import classnames from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import './index.scss';
export function CnBalloon(props) {
    const cls = classnames('cn-balloon-style', props.className);
    return (React.createElement(Balloon, { ...props, className: cls, "data-name": "CnBalloon" }, props.children));
}
CnBalloon.displayName = 'CnBalloon';
/**
 * 请直接使用 CnTooltip
 * @deprecated
 */
CnBalloon.Tooltip = CnTooltip;
