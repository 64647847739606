/* eslint-disable @typescript-eslint/no-shadow */
import $i18n from 'panda-i18n';
import * as React from 'react';
import { useRequest } from 'ahooks';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import LRU from 'lru-cache';
import { CnTooltip } from '@/components/cn-tooltip';
import { Select as NextSelect, Loading } from '@/components/fusion';
import { packRequest as request, flattenDeepChildren, useSizeChange, } from '@/components/cn-utils';
import { CnReadOnly } from '@/components/cn-read-only';
import './index.scss';
function handleRequestService(requestConfig, remoteUrl) {
    const { service, searchKey = 'key', params: paramsProps, data: dataProps, searchFormat: searchFormatProps = (params) => params, formatParam, formatResult = (res) => {
        var _a;
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        return [];
    }, method = 'GET', } = requestConfig || {};
    if (isFunction(service)) {
        return async (searchConfig) => {
            var _a;
            return (_a = service(searchConfig)) === null || _a === void 0 ? void 0 : _a.then((data) => {
                const dataSource = formatResult(data);
                return Array.isArray(dataSource) ? dataSource : [];
            });
        };
    }
    if (remoteUrl) {
        return async (searchConfig) => {
            const searchFormat = formatParam !== null && formatParam !== void 0 ? formatParam : searchFormatProps;
            const params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                ? searchFormat({
                    ...paramsProps,
                    ...(isNil(searchConfig)
                        ? {}
                        : {
                            [searchKey]: searchConfig,
                        }),
                })
                : paramsProps;
            const data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                ? searchFormat({
                    ...dataProps,
                    ...(isNil(searchConfig)
                        ? {}
                        : {
                            [searchKey]: searchConfig,
                        }),
                })
                : dataProps;
            const resultData = await request({
                ...requestConfig,
                params,
                data,
                url: remoteUrl,
            });
            const dataSource = formatResult(resultData);
            return Array.isArray(dataSource) ? dataSource : [];
        };
    }
    return undefined;
}
function handleErrorInfo(error) {
    if (!error)
        return {};
    return {
        autoWidth: false,
        dataSource: [],
        notFoundContent: React.createElement("div", { className: "cn-async-select-error" }, "\u8BF7\u6C42\u6570\u636E\u5F02\u5E38"),
    };
}
function handleLoading(loading, data) {
    const ContentLoading = () => {
        return (React.createElement("div", { className: "content-loading" },
            React.createElement(Loading, { size: "medium" })));
    };
    const IsContentLoading = (data === null || data === void 0 ? void 0 : data.length) === 0 && loading;
    if (IsContentLoading) {
        return {
            notFoundContent: React.createElement(ContentLoading, null),
        };
    }
    if (loading) {
        return {
            state: 'loading',
        };
    }
    return {
        state: undefined,
    };
}
const maxTagPlaceholder = (selectedValues, totalValues) => {
    const trigger = (React.createElement("span", { className: "cn-next-select-tag-compact-inner" },
        $i18n.get({ id: 'Selected', dm: '已选择', ns: 'CnAsyncSelect' }),
        `${selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length}`,
        $i18n.get({
            id: 'APOLLO_X.Item.import.CNTM',
            dm: '项',
            ns: 'CnAsyncSelect',
        })));
    const labels = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.map((obj) => {
        if (typeof obj === 'object') {
            return obj.label;
        }
        return obj;
    });
    return React.createElement(CnTooltip, { trigger: trigger }, labels && labels.join(', '));
};
function onSearchClear(run, onSearchClearProps, actionType) {
    onSearchClearProps(actionType, run);
}
export const Select = React.forwardRef((props, ref) => {
    var _a;
    const baseSelectRef = React.useRef(null);
    const instanceIdRef = React.useRef(null);
    const [lru] = React.useState(new LRU({
        max: 5000,
    }));
    const trace = window.coneArmsTrace;
    const { remoteUrl: url, requestConfig, initRequestConfig, readOnly, readOnlyProps, onVisibleChange: onVisibleChangeProps, onSearchClear: onSearchClearProps = () => { }, popupContainer, $i18n, ...otherProps } = props;
    // useRequest 相关配置
    const { service, searchFormat, url: ConfighUrl, ...otherConfig } = requestConfig || {};
    const remoteUrl = ConfighUrl || url;
    const insertSelectProps = {};
    const requestService = (_a = handleRequestService(requestConfig, remoteUrl)) !== null && _a !== void 0 ? _a : function () {
        return Promise.resolve([]);
    };
    const initRequestService = handleRequestService(initRequestConfig, initRequestConfig === null || initRequestConfig === void 0 ? void 0 : initRequestConfig.url);
    const { run, mutate, error, data = [], loading, } = useRequest(requestService, {
        ready: !!(remoteUrl || service),
        ...otherConfig,
    });
    const [, forceUpdate] = React.useState(1);
    // 为了给外部正确使用 forceUpdate
    if (baseSelectRef.current) {
        baseSelectRef.current.forceUpdate = () => {
            forceUpdate((s) => (s + 1) % 32);
        };
    }
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            mutateDataSource: mutate,
            sandRequest: run,
            ...((_a = baseSelectRef === null || baseSelectRef === void 0 ? void 0 : baseSelectRef.current) !== null && _a !== void 0 ? _a : {}),
        };
    });
    const onVisibleChange = (visible, type) => {
        var _a, _b, _c;
        if (isFunction(onVisibleChangeProps)) {
            onVisibleChangeProps(visible, type, run);
        }
        try {
            if (!trace || !trace.initCustomEventReport)
                return;
            const commonReportParams = {
                c6: ((_a = otherProps === null || otherProps === void 0 ? void 0 : otherProps.dataSource) === null || _a === void 0 ? void 0 : _a.length) ||
                    ((_b = insertSelectProps === null || insertSelectProps === void 0 ? void 0 : insertSelectProps.dataSource) === null || _b === void 0 ? void 0 : _b.length),
                c7: !!(remoteUrl || service),
                c8: otherProps.mode || 'multiple',
                c9: (_c = otherProps.value) === null || _c === void 0 ? void 0 : _c.toString(),
            };
            if (!visible) {
                const newReportIns = trace.initCustomEventReport({
                    // 事务id
                    taskName: 'cn-component.cn-ui.asyncSelect',
                    autoReportStart: false,
                    // 可同时设置其他上报时需要附带的参数 c6 ~ c10
                });
                // 如果要进行跨子组件进行上报，建议传递instanceId进行事务标记，然后进行上报
                const instanceId = newReportIns.getInstanceId();
                instanceIdRef.current = instanceId;
                trace.reportCustomEventStart(instanceId, commonReportParams);
            }
            else if (instanceIdRef.current) {
                trace.reportCustomEventEnd(instanceIdRef.current, commonReportParams);
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    // React.useEffect(() => {
    // data?.forEach((item) => {
    //   lru.set(item.value, Promise.resolve(item));
    // });
    // otherProps.dataSource?.forEach((item) => {
    //   lru.set(item.value, Promise.resolve(item));
    // });
    // }, [data, otherProps.dataSource]);
    React.useEffect(() => {
        var _a;
        const { value, useDetailValue } = props;
        if (useDetailValue)
            return;
        if (!isNumber(value) && !isString(value) && !(value instanceof Array))
            return;
        const listValue = value instanceof Array ? value : [value];
        const dataMap = flattenDeepChildren(data.concat((_a = otherProps.dataSource) !== null && _a !== void 0 ? _a : [])).reduce((pre, cur) => {
            pre[cur.value] = cur;
            return pre;
        }, {});
        const newValue = listValue.filter((item) => !dataMap[item]);
        // const newValue = listValue.filter(
        //   (item) => !flattenDeepChildren(data).find((el) => el.value === item),
        // );
        if (isEmpty(newValue))
            return;
        if (!isFunction(initRequestService)) {
            newValue.forEach((item) => {
                requestService(item).then((data) => {
                    mutate((dataSource = []) => {
                        const safeDataSource = Array.isArray(dataSource)
                            ? dataSource
                            : [];
                        return [...safeDataSource, ...data];
                    });
                });
            });
        }
        else {
            initRequestService(value).then((data) => {
                mutate((dataSource) => {
                    const safeDataSource = Array.isArray(dataSource) ? dataSource : [];
                    return [...safeDataSource, ...data];
                });
            });
        }
    }, [props.value]);
    const { size, setSize } = useSizeChange(props);
    insertSelectProps.dataSource = data;
    insertSelectProps.size = size;
    const errorInfo = handleErrorInfo(error);
    const loadingInfo = handleLoading(loading, data);
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // onSearch 自动包装
    // 仅在 showSearch 且 filterLocal 为 false 情况下启用
    const enableOnSearch = otherProps.showSearch &&
        !otherProps.onSearch &&
        otherProps.filterLocal === false;
    if (enableOnSearch) {
        insertSelectProps.onSearch = (inputValue) => {
            run(inputValue);
        };
    }
    insertSelectProps.isPreview = readOnly;
    insertSelectProps.renderPreview = (values) => {
        return (React.createElement(CnReadOnly, { type: "enum", value: values, ...{
                ...pick(props, [
                    'addonBefore',
                    'innerBefore',
                    'addonTextBefore',
                    'addonTextAfter',
                    'innerAfter',
                    'addonAfter',
                ]),
                ...readOnlyProps,
            } }));
    };
    const onSearchClearCallBack = React.useMemo(() => {
        return onSearchClear.bind(null, run, onSearchClearProps);
    }, []);
    return (React.createElement(NextSelect, { "data-name": "CnAsyncSelect", ref: baseSelectRef, maxTagPlaceholder: maxTagPlaceholder, popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : ((e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || document.body), ...insertSelectProps, ...omit(otherProps, ['$i18n']), onVisibleChange: onVisibleChange, 
        // valueRender={selectedValueRender}
        onSearchClear: onSearchClearCallBack }));
});
Select.defaultProps = {
    maxTagCount: 2,
    tagInline: true,
    filterLocal: true,
    popupClassName: 'cn-async-select-popup',
};
Select.displayName = 'Select';
