import React, { useContext, useState } from 'react';
import $i18n from 'panda-i18n';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Balloon, Menu } from '@/components/fusion';
import { CnButton } from '@/components/cn-button';
import { CnTooltip } from '@/components/cn-tooltip';
import Config from './config';
import GlobalContext from './context';
import { FullScreenIcon, ColumnSetIcon, SizeIcon, ZebraIcon } from './icons';
import { DropDown2 as DropDown } from '@/components/cn-base-table/view/dep';
// import { isDef } from './util';
import { isPromise } from '@/components/cn-utils';
import { columnsSettingArmsReport, tableFullScreenArmsReport, tableDensityArmsReport, } from './arms';
import ColumnsSettingDialog from './columns-setting/dialog';
import ColumnsSettingPopup from './columns-setting/popup';
import { ColumnsSettingCtx } from './columns-setting/context';
export default function Toolbar(props) {
    const { batchArea, toolArea, settings, tableInfo } = props;
    const { showSelectedNum, total } = tableInfo || {};
    const leftList = Array.isArray(batchArea) ? batchArea : [];
    const rightList = Array.isArray(toolArea) ? toolArea : [];
    const settingList = Array.isArray(settings) ? settings : [];
    const hasToolBar = !isEmpty(settingList) || !isEmpty(leftList) || !isEmpty(rightList);
    const showTotalInfo = hasToolBar && (showSelectedNum || !isNil(total));
    function sortSetting(a, b) {
        const settingsValue = { size: 0, zebra: 1, fullscreen: 2, columnSet: 3 };
        const valueA = (a === null || a === void 0 ? void 0 : a.type) ? settingsValue[a.type] : settingsValue[a];
        const valueB = (b === null || b === void 0 ? void 0 : b.type) ? settingsValue[b.type] : settingsValue[b];
        return valueA - valueB;
    }
    return (React.createElement("div", { className: "cn-table-toolbar" },
        React.createElement("div", { className: "cn-table-toolbar-left" },
            showTotalInfo ? React.createElement(TotalInfo, { tableInfo: tableInfo }) : null,
            leftList.map((item, index) => (React.createElement(RenderNormalItem, { item: item, opts: tableInfo, index: index })))),
        React.createElement("div", { className: "cn-table-toolbar-right" },
            rightList.map((item, index) => (React.createElement(RenderNormalItem, { item: item, opts: tableInfo, index: index }))),
            (settingList === null || settingList === void 0 ? void 0 : settingList.length) > 0 ? (React.createElement("div", { className: "cn-table-toolbar-settings" }, settingList
                .sort(sortSetting)
                .map((item, index) => renderSettings(item, tableInfo, index)))) : null)));
}
function TotalInfo(props) {
    var _a;
    const { tableInfo } = props;
    const { showSelectedNum, selectKeys, total, totalSlot } = tableInfo || {};
    if (totalSlot) {
        return totalSlot;
    }
    return (React.createElement("span", { className: "cn-table-toolbar-left__select" },
        showSelectedNum ? (React.createElement(React.Fragment, null,
            $i18n.get({ id: 'Select', dm: '选中', ns: 'CnTable' }),
            React.createElement("span", { className: "cn-table-toolbar-highlight" }, (_a = selectKeys === null || selectKeys === void 0 ? void 0 : selectKeys.length) !== null && _a !== void 0 ? _a : 0),
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnTable',
            }))) : null,
        showSelectedNum && !isNil(total) ? (React.createElement("span", { className: "cn-table-toolbar-highlight" }, "/")) : null,
        !isNil(total) ? (React.createElement(React.Fragment, null,
            $i18n.get({ id: 'Total', dm: '共', ns: 'CnTable' }),
            React.createElement("span", { className: "cn-table-toolbar-all" }, total),
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnTable',
            }))) : null));
}
function useRenderButtonLoading(item) {
    var _a;
    const [loading, setLoading] = useState(false);
    if (!item) {
        return {
            ButtonOnClick: () => { },
            loading: false,
        };
    }
    if (((_a = item === null || item === void 0 ? void 0 : item.buttonProps) === null || _a === void 0 ? void 0 : _a.loading) && isFunction(item === null || item === void 0 ? void 0 : item.onClick)) {
        const wrapOnClick = new Proxy(item === null || item === void 0 ? void 0 : item.onClick, {
            apply(arget, object, args) {
                setLoading(true);
                const result = arget(...args);
                if (isPromise(result)) {
                    result.finally(() => {
                        setLoading(false);
                    });
                }
                else {
                    setLoading(false);
                }
                return result;
            },
        });
        return {
            ButtonOnClick: wrapOnClick,
            loading,
        };
    }
    if (isFunction(item === null || item === void 0 ? void 0 : item.onClick)) {
        return {
            ButtonOnClick: item.onClick,
            loading: false,
        };
    }
    return {
        ButtonOnClick: () => { },
        loading: false,
    };
}
function RenderNormalItem({ item, opts, index, }) {
    var _a, _b, _c, _d;
    const { selectKeys, storeSelectInfo, globalSize } = opts || {};
    const { tableProps } = useContext(GlobalContext);
    const { loading, ButtonOnClick } = useRenderButtonLoading(item);
    if (!item)
        return null;
    if ((item === null || item === void 0 ? void 0 : item.$$typeof) === Symbol.for('react.element')) {
        return React.cloneElement(item, {
            ...((item === null || item === void 0 ? void 0 : item.props) || {}),
            ...(isFunction(item === null || item === void 0 ? void 0 : item.type)
                ? {
                    selectKeys,
                    selectRecords: (_a = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _a === void 0 ? void 0 : _a[1],
                }
                : {}),
        });
    }
    if (typeof item === 'function') {
        return item(selectKeys, (_b = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _b === void 0 ? void 0 : _b[1], storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current);
    }
    if (typeof item.visible === 'function' &&
        !item.visible(selectKeys, (_c = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _c === void 0 ? void 0 : _c[1], storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current)) {
        return null;
    }
    return (React.createElement(CnButton, { size: globalSize, 
        // size={'small'}
        key: index, type: item === null || item === void 0 ? void 0 : item.type, onClick: (event) => {
            var _a;
            ButtonOnClick(event, selectKeys, (_a = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _a === void 0 ? void 0 : _a[1], storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current);
        }, 
        // loading=
        disabled: item && isFunction(item.isDisabled)
            ? item.isDisabled(selectKeys, (_d = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _d === void 0 ? void 0 : _d[1], storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current)
            : false, ...((item === null || item === void 0 ? void 0 : item.buttonProps) || {}), loading: loading }, item === null || item === void 0 ? void 0 : item.children));
}
function renderSettings(item, opts, index) {
    if (item === 'size' || (item === null || item === void 0 ? void 0 : item.type) === 'size') {
        return React.createElement(RenderSize, { key: index, setSize: opts.setSize, size: opts.size });
    }
    if (item === 'fullscreen' || (item === null || item === void 0 ? void 0 : item.type) === 'fullscreen') {
        return (React.createElement(RenderFullScreenButton, { isFullScreen: opts === null || opts === void 0 ? void 0 : opts.fullScreen, setFullScreen: opts.setFullScreen, index: index })); // RenderFullScreenButton(opts?.fullScreen, opts?.setFullScreen, index);
    }
    if (item === 'columnSet' || (item === null || item === void 0 ? void 0 : item.type) === 'columnSet') {
        return (React.createElement(RenderColumnsSet, { key: index, columns: opts.columns, setColumns: opts.setColumns, zIndex: (item === null || item === void 0 ? void 0 : item.zIndex) || 999 }));
    }
    if (item === 'zebra' || (item === null || item === void 0 ? void 0 : item.type) === 'zebra') {
        return (React.createElement(ZebraSetting, { key: index, zebra: opts.zebra, setZebra: opts.setZebra }));
    }
}
function ZebraSetting(props) {
    const { zebra, setZebra } = props;
    return (React.createElement(CnTooltip, { popupStyle: { wordBreak: 'keep-all' }, trigger: React.createElement("div", { className: "cn-table-toolbar-settings-item", onClick: () => setZebra(!zebra) },
            React.createElement(ZebraIcon, { zebra: zebra, size: 15 })) }, $i18n.get({ id: 'ZebraCrossing', dm: '斑马线', ns: 'CnTable' })));
}
function RenderColumnsSet(props) {
    const { columns, setColumns, zIndex } = props;
    const [columnSettingDialogVisible, setColumnSettingDialogVisible] = useState(false);
    const onShow = (event) => {
        var _a;
        if (!(columns === null || columns === void 0 ? void 0 : columns.length))
            return;
        const toolbarDom = getParent(event.target, (el) => el.className === 'cn-table-toolbar-settings');
        const customProps = { style: { left: 0 } };
        const rect = (_a = toolbarDom === null || toolbarDom === void 0 ? void 0 : toolbarDom.getBoundingClientRect) === null || _a === void 0 ? void 0 : _a.call(toolbarDom);
        if (rect) {
            customProps.style.left = rect.left + rect.width;
        }
        if (columns.length > 15) {
            setColumnSettingDialogVisible(true);
        }
        else {
            DropDown.show(event, React.createElement(ColumnsSettingCtx, { columns: columns, setColumns: setColumns },
                React.createElement(ColumnsSettingPopup, null)), {
                accordTarget: false,
                hasMask: true,
                Container: PopupContainer,
                closeOnClick: true,
                target: toolbarDom,
                zIndex,
                props: customProps,
                transform({ isTopHalf }) {
                    return `translate(-100%, ${isTopHalf ? 0 : '-100'}%)`;
                },
            });
        }
        columnsSettingArmsReport();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CnTooltip, { popupStyle: { wordBreak: 'keep-all' }, trigger: React.createElement("div", { className: "cn-table-toolbar-settings-item", onClick: onShow },
                React.createElement(ColumnSetIcon, { activate: columnSettingDialogVisible, color: columnSettingDialogVisible ? '#2f6cf7' : 'unset' })) }, $i18n.get({ id: 'ColumnSettings', dm: '列设置', ns: 'CnTable' })),
        React.createElement(ColumnsSettingCtx, { isDialog: true, columns: columns, setColumns: setColumns },
            React.createElement(ColumnsSettingDialog, { visible: columnSettingDialogVisible, setVisible: setColumnSettingDialogVisible }))));
}
function RenderFullScreenButton({ isFullScreen, setFullScreen, index, }) {
    React.useEffect(() => {
        function handleCancelFullScreen(event) {
            if (event.keyCode === 27) {
                setFullScreen(false);
            }
        }
        window.addEventListener('keydown', handleCancelFullScreen);
        return () => {
            window.removeEventListener('keydown', handleCancelFullScreen);
        };
    }, []);
    return (React.createElement(CnTooltip, { popupStyle: { wordBreak: 'keep-all' }, trigger: React.createElement("div", { className: "cn-table-toolbar-settings-item", key: index, onClick: () => {
                setFullScreen(!isFullScreen);
                tableFullScreenArmsReport();
            } },
            React.createElement(FullScreenIcon, { isFullScreen: isFullScreen })) }, isFullScreen
        ? $i18n.get({ id: 'CancelFullScreen', dm: '取消全屏', ns: 'CnTable' })
        : $i18n.get({ id: 'FullScreen', dm: '全屏', ns: 'CnTable' })));
}
function RenderSize(props) {
    const { size, setSize } = props;
    const [visible, setVisible] = React.useState(false);
    const clickHandler = React.useCallback((val) => {
        setVisible(false);
        setSize(val === null || val === void 0 ? void 0 : val[0]);
        tableDensityArmsReport();
    }, [setSize]);
    return (React.createElement(Balloon, { style: { padding: 0 }, trigger: React.createElement(CnTooltip, { popupStyle: { wordBreak: 'keep-all' }, trigger: React.createElement("div", { className: "cn-table-toolbar-settings-item", onClick: () => setVisible(true), style: { height: 'auto' } },
                React.createElement(SizeIcon, null)) }, $i18n.get({ id: 'Dimensions', dm: '尺寸', ns: 'CnTable' })), visible: visible, onVisibleChange: setVisible, triggerType: "click", align: "bl", closable: false },
        React.createElement(Menu, { selectMode: "single", selectedKeys: size, onSelect: clickHandler, style: {
                border: 0,
            } }, ((Config === null || Config === void 0 ? void 0 : Config.sizeList()) || []).map((sizeOption) => (React.createElement(Menu.Item, { key: sizeOption.value }, sizeOption.label))))));
}
export function PopupContainer(props) {
    const { children, className, ...rest } = props;
    return (React.createElement("div", { className: 'cn-table-pop-container', ...rest }, children));
}
export function getParent(dom, match, maxLevel = 5) {
    if (dom && isFunction(match) && maxLevel > 0) {
        let level = 0;
        let nowDom = dom;
        while (level < maxLevel) {
            nowDom = nowDom === null || nowDom === void 0 ? void 0 : nowDom.parentElement;
            if (match(nowDom)) {
                return nowDom;
            }
            level++;
        }
    }
    return dom;
}
