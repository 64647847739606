import $i18n from 'panda-i18n';
import React, { useContext } from 'react';
import { ColumnItem } from '../column-item';
import { rightFixMaxNum } from '@/components/cn-table/const';
import { ColumnsStateCtx } from '../columns-setting/context';
export default function ColumnsFixRight() {
    const { isDialog, fixRightColumns } = useContext(ColumnsStateCtx);
    return (React.createElement(React.Fragment, null,
        isDialog || fixRightColumns.length ? (React.createElement("div", { className: "cn-table-column-setting-wrapper-title" }, $i18n.get({
            id: 'FixedOnTheRightDatasourcelanglerightFixMaxNum',
            dm: '固定在右侧({dataSourceLength}/{rightFixMaxNum})',
            ns: 'CnTable',
        }, {
            dataSourceLength: fixRightColumns.length,
            rightFixMaxNum,
        }))) : null,
        isDialog || fixRightColumns.length ? (React.createElement("div", { className: "cn-table-column-setting" }, fixRightColumns.map((dataItem, index) => (React.createElement(ColumnItem, { key: dataItem.dataIndex, dataItem: dataItem, index: index, position: "right" }))))) : null));
}
