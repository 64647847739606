import React, { useState, useEffect } from 'react';
import isFunction from 'lodash/isFunction';
import isEqualeq from 'lodash/isEqual';
import { MenuContainer, SubMenu, MenuItem } from './menu';
import './header-menu.scss';
export const HeaderMenu = (props) => {
    const { menu, onSelect, defaultSelectedKeys: defaultSelectedKeysProps, selectedKeys: selectedKeysProps, triggerType, } = props;
    const [selectedKeys, setSelectedKeys] = useState(selectedKeysProps || defaultSelectedKeysProps);
    useEffect(() => {
        if (isEqualeq(selectedKeys, selectedKeysProps))
            return;
        setSelectedKeys(selectedKeysProps);
    }, [selectedKeysProps]);
    const handleSelect = (selectedKeys, ...other) => {
        var _a;
        if (isFunction(onSelect))
            onSelect(selectedKeys, ...other);
        if (!('selectedKeys' in props)) {
            setSelectedKeys(selectedKeys);
        }
        (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
            name: 'CnShell页头导航菜单点击',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-shell.headerMenuSelect',
        });
    };
    const handleMenuOpen = (selectedKeys) => {
        var _a;
        if (selectedKeys.length > 0) {
            (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
                name: 'CnShell页头导航菜单展开全部',
                eventType: 'CLK',
                hottag: 'cn-ui.cn-shell.headerMenuOpen',
            });
        }
    };
    return (React.createElement(MenuContainer, { hozInLine: true, direction: "hoz", mode: "popup", className: "cn-ui-shell-header-menu", popupClassName: "cn-ui-shell-header-menu-popup", popupAutoWidth: true, triggerType: triggerType, selectMode: "single", selectedKeys: selectedKeys, onSelect: handleSelect, onOpen: handleMenuOpen }, renderMenu(menu)));
};
function renderMenu(menu = []) {
    if (!Array.isArray(menu))
        return [];
    return menu.map((item) => {
        var _a;
        return ((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement(SubMenu, { key: item.key, label: item.text }, renderMenu(item.children))) : (React.createElement(MenuItem, { key: item.key, path: item.path },
            React.createElement("a", { ...item, href: item.path, onClick: (e) => {
                    e.preventDefault();
                } }, item.text)));
    });
}
