import $i18n from 'panda-i18n';
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import useJobsPolling from '@/components/cn-async-jobs-pro/hooks/use-jobs-polling';
import { events, downloadFile } from '@/components/cn-utils';
import { CnButton } from '@/components/cn-button';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { CnResult } from '@/components/cn-result';
import { CnMessage } from '@/components/cn-message';
import { cssPrefix, MARK_UNREAD } from '../../constant';
import JobItem from '../job-item';
import JobMessage from '../job-message';
import useStartPolling from './hooks/use-start-polling';
import useLauncherLoading from './hooks/use-launcher-loading';
import './index.scss';
export default function JobPanel(props) {
    const { selected, visible, type, pollingRequestConfig: pollingRequestConfigProps, onError, onSuccess, className, style: _style = {}, autoDownload, viewMore, pollingService, } = props;
    const pollingRequestConfig = pollingRequestConfigProps || pollingService;
    const { loading, jobResult, startTaskLoop, hasUnreadJob, processedJobs } = useJobsPolling({ pollingRequestConfig, onError, onSuccess });
    useEffect(() => {
        if (visible)
            return;
        if (hasUnreadJob)
            events.emit(MARK_UNREAD);
    }, [visible, hasUnreadJob]);
    useStartPolling({ visible, type, startTaskLoop });
    useLauncherLoading({ type, loading });
    useEffect(() => {
        for (const job of processedJobs) {
            const { status, downloadUrl, jobName } = job;
            const config = {
                content: React.createElement(JobMessage, { type: type, data: job }),
                duration: 3000,
            };
            if (status === 'FAILED') {
                CnMessage.error(config);
            }
            else if (status === 'SOME') {
                if (autoDownload)
                    downloadFile(downloadUrl, jobName);
                CnMessage.warning(config);
            }
            else if (status === 'SUCCEEDED') {
                if (autoDownload)
                    downloadFile(downloadUrl, jobName);
                CnMessage.success(config);
            }
        }
    }, [autoDownload, processedJobs, type]);
    const style = useMemo(() => {
        const temp = {
            ..._style,
            height: viewMore ? 'calc(100% - 56px)' : '100%',
        };
        if (!selected)
            temp.display = 'none';
        return temp;
    }, [viewMore, selected, _style]);
    if (!jobResult.length) {
        return (React.createElement(CnResult, { type: "empty", style: style, title: $i18n.get({
                id: 'NoTaskYet',
                dm: '暂无任务',
                ns: 'CnAsyncJobsPro',
            }) }));
    }
    return (React.createElement("div", { className: classNames(`${cssPrefix}panel`, className), style: style },
        React.createElement(CnScrollBar, null, jobResult.map((data, i) => (React.createElement(JobItem, { className: classNames({ [`${cssPrefix}item-first`]: i === 0 }), key: data.jobId, type: type, data: data })))),
        viewMore ? (React.createElement("div", { className: classNames(`${cssPrefix}panel-view-more`) },
            React.createElement(CnButton, { onClick: viewMore.onClick, text: true }, viewMore.text ||
                $i18n.get({
                    id: 'SeeMore',
                    dm: '查看更多',
                    ns: 'CnAsyncJobsPro',
                })))) : null));
}
