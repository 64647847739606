import * as React from 'react';
import cx from 'classnames';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import { CnIcon } from '@/components/cn-icon';
export const AnchorLink = (props) => {
    const { label, htmlId, isFocus, level, icon, onSelect, padding = 0 } = props;
    const cls = cx({
        'cn-ui-anchor-item': true,
        // [`cn-ui-anchor-item-level-${level}`]: true,
        'cn-ui-anchor-item-active': !!isFocus,
    });
    return (React.createElement("div", { key: htmlId, className: cls, onClick: () => onSelect({
            label,
            htmlId,
            isFocus,
            level,
            icon,
            padding,
        }), style: {
            paddingLeft: 4 + 8 * level + padding,
        } },
        icon && (React.createElement("div", { className: "cn-ui-anchor-item-icon-wrapper" },
            React.createElement(CnIcon, { type: icon, className: "cn-ui-anchor-item-icon" }))),
        React.createElement(CnTextEllipsis, { line: 1 }, label)));
};
