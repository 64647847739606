import * as React from 'react';
import { findDOMNode } from 'react-dom';
import numbro from 'numbro';
import classnames from 'classnames';
import { NumberPicker } from '@/components/fusion';
import { CnCurrencySelect } from '@/components/cn-currency-select';
import { dom } from '@fusion/lib/util';
import currencyMap from './currency-map';
import { handleFormat, formatInputValue } from './util';
import { CnCurrencyAmountNumberPickerReadOnly } from './amount-select-read-only';
import './index.scss';
import { CnTooltip } from '../cn-tooltip';
export class CnCurrencyAmountNumberPicker extends React.Component {
    constructor(props) {
        var _a, _b, _c, _d;
        super(props);
        this.onCurrencySelectVisibleChange = (newVisibleStatus) => {
            if (newVisibleStatus) {
                this.syncWidth();
            }
            this.setState({
                currencySelectVisible: newVisibleStatus,
            });
        };
        this.onNumberPickerFocusChange = (newFocusState) => {
            this.setState({
                isNumberPickerFocus: newFocusState,
            });
        };
        this.saveAmountSelectRef = (ref) => {
            // eslint-disable-next-line react/no-find-dom-node
            this.amountSelectDOM = findDOMNode(ref);
        };
        /**
         * 获取当前精度。默认为2，若没有，则使用内置精度规范。
         * currency: 当前货币类型
         * dataSource: 货币数据源
         */
        this.getPrecision = (currency) => {
            let tmpPrecision;
            if ('precision' in this.props) {
                tmpPrecision = this.props.precision;
            }
            // 若命中默认币种精度，则使用币种精度
            if (currency && currencyMap[currency]) {
                tmpPrecision = currencyMap[currency].precision;
            }
            return tmpPrecision;
        };
        // 货币类型更改
        this.handleCurrencyChange = (value, actionType, mixed) => {
            const { onChange } = this.props;
            const { amount, currency } = this.state;
            // 货币种类更改，对应的数据金额也需要更新
            const finalAmount = this.handleRenderValue(amount, value);
            onChange &&
                onChange({
                    currency: value,
                    amount: finalAmount,
                    preAmount: amount,
                    preCurrency: currency,
                }, actionType, mixed);
            this.setState({
                currency: value,
                amount: finalAmount,
            });
        };
        this.handleAmountChange = (val) => {
            const { onChange } = this.props;
            const { currency } = this.state;
            // 业务规定最多只能输入16位
            onChange &&
                onChange({
                    amount: val,
                    currency,
                }, 'input', {});
            this.setState({
                amount: val,
            });
        };
        /**
         * 将原始值根据规则转换为目标值，不是格式化的数据
         *
         * amount 输入值 123.456
         * tmpCurrency当前货币类型,不传入则使用state的类型 例如 'CNY'
         * dataSource 货币数据源
         * return  123.46
         */
        this.handleRenderValue = (amount, tmpCurrency) => {
            // 当为异步赋值时，货币类型初始化不存在，需要进行兼容
            if (!tmpCurrency) {
                return amount;
            }
            const { onChange, format } = this.props;
            const { currency } = this.state || {};
            const precision = this.getPrecision(tmpCurrency);
            const val = handleFormat(amount, precision, format);
            const finalValue = numbro.unformat(val);
            const finalRenderValue = `${finalValue}` === 'null' ? undefined : finalValue;
            // 当input发生改变时触发onChange事件
            if (amount !== finalRenderValue && onChange) {
                onChange({
                    amount: finalRenderValue,
                    currency: tmpCurrency || currency,
                    preAmount: amount,
                }, 'input', {});
            }
            return finalRenderValue;
        };
        this.renderInput = (inputEl) => {
            var _a;
            const { size, i18nBundle, hasUnitTooltip, unitTooltipProps } = this.props;
            const { amount, isNumberPickerFocus } = this.state;
            if (hasUnitTooltip) {
                const numberFontSizeMap = {
                    small: {
                        origin: 10,
                        fontSize: 12 / 2,
                    },
                    medium: {
                        origin: 10,
                        fontSize: 12 / 2,
                    },
                    large: {
                        origin: 6,
                        fontSize: 14 / 2,
                    },
                };
                const offsetWidth = numberFontSizeMap[size].origin +
                    (((_a = String(amount)) === null || _a === void 0 ? void 0 : _a.length) || 0) * numberFontSizeMap[size].fontSize;
                return (React.createElement(CnTooltip, { followTrigger: true, trigger: inputEl, triggerType: [], className: "cn-ui-currency-amount-number-picker-unit-tooltip", popupProps: {
                        offset: [-offsetWidth, 0],
                        onVisibleChange: this.onNumberPickerFocusChange,
                        // visible: true,
                        visible: amount && amount >= 1000 && isNumberPickerFocus,
                        align: 'tl br',
                        animation: false,
                    }, ...unitTooltipProps }, formatInputValue(amount, i18nBundle.currentLanguage)));
            }
            return inputEl;
        };
        let amount;
        let currency;
        if ('value' in props) {
            amount = (_a = props.value) === null || _a === void 0 ? void 0 : _a.amount;
            currency = (_b = props.value) === null || _b === void 0 ? void 0 : _b.currency;
        }
        else {
            amount = (_c = props.defaultValue) === null || _c === void 0 ? void 0 : _c.amount;
            currency = (_d = props.defaultValue) === null || _d === void 0 ? void 0 : _d.currency;
        }
        this.state = {
            amount,
            currency,
            currencySelectVisible: false,
            popupWidth: 0,
            isNumberPickerFocus: false,
        };
    }
    componentDidMount() {
        // overlay 还没有完成 mount，所以需要滞后同步宽度
        setTimeout(() => this.syncWidth(), 0);
    }
    /**
     * Calculate and set width of popup menu
     * @protected
     */
    syncWidth() {
        const { popupWidth } = this.state;
        const { selectProps } = this.props;
        if ((selectProps === null || selectProps === void 0 ? void 0 : selectProps.popupStyle) && 'width' in selectProps.popupStyle) {
            return;
        }
        const width = dom.getStyle(this.amountSelectDOM, 'width');
        if (width && popupWidth !== width) {
            this.setState({
                popupWidth: width,
            });
        }
    }
    render() {
        const { size, className, style, disabled, hasCurrencySelect, hasHighlight, placeholder, i18nBundle, readOnly, readOnlyRender, format, numberPickerRender, selectProps, state, 
        // render 内未调用
        hasUnitTooltip, unitTooltipProps, value, onChange, currencyDataSource, onFocus, onBlur, ...numberPickerProps } = this.props;
        const { amount, currency, currencySelectVisible, popupWidth } = this.state;
        const i18nPlaceholder = placeholder || i18nBundle.placeholder;
        const precision = this.getPrecision(currency);
        const cls = classnames({
            'cn-ui-currency-amount-number-picker': true,
            'cn-ui-currency-amount-number-picker-with-currency': hasCurrencySelect,
            'amount-light': !!hasHighlight && !disabled,
            [className]: className,
        });
        if (readOnly) {
            if (readOnlyRender) {
                return readOnlyRender(this.props);
            }
            return (React.createElement(CnCurrencyAmountNumberPickerReadOnly, { amount: amount, currency: currency, precision: precision, format: format, hasHighlight: hasHighlight, className: className }));
        }
        let numberPickerEl = (React.createElement(NumberPicker, { className: "cn-ui-currency-amount-number-picker-number-picker", value: amount, format: (val) => handleFormat(val, precision, format), onChange: (val) => this.handleAmountChange(val), precision: precision, disabled: disabled, placeholder: i18nPlaceholder, inputRender: this.renderInput, onFocus: (e) => {
                this.onNumberPickerFocusChange(true);
                onFocus && onFocus(e);
            }, onBlur: (e) => {
                this.onNumberPickerFocusChange(false);
                onBlur && onBlur(e);
            }, size: size, maxLength: 16, state: state, ...numberPickerProps }));
        if (numberPickerRender) {
            numberPickerEl = numberPickerRender(numberPickerEl);
        }
        return (React.createElement("div", { "data-name": "CnCurrencyAmountNumberPicker", ref: this.saveAmountSelectRef, className: cls, style: style, key: "main-span" },
            hasCurrencySelect && (React.createElement(CnCurrencySelect, { className: "cn-ui-currency-amount-number-picker-currency-select", dataSource: currencyDataSource, value: currency, showSearch: true, onChange: (newValue, actionType, mixed) => this.handleCurrencyChange(newValue, actionType, mixed), placeholder: i18nBundle.currencyPlaceholder, disabled: disabled, hasClear: false, autoWidth: false, visible: currencySelectVisible, onVisibleChange: this.onCurrencySelectVisibleChange, popupStyle: { width: popupWidth }, popupProps: { align: 'tl bl' }, popupClassName: "cn-ui-currency-amount-number-picker-currency-popup", size: size, onFocus: onFocus, onBlur: onBlur, state: state, ...selectProps })),
            numberPickerEl));
    }
}
CnCurrencyAmountNumberPicker.displayName = 'CnCurrencyAmountNumberPicker';
CnCurrencyAmountNumberPicker.defaultProps = {
    hasCurrencySelect: true,
    hasHighlight: true,
    precision: 2,
    size: 'medium',
    hasUnitTooltip: true,
    i18nBundle: {},
};
CnCurrencyAmountNumberPicker.getDerivedStateFromProps = (nextProps, prevState) => {
    var _a, _b, _c, _d;
    const { currency, amount } = prevState;
    const changeState = {};
    if ('value' in nextProps && currency !== ((_a = nextProps.value) === null || _a === void 0 ? void 0 : _a.currency)) {
        changeState.currency = (_b = nextProps.value) === null || _b === void 0 ? void 0 : _b.currency;
    }
    if ('value' in nextProps && amount !== ((_c = nextProps.value) === null || _c === void 0 ? void 0 : _c.amount)) {
        changeState.amount = (_d = nextProps.value) === null || _d === void 0 ? void 0 : _d.amount;
    }
    return changeState;
};
