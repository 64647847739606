/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo, useEffect, useRef, useCallback, } from 'react';
import useDebounceFn from 'ahooks/lib/useDebounceFn';
import useMount from 'ahooks/lib/useMount';
import useUpdate from 'ahooks/lib/useUpdate';
// import usePersistFn from 'ahooks/lib/usePersistFn';
function usePersistFn(fn) {
    const fnRef = useRef(fn);
    fnRef.current = fn;
    const persistFn = useRef();
    if (!persistFn.current) {
        persistFn.current = function () {
            const args = [];
            for (let _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return fnRef.current.apply(this, args);
        };
    }
    return persistFn.current;
}
export function useWillUpdate(handler, depedencies) {
    const didMount = useRef(false);
    useMemo(() => {
        if (didMount.current) {
            handler instanceof Function && handler();
        }
    }, depedencies);
    useEffect(() => {
        didMount.current = true;
    }, [didMount]);
}
/**
 * 屏幕刷新的时候重新获取表格dom宽度, 并提供表格正确的ref
 * @param ref | React.createRef
 * @returns
 */
export function useTableWidthCalWhenScreenResize(tableSourceRef) {
    const update = useUpdate();
    const tableWidth = useRef();
    const totalWidthCalByColumnRef = useRef();
    const newRef = useRef();
    const midRef = tableSourceRef || newRef;
    const calTableWidth = useCallback(() => {
        var _a, _b;
        const tableWrapper = (_b = (_a = midRef === null || midRef === void 0 ? void 0 : midRef.current) === null || _a === void 0 ? void 0 : _a.artTableWrapperRef) === null || _b === void 0 ? void 0 : _b.current;
        const tableContainerWidth = tableWrapper === null || tableWrapper === void 0 ? void 0 : tableWrapper.clientWidth;
        if (tableContainerWidth > 0) {
            tableWidth.current = tableContainerWidth;
            const totalWidthCalByColumn = totalWidthCalByColumnRef.current;
            if (totalWidthCalByColumn > 0 &&
                totalWidthCalByColumn < tableContainerWidth) {
                update();
            }
        }
    }, []);
    useEffect(() => {
        calTableWidth();
    }, []);
    const { run } = useDebounceFn(() => {
        calTableWidth();
    }, {
        wait: 100,
    });
    // if (isFunction(ResizeObserver)) {
    //   const resizeObserver = new ResizeObserver((entries) => {
    //     // entries.wid
    //     if (tableWidth.current !== entries?.[0]?.contentRect?.width) {
    //       run();
    //     }
    //   });
    //   if (midRef?.current?.artTableWrapperRef?.current) {
    //     resizeObserver.observe(midRef?.current?.artTableWrapperRef?.current);
    //   }
    // }
    // useEffect(() => {
    //   run();
    // }, [midRef?.current?.artTableWrapperRef?.current?.clientWidth]);
    useMount(() => {
        window.onresize = () => {
            run();
        };
    });
    return {
        midRef,
        totalWidthCalByColumnRef,
        tableWidth,
    };
}
export function useControllableValue(props, opt) {
    props = props || {};
    const { defaultValue: fallbackDefaultValue, valuePropName = 'value', defaultValuePropName = 'defaultValue', triggerName = 'onChange', } = opt;
    const value = props[valuePropName];
    const defaultValue = props[defaultValuePropName];
    const initialValue = useMemo(() => {
        var _a;
        return (_a = value !== null && value !== void 0 ? value : defaultValue) !== null && _a !== void 0 ? _a : fallbackDefaultValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const forceUpdate = useUpdate();
    const valueRef = useRef(initialValue);
    const setState = usePersistFn((val, ...rest) => {
        const onChange = props[triggerName];
        if (!(valuePropName in props)) {
            valueRef.current = val;
            forceUpdate();
        }
        onChange instanceof Function && onChange(val, ...rest);
    });
    useWillUpdate(() => {
        valueRef.current = value;
    }, [value]);
    return [valueRef.current, setState];
}
