/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-types */
import { useState } from 'react';
import { useControllableValue } from '../../view/hooks';
import SortHeaderCell from '../dep/SortHeaderCell';
import { isFunction } from '../utils';
import sortOp from './sort';
export const useSort = (sortCustom) => {
    const [sorts, setSorts] = useControllableValue(sortCustom, {
        valuePropName: 'sorts',
        // triggerName: 'onChangeSorts',
    });
    const [keepDataSource, setKeepDataSource] = useState(false);
    return {
        sorts,
        keepDataSource,
        setSorts,
        setKeepDataSource,
    };
};
const defaultOrders = ['asc', 'desc', 'none'];
export default function columnsSortPipeline(onSort, 
// @ts-ignore
sortStatus, sortCustom) {
    let options = {
        SortHeaderCell,
        ...(sortCustom || {}),
    };
    if (isFunction(onSort)) {
        options = {
            mode: 'single',
            orders: defaultOrders,
            highlightColumnWhenActive: false,
            keepDataSource: sortStatus.keepDataSource,
            SortHeaderCell,
            ...(sortCustom || {}),
            // @ts-ignore
            sorts: sortStatus.sorts,
            onChangeSorts(_sorts) {
                var _a;
                const [sort] = _sorts;
                const ret = (_a = onSort(sort === null || sort === void 0 ? void 0 : sort.code, sort === null || sort === void 0 ? void 0 : sort.order, sort)) !== null && _a !== void 0 ? _a : true;
                sortStatus.setKeepDataSource(!ret);
                sortStatus.setSorts(_sorts);
            },
        };
    }
    // @ts-ignore
    return sortOp(options);
}
