import React, { useEffect, useState } from 'react';
import { Menu, Dropdown } from '@/components/fusion';
import { useI18n, defaultLangList, langList as allLangList, pandaEvent, } from 'panda-i18n';
import keyBy from 'lodash/keyBy';
import './index.scss';
const allLangMap = keyBy(allLangList, 'value');
export const CnI18nSelect = ({ langList, lang, triggerType = 'hover', beforeChange, onChange, }) => {
    var _a;
    const $i18n = useI18n();
    const [currentLang, setCurrentLang] = useState(lang || $i18n.getLang());
    const usedLangList = (langList === null || langList === void 0 ? void 0 : langList.map((_lang) => allLangMap[_lang] || {
        value: _lang,
        label: _lang,
    })) || defaultLangList;
    // lister lang changed then change the current lang
    useEffect(() => {
        const changeLangHandler = pandaEvent.on('CHANGE_LANG', (_lang) => {
            if (!lang) {
                setCurrentLang(_lang);
            }
        });
        return () => pandaEvent.off(changeLangHandler);
    }, []);
    useEffect(() => {
        lang && setCurrentLang(lang);
    }, [lang]);
    return (React.createElement(Dropdown, { trigger: React.createElement("span", { className: "cn-i18n-select", "aria-haspopup": "true" }, ((_a = allLangMap[currentLang]) === null || _a === void 0 ? void 0 : _a.alias) ||
            currentLang.substring(0, 1).toUpperCase() +
                currentLang.substring(1, 2).toLowerCase()), offset: [0, 20], triggerType: triggerType },
        React.createElement(Menu, { className: "cn-i18n-select-menu", selectedKeys: $i18n.getLang() }, usedLangList.map((langObj) => {
            return (React.createElement(Menu.Item, { onClick: async () => {
                    if (beforeChange && typeof beforeChange === 'function') {
                        try {
                            const result = await beforeChange(langObj.value);
                            if (result === false) {
                                return;
                            }
                        }
                        catch (err) {
                            return;
                        }
                    }
                    $i18n.setLang(langObj.value);
                    if (onChange) {
                        onChange(langObj.value);
                    }
                    else {
                        location.reload();
                    }
                }, key: langObj.value }, langObj.label));
        }))));
};
export default CnI18nSelect;
