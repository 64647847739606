import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown, Menu, Button } from '@/components/fusion';
import { CnIcon } from '@/components/cn-icon';
const OptBtns = (props) => {
    const { maxShowNum = 1, dataSource = [], size } = props;
    const visibleBtns = useMemo(() => {
        return dataSource.slice(0, maxShowNum - 1);
    }, [dataSource, maxShowNum]);
    const moreBtns = useMemo(() => {
        return dataSource.slice(maxShowNum - 1, dataSource.length);
    }, [dataSource, maxShowNum]);
    const renderMoreOpts = (btns) => {
        return (React.createElement(Dropdown, { v2: true, triggerType: "click", 
            // closable={false}
            // popupClassName='cn-ui-filter-btns-dropdown'
            // popupContainer={() => document.querySelector('.c-content-container')}
            // popupStyle={{ width: 150 }}
            trigger: React.createElement(Button, { iconSize: "medium", size: size, className: classNames('cn-ui-filter-icon-btn', `cn-ui-filter-icon-btn-${size}`) },
                React.createElement(CnIcon, { type: "ellipsis-more" })), align: "br" },
            React.createElement(Menu, null, btns.map((item, index) => {
                const { title, onClick = () => { }, icon, ref } = item;
                return (React.createElement(Menu.Item, { key: index, className: "opt-btn", ref: ref, 
                    // iconSize={'small'}
                    onClick: () => onClick(item) },
                    React.createElement(CnIcon, { type: icon, size: "small" }),
                    " ",
                    title));
            }))));
    };
    const renderMoreBtns = (btns) => {
        var _a, _b, _c, _d, _e;
        if (btns.length > 1)
            return renderMoreOpts(btns);
        if (btns.length === 1) {
            return (React.createElement(Button, { className: "opt-btn", ref: (_a = btns[0]) === null || _a === void 0 ? void 0 : _a.ref, size: (_b = btns[0]) === null || _b === void 0 ? void 0 : _b.size, onClick: () => { var _a; return (_a = btns[0]) === null || _a === void 0 ? void 0 : _a.onClick(btns[0]); } }, ((_c = btns[0]) === null || _c === void 0 ? void 0 : _c.useIcon) ? React.createElement(CnIcon, { type: (_d = btns[0]) === null || _d === void 0 ? void 0 : _d.icon }) : (_e = btns[0]) === null || _e === void 0 ? void 0 : _e.title));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        renderMoreBtns(moreBtns),
        visibleBtns.map((item, index) => {
            const { icon, ref, onClick = () => { } } = item;
            return (React.createElement(Button, { ref: ref, size: size, className: "opt-btn", onClick: () => onClick(item), key: index }, item.useIcon ? React.createElement(CnIcon, { type: icon }) : item.title));
        })));
};
export default OptBtns;
