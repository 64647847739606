import * as React from 'react';
import NextProgress from '@fusion/lib/progress';
// export interface AffixProps extends React.HTMLAttributes<HTMLElement> {
//   /**
//    * 设置 Affix 需要监听滚动事件的容器元素
//    */
//   container?: () => React.ReactElement<any>;
//   /**
//    * 距离窗口顶部达到指定偏移量后触发
//    */
//   offsetTop?: number;
//   /**
//    * 距离窗口底部达到制定偏移量后触发
//    */
//   offsetBottom?: number;
//   /**
//    * 当元素的样式发生固钉样式变化时触发的回调函数
//    */
//   onAffix?: (affixed: boolean) => void;
//   /**
//    * 是否启用绝对布局实现 affix
//    */
//   useAbsolute?: boolean;
// }
export const CnProgress = React.forwardRef((props, ref) => {
    return React.createElement(NextProgress, { "data-name": "cn-progress", ...props, ref: ref });
});
