import { useI18n } from 'panda-i18n';
import * as React from 'react';
import { Button } from '@/components/fusion';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import DragModal from '../drag-modal';
import { SaveConfigActionTypes } from '../../store';
export const ConfigButton = ({ enableConfig, store, dispatch, ...props }) => {
    const { overlayVisible } = store;
    if (!enableConfig)
        return null;
    return (React.createElement(Button, { ...props, onClick: () => {
            sendLog({
                id: 'cn-ui.cn-filter.clickSaveConfigButton',
            });
            dispatch({
                type: SaveConfigActionTypes.SetOverlayVisible,
                payload: !overlayVisible,
            });
        } },
        React.createElement("span", null,
            React.createElement(CnIcon, { type: "setting" }))));
};
export const ConfigModal = ({ enableConfig, enableSaveSelected, onConfigValueChange, configValue: propConfigValue, store, dispatch, gridProps, saveSelectSpan, }) => {
    const { dataSource, overlayVisible, configInited, configValue } = store;
    const $i18n = useI18n();
    if (!enableConfig)
        return null;
    // 内部状态改变更新配置 meta
    const updateConfigValue = (nextConfigValue) => {
        if (enableConfig) {
            onConfigValueChange && onConfigValueChange(nextConfigValue);
            if (propConfigValue === undefined) {
                dispatch({
                    type: SaveConfigActionTypes.SetConfigValue,
                    payload: nextConfigValue,
                });
            }
        }
    };
    const updateDataSource = (nextDataSource) => {
        updateConfigValue(nextDataSource.map((nextDataSourceItem) => {
            const { visible, value } = nextDataSourceItem;
            return {
                visible,
                name: value,
            };
        }));
    };
    const setOverlayVisible = (payload) => {
        dispatch({
            type: SaveConfigActionTypes.SetOverlayVisible,
            payload,
        });
    };
    return (React.createElement(DragModal, { ...{
            enableSaveSelected,
            dataSource,
            updateDataSource,
            setOverlayVisible,
            configInited,
            configValue,
            overlayVisible,
            gridProps,
            saveSelectSpan,
            $i18n,
        } }));
};
