import { componentName } from './const';
import View from './view';
import locale from '@locale';
import { withI18n } from 'panda-i18n';
export * from './view';
export * from './type';
const Table = withI18n(View, {
    componentName,
    locale,
});
Table.displayName = componentName;
export default Table;
