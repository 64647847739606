import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { polyfill } from 'react-lifecycles-compat';
import classnames from 'classnames';
import Button from '@fusion/lib/button';
import Icon from '@fusion/lib/icon';
import Menu from '@fusion/lib/menu';
import Overlay from '@fusion/lib/overlay';
import ConfigProvider from '@fusion/lib/config-provider';
import { obj, func } from '@fusion/lib/util';
const { Popup } = Overlay;
/**
 * MenuButton
 */
class MenuButton extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.clickMenuItem = (key, ...others) => {
            const { selectMode } = this.props;
            this.props.onItemClick(key, ...others);
            if (selectMode === 'multiple') {
                return;
            }
            this.onPopupVisibleChange(false, 'menuSelect');
        };
        this.selectMenu = (keys, ...others) => {
            if (!('selectedKeys' in this.props)) {
                this.setState({
                    selectedKeys: keys,
                });
            }
            this.props.onSelect(keys, ...others);
        };
        this.onPopupOpen = () => {
            const button = findDOMNode(this);
            if (button && this.menu) {
                if (this.menu.offsetWidth < button.offsetWidth) {
                    this.menu.style.width = `${button.offsetWidth}px`;
                }
            }
        };
        this.onPopupVisibleChange = (visible, type) => {
            if (!('visible' in this.props)) {
                this.setState({
                    visible,
                });
            }
            this.props.onVisibleChange(visible, type);
        };
        this._menuRefHandler = (ref) => {
            this.menu = findDOMNode(ref);
            const refFn = this.props.menuProps.ref;
            if (typeof refFn === 'function') {
                refFn(ref);
            }
        };
        this.state = {
            selectedKeys: props.defaultSelectedKeys,
            visible: props.defaultVisible,
        };
    }
    static getDerivedStateFromProps(props) {
        const st = {};
        if ('visible' in props) {
            st.visible = props.visible;
        }
        if ('selectedKeys' in props) {
            st.selectedKeys = props.selectedKeys;
        }
        return st;
    }
    render() {
        const { prefix, style, className, label, popupTriggerType, popupContainer, popupStyle, popupClassName, popupProps, followTrigger, selectMode, menuProps, children, ...others } = this.props;
        const { state } = this;
        const classNames = classnames({
            [`${prefix}menu-btn`]: true,
            [`${prefix}expand`]: state.visible,
            opened: state.visible,
        }, className);
        const popupClassNames = classnames({
            [`${prefix}menu-btn-popup`]: true,
        }, popupClassName);
        const trigger = (React.createElement(Button, { style: style, className: classNames, ...obj.pickOthers(MenuButton.propTypes, others) },
            label,
            " ",
            React.createElement(Icon, { type: "arrow-down", className: `${prefix}menu-btn-arrow` })));
        return (React.createElement(Popup, { ...popupProps, followTrigger: followTrigger, visible: state.visible, onVisibleChange: this.onPopupVisibleChange, trigger: trigger, triggerType: popupTriggerType, container: popupContainer, onOpen: this.onPopupOpen, style: popupStyle, className: popupClassNames },
            React.createElement("div", { className: `${prefix}menu-btn-spacing-tb` },
                React.createElement(Menu, { ...menuProps, ref: this._menuRefHandler, selectedKeys: state.selectedKeys, selectMode: selectMode, onSelect: this.selectMenu, onItemClick: this.clickMenuItem }, children))));
    }
}
MenuButton.propTypes = {
    prefix: PropTypes.string,
    /**
     * 按钮上的文本内容
     */
    label: PropTypes.node,
    /**
     * 弹层是否与按钮宽度相同
     */
    autoWidth: PropTypes.bool,
    /**
     * 弹层触发方式
     */
    popupTriggerType: PropTypes.oneOf(['click', 'hover']),
    /**
     * 弹层容器
     */
    popupContainer: PropTypes.any,
    /**
     * 弹层展开状态
     */
    visible: PropTypes.bool,
    /**
     * 弹层默认是否展开
     */
    defaultVisible: PropTypes.bool,
    /**
     * 弹层在显示和隐藏触发的事件
     */
    onVisibleChange: PropTypes.func,
    /**
     * 弹层自定义样式
     */
    popupStyle: PropTypes.object,
    /**
     * 弹层自定义样式类
     */
    popupClassName: PropTypes.string,
    /**
     * 弹层属性透传
     */
    popupProps: PropTypes.object,
    /**
     * 是否跟随滚动
     */
    followTrigger: PropTypes.bool,
    /**
     * 默认激活的菜单项（用法同 Menu 非受控）
     */
    defaultSelectedKeys: PropTypes.array,
    /**
     * 激活的菜单项（用法同 Menu 受控）
     */
    selectedKeys: PropTypes.array,
    /**
     * 菜单的选择模式，同 Menu
     */
    selectMode: PropTypes.oneOf(['single', 'multiple']),
    /**
     * 点击菜单项后的回调，同 Menu
     */
    onItemClick: PropTypes.func,
    /**
     * 选择菜单后的回调，同 Menu
     */
    onSelect: PropTypes.func,
    /**
     * 菜单属性透传
     */
    menuProps: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.any,
};
MenuButton.defaultProps = {
    prefix: 'cn-next-',
    autoWidth: true,
    popupTriggerType: 'click',
    onVisibleChange: func.noop,
    onItemClick: func.noop,
    onSelect: func.noop,
    defaultSelectedKeys: [],
    menuProps: {},
};
MenuButton.Item = Menu.Item;
MenuButton.Group = Menu.Group;
MenuButton.Divider = Menu.Divider;
export default ConfigProvider.config(polyfill(MenuButton), {
    componentName: 'MenuButton',
});
