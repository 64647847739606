import $i18n from 'panda-i18n';
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';
import { MenuButton } from '@/components/fusion';
// import moduleName from 'module';
import { CnButton as Button } from '@/components/cn-button';
import { globalSplit, globalOpreateMoreIconSize } from '../global';
import { 
// getParent,
isDef, isFunction, isNumber, isPlainObject, } from '../utils';
export const operateCode = `operate${Date.now()}`;
// 随机code 请注意不要放在pipeline内部，否则可能出现
export default function operateColumnPipeline(opts, calOperateButtonItemWithNoRule) {
    const { width, buttons, showButtonNum = 3, moreBtnProps, render, columnsProps, split, splitWidth = 16, contentWidthRule, lock = 'right', } = opts;
    const finalName = $i18n.get({
        id: '31255239218827264.CNTM',
        dm: '操作',
        ns: 'CnBaseTable',
    });
    const finalOperateMoreText = $i18n.get({
        id: 'More',
        dm: '更多',
        ns: 'CnBaseTable',
    });
    const finalSplit = split || globalSplit;
    const finalSplitWidth = parseInt(splitWidth) || 10;
    let showButtonNumReality = showButtonNum > 0 ? showButtonNum : (buttons || []).length;
    const operateCol = {
        name: finalName,
        lock,
        sizeFixed: true,
        maxWidth: false,
        code: operateCode,
        contentWidthRule: getFinalRule({
            contentWidthRule,
            render,
            finalName,
            finalOperateMoreText,
            buttons,
            splitWidth: finalSplitWidth,
            showButtonNum: showButtonNumReality,
            calOperateButtonItemWithNoRule,
        }),
        ...((columnsProps instanceof Function && columnsProps()) || []),
        width,
        getCellProps() {
            return {
                className: 'cn-table-cell-operate',
            };
        },
        render(val, record, index) {
            let showButtons = []; // 展示的buttons
            const hideButtons = []; // 隐藏起来的buttons
            // 自定义render优先级最高
            if (render instanceof Function) {
                showButtons = render(val, record, index);
                if (showButtons.$$typeof)
                    return showButtons;
            }
            // 数据优先级最低
            if (Array.isArray(buttons)) {
                if (buttons.length - showButtonNumReality === 1) {
                    showButtonNumReality += 1;
                }
                buttons.forEach((buttonItem, i) => {
                    var _a;
                    if (buttonItem &&
                        buttonItem.visible instanceof Function &&
                        !buttonItem.visible(record, index)) {
                        return;
                    }
                    const shouldShow = showButtons.length / 2 < showButtonNumReality;
                    let item;
                    if ([buttonItem].some(isFunction)) {
                        // 这里去掉了 buttonItem?.children
                        const renderFunc = buttonItem instanceof Function
                            ? buttonItem
                            : buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children;
                        const itemTmp = renderFunc(record, index);
                        if (isPlainObject(itemTmp) && itemTmp.$$typeof) {
                            item = itemTmp;
                        }
                    }
                    else if (isPlainObject(buttonItem)) {
                        const { children, onClick, type = 'primary', btnProps, isDisabled, } = buttonItem;
                        const disabled = isFunction(isDisabled)
                            ? !!isDisabled(record, index)
                            : false;
                        item = shouldShow ? (React.createElement(Button, { text: true, key: i, type: type, disabled: disabled, className: "cn-table-operate-btn", style: { height: 'auto' }, size: "small", onClick: (e) => isFunction(onClick) && onClick(e, record, index), ...(btnProps || {}) }, children instanceof Function
                            ? children(record, index)
                            : children)) : (
                        // hideButton 要保证其触发范围
                        React.createElement(MenuButton.Item, { onClick: (e) => !disabled && isFunction(onClick) && onClick(e, record, index) },
                            React.createElement(Button, { text: true, key: i, disabled: disabled, 
                                // onClick={(e) => {
                                //   if (!disabled && isFunction(onClick)) {
                                //     onClick(e, record, index);
                                //   }
                                // }}
                                type: type, className: "cn-table-operate-btn", style: { height: 'auto' }, size: "small", ...(btnProps || {}) }, children instanceof Function
                                ? children(record, index)
                                : children)));
                    }
                    if (isDef(item)) {
                        if (shouldShow) {
                            showButtons.push(item);
                            showButtons.push(React.createElement("div", { style: { width: finalSplitWidth }, className: "hp-table-split" }, finalSplit));
                        }
                        else if (((_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName) !== 'MenuButtonItem') {
                            hideButtons.push(React.createElement(MenuButton.Item, null, item));
                        }
                        else {
                            hideButtons.push(item);
                        }
                    }
                });
            }
            showButtons.pop();
            return (React.createElement("div", { className: "cn-table-operate" },
                showButtons,
                hideButtons.length > 0 ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { width: finalSplitWidth }, className: "hp-table-split" }, finalSplit),
                    React.createElement(MenuButton, { text: true, popupProps: {
                            v2: true,
                        }, style: { height: 'auto' }, size: "small", className: "cn-table-operate-btn", type: "primary", autoWidth: false, label: finalOperateMoreText, popupClassName: "cn-table-operate-pop", ...(moreBtnProps || {}) }, hideButtons.map((button) => {
                        // <MenuButton.Item>{button}</MenuButton.Item>
                        // console.log(button);
                        return (button);
                    })))) : null));
        },
    };
    return (pipeline) => {
        return pipeline.columns(pipeline.getColumns().concat([operateCol]));
    };
}
// function showHideButton(
//   event: React.MouseEvent,
//   hideButtons: React.ReactNode[],
// ) {
//   const target = getParent(
//     event?.target as HTMLElement,
//     (dom) => {
//       const ret = dom?.className?.includes?.('cn-table-operate-btn');
//       return ret;
//     },
//     10,
//   );
//   const customProps = { style: { padding: 0 } };
//   // @ts-ignore
//   const { width, left } = isFunction(target?.getBoundingClientRect)
//     ? target.getBoundingClientRect()
//     : {};
//   if (isNumber(width) && isNumber(left)) {
//     // @ts-ignore
//     customProps.style.left = left + width;
//   }
//   // const render = hideButtons.map((button) => <div>{button}</div>);
//   DropDown.show(
//     event,
//     <div
//       onClick={() => {
//         DropDown.hide('operate');
//       }}
//       className="cn-table-operate-pop"
//     >
//       {hideButtons.map((button) => (
//         <div>{button}</div>
//       ))}
//     </div>,
//     {
//       offset: [0, 0],
//       props: customProps,
//       accordTarget: false,
//       Container: PopupContainer,
//       zIndex: 1005,
//       containerType: 'operate',
//       triangleHeight: 0,
//       // @ts-ignore
//       transform({ isTopHalf }: { isTopHalf: boolean }) {
//         return `translate(-100%, ${isTopHalf ? 10 : '-110'}%)`;
//       },
//     },
//   );
// }
function calVisibleButtonTotal(props, eachCal, noWidthRuleCal) {
    const { buttons, record, index, maxButtonNum, finalOperateMoreText } = props;
    let total = null;
    let calNum = 0;
    buttons.forEach((buttonItem, buttonIndex) => {
        const isVisible = (buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.visible) instanceof Function
            ? buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.visible(record, index)
            : true;
        // 单独计算这种无规则render，需要使用探针，所以无论visible都要进行计算
        if ([buttonItem, buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children].some(isFunction) &&
            noWidthRuleCal instanceof Function &&
            !buttonItem.widthRule) {
            // 在什么都没有的情况下，这里提供一个hack方式，拿第一个按钮来计算
            noWidthRuleCal(buttonItem, buttonIndex, isVisible);
        }
        // 展示才会进行计算
        if (isVisible) {
            // 多了就会出现更多，使用更多的计算方式
            if (calNum >= maxButtonNum) {
                if (calNum === maxButtonNum) {
                    total = eachCal(total, finalOperateMoreText, globalOpreateMoreIconSize);
                }
                return;
            }
            // children funciton 计算法
            if ([buttonItem, buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children].some(isFunction)) {
                if (isPlainObject(buttonItem.widthRule)) {
                    const { text, additionWidth } = buttonItem.widthRule;
                    const addText = text instanceof Function ? text(record, index) : text;
                    const addWidth = additionWidth instanceof Function
                        ? additionWidth(record, index)
                        : additionWidth;
                    total = eachCal(total, addText, addWidth);
                }
                return;
            }
            // chidren string 直接计算
            if (typeof (buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children) === 'string') {
                total = eachCal(total, buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children, 0);
            }
            calNum++;
        }
    });
    return total;
}
function getFinalRule(props) {
    const { contentWidthRule, render, finalName, finalOperateMoreText, buttons, splitWidth, showButtonNum, // 没有则为空
    calOperateButtonItemWithNoRule, } = props;
    return (contentWidthRule ||
        (render instanceof Function || !showButtonNum
            ? null
            : {
                header: {
                    text() {
                        return finalName;
                    },
                },
                cell: {
                    additionWidth(val, index, record) {
                        let buttonAdditionWidth = 0;
                        const totalSplitNum = calVisibleButtonTotal({
                            buttons,
                            record,
                            index,
                            maxButtonNum: showButtonNum,
                            finalOperateMoreText,
                            splitWidth,
                        }, (prev, renderText, additionWidth) => {
                            buttonAdditionWidth += parseInt(additionWidth) || 0;
                            return (parseInt(prev) || 0) + (renderText ? 1 : 0);
                        }, 
                        // 无计算规则处理方式
                        (buttonItem, buttonIndex, isVisible) => {
                            const width = calOperateButtonItemWithNoRule({
                                render: isFunction(buttonItem)
                                    ? buttonItem
                                    : buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children,
                                record,
                                index,
                                buttonIndex,
                                splitWidth,
                            });
                            if (isNumber(width) && isVisible) {
                                buttonAdditionWidth += width;
                            }
                        }) - 1;
                        return (totalSplitNum *
                            (parseInt(splitWidth) || 0) +
                            buttonAdditionWidth);
                    },
                    text(val, index, record) {
                        const finalText = calVisibleButtonTotal({
                            buttons,
                            record,
                            index,
                            maxButtonNum: showButtonNum,
                            finalOperateMoreText,
                            splitWidth,
                        }, (prev, renderText) => {
                            return `${typeof prev === 'string' ? prev : ''}${typeof renderText === 'string' ? renderText : ''}`;
                        });
                        return finalText;
                    },
                },
            }));
}
