import React from 'react';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
export default function columnsTitlePipeline() {
    return function (pipeline) {
        const columns = pipeline.getColumns();
        // const mergeHeaderCellPropsColumns = columns.map((column) => {
        //   return handleTitleTooltips(column);
        // });
        return pipeline.columns(columns.map(handleTitleTooltips));
    };
}
function handleTitleTooltips(column) {
    const { title, tip } = column;
    let titleTip;
    if (tip) {
        const textDom = (React.createElement(CnIcon, { style: { marginLeft: '2px' }, type: "help-color", size: "medium" }));
        column.title = (React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
            title,
            React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, tip)));
    }
    // if (isPlainObject(title)) {
    //   if (title?.toolTips) {
    //     const textDom = (
    //       <CnIcon style={{ marginLeft: '2px' }} type="help-color" size="medium" />
    //     );
    //     column.title = (
    //       <div style={{ display: 'flex', alignItems: 'center' }}>
    //         {title?.value ?? title?.text}
    //         <CnTooltip trigger={textDom} triggerType="hover">
    //           {title?.toolTips}
    //         </CnTooltip>
    //       </div>
    //     );
    //   } else {
    //     column.title = title?.value ?? title?.text;
    //   }
    // }
    return column;
}
