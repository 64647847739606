import $i18n from 'panda-i18n';
import cx from 'classnames';
import React from 'react';
import { Colgroup } from './colgroup';
import { Classes } from './styles';
const DefaultEmptyContent = React.memo(() => (React.createElement("div", { className: "cn-empty-wrapper" },
    React.createElement("img", { src: "//img.alicdn.com/imgextra/i2/O1CN01wRaRZS1hn48TBP2dl_!!6000000004321-55-tps-160-160.svg", alt: "", draggable: "false", className: "empty-image" }),
    React.createElement("div", { className: "empty-message" }, $i18n.get({
        id: 'TableDataIsEmpty',
        dm: '表格数据为空',
        ns: 'CnBaseTable',
    })))));
export function EmptyHtmlTable({ descriptors, isLoading, emptyCellHeight, EmptyContent = DefaultEmptyContent, }) {
    const show = !isLoading;
    return (React.createElement("table", null,
        React.createElement(Colgroup, { descriptors: descriptors }),
        React.createElement("tbody", null,
            React.createElement("tr", { className: cx(Classes.tableRow, 'first', 'last', 'no-hover'), "data-rowindex": 0 },
                React.createElement("td", { className: cx(Classes.tableCell, 'first', 'last'), colSpan: descriptors.length, style: { height: emptyCellHeight !== null && emptyCellHeight !== void 0 ? emptyCellHeight : 200 } }, show && (React.createElement("div", { className: Classes.emptyWrapper },
                    React.createElement(EmptyContent, null))))))));
}
