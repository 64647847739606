import * as React from 'react';
import cx from 'classnames';
import { Breadcrumb } from '@/components/fusion';
export const CnBreadcrumb = (props) => {
    const { children, dataSource, className, ...otherProps } = props;
    const childNodes = [];
    if (dataSource) {
        dataSource.forEach((item, idx) => {
            const { children: itemChildren, ...otherItemProps } = item;
            childNodes.push(React.createElement(Breadcrumb.Item, { key: idx, ...otherItemProps }, item.children));
        });
    }
    const classes = cx({
        'cn-ui-breadcrumb': true,
        [className]: !!className,
    });
    return (React.createElement(Breadcrumb, { "data-name": "CnBreadcrumb", separator: "/", className: classes, ...otherProps }, children || childNodes));
};
export const CnBreadcrumbItem = Breadcrumb.Item;
