/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { Component } from 'react';
import styled from 'styled-components';
const LineContainer = styled.div `
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  padding: 0px 5px;
  transform: translateX(50px);
  z-index: 1000;
  cursor: col-resize;
`;
const Line = styled.div `
  height: 100%;
  width: 1px;
  background: #a8aaaf;
`;
export default class DragLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startClientX: 0,
        };
    }
    setOffset(val) {
        this.setState({
            startClientX: val,
        });
    }
    reset() {
        this.setState({
            startClientX: 0,
        });
    }
    render() {
        const { startClientX } = this.state;
        const { tableClientX } = this.props;
        return startClientX > 0 ? (React.createElement(LineContainer, { style: { transform: `translateX(${startClientX - tableClientX}px)` } },
            React.createElement(Line, null))) : null;
    }
}
