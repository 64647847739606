import { getValueByPath } from './common';
export function safeCellPaddingWidth(size) {
    return size === 'large' ? 26 : 18;
}
export function safeRenderHeader(column) {
    var _a;
    return (_a = column.title) !== null && _a !== void 0 ? _a : column.name;
}
export function safeGetValue(column, record, rowIndex) {
    var _a;
    if (column.getValue) {
        return column.getValue(record, rowIndex);
    }
    return getValueByPath(record, `${(_a = column.code) !== null && _a !== void 0 ? _a : ''}`.split('.'), undefined);
}
export function safeGetRowKey(primaryKey, record, rowIndex) {
    let key;
    if (typeof primaryKey === 'string') {
        key = record[primaryKey];
    }
    else if (typeof primaryKey === 'function') {
        key = primaryKey(record);
    }
    if (key == null) {
        key = String(rowIndex);
    }
    return key;
}
export const renderPre = Symbol('render-pre');
export function safeGetRenderKey(primaryKey, record, rowIndex) {
    var _a;
    let key = '';
    const pre = (_a = record[renderPre]) !== null && _a !== void 0 ? _a : '';
    if (typeof primaryKey === 'string') {
        key = record[primaryKey];
    }
    else if (typeof primaryKey === 'function') {
        key = primaryKey(record);
    }
    if (key == null) {
        key = String(rowIndex);
    }
    return `${pre}${key}`;
}
export function safeGetCellProps(column, record, rowIndex) {
    if (column.getCellProps) {
        const value = safeGetValue(column, record, rowIndex);
        return column.getCellProps(value, record, rowIndex) || {};
    }
    return {};
}
export function safeRender(column, record, rowIndex) {
    const value = safeGetValue(column, record, rowIndex);
    if (column.render) {
        return column.render(value, record, rowIndex);
    }
    return value;
}
