import { isLeafNode } from './node';
/** 获取一棵树的高度/深度 (0-based) */
export function getTreeDepth(nodes) {
    let maxDepth = -1;
    dfs(nodes, 0);
    return maxDepth;
    function dfs(columns, depth) {
        for (const column of columns) {
            if (isLeafNode(column)) {
                maxDepth = Math.max(maxDepth, depth);
            }
            else {
                dfs(column.children, depth + 1);
            }
        }
    }
}
export function makeRecursiveMapper(fn) {
    return (tree, level = 0) => {
        return dfs(tree, level, []).result;
        function dfs(nodes, parentStartIndex, path) {
            let flatCount = 0;
            const result = [];
            for (const node of nodes) {
                path.push(node);
                const startIndex = parentStartIndex + flatCount;
                let subResult;
                if (isLeafNode(node)) {
                    subResult = fn(node, {
                        startIndex,
                        endIndex: startIndex + 1,
                        path: path.slice(),
                        isLeaf: true,
                    });
                    flatCount += 1;
                }
                else {
                    const dfsResult = dfs(node.children, startIndex, path);
                    subResult = fn({ ...node, children: dfsResult.result }, {
                        startIndex,
                        endIndex: startIndex + dfsResult.flatCount,
                        path: path.slice(),
                        isLeaf: false,
                    });
                    flatCount += dfsResult.flatCount;
                }
                if (Array.isArray(subResult)) {
                    result.push(...subResult);
                }
                else if (subResult != null) {
                    result.push(subResult);
                }
                path.pop();
            }
            return { result, flatCount };
        }
    };
}
