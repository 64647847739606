export default function zebraPipeline(zebraColor = 'var(--row-zbcolor)') {
    return function (pipeline) {
        const sourceCols = pipeline.getColumns();
        sourceCols.forEach((col, index) => {
            const sourceCellProps = (col === null || col === void 0 ? void 0 : col.getCellProps) || (() => ({}));
            col.getCellProps = (value, record, rowIndex) => {
                const prevCellProps = sourceCellProps(value, record, rowIndex);
                if (rowIndex % 2 === 1) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    return Object.assign({}, prevCellProps, {
                        style: {
                            '--bgcolor': zebraColor,
                            ...((prevCellProps === null || prevCellProps === void 0 ? void 0 : prevCellProps.style) || {}),
                        },
                        // 优先级 cellProps > getCellProps > pipeline内部getCellProps
                    });
                }
                return prevCellProps;
            };
        });
        return pipeline.columns(sourceCols);
    };
}
